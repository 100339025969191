




































import {
  isQuestTime,
  TimeLimitedQuestCode,
} from '@/game/infos/questTimeLimited';
import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import WeaponComponent from './WeaponComponent.vue';
import settingx from '@/store/modules/settingx';
import gamex from '@/store/modules/gamex';
@Component({
  components: {
    WeaponComponent,
  },
})
export default class PolarModal extends Vue {
  public get titleHtml() {
    return `Theo's weaponry`;
  }
  public get avatar() {
    return isQuestTime(TimeLimitedQuestCode.Xmas2021)
      ? 'images/games/polar-head-santa.jpg'
      : 'images/games/polar-head.jpg';
  }

  public get changeWeapon() {
    if (settingx.now.control === 'touch') {
      return 'Swipe Left or Right.';
    }
    let wpnL: string = settingx.now.wpnL;
    let wpnR: string = settingx.now.wpnR;
    if (
      wpnL !== 'Left-Click' &&
      wpnL !== 'Right-Click' &&
      wpnR !== 'Left-Click' &&
      wpnR !== 'Right-Click'
    ) {
      return `Press ${wpnL.toUpperCase()} or ${wpnR.toUpperCase()}`;
    }
    if (wpnL !== 'Left-Click' && wpnL !== 'Right-Click') {
      wpnL = 'Press ' + wpnL.toUpperCase();
    }
    if (wpnR !== 'Left-Click' && wpnR !== 'Right-Click') {
      wpnR = 'Press ' + wpnR.toUpperCase();
    }
    return `${wpnL} or ${wpnR}`;
  }
  public onShow() {
    gamex.updatePolarModalShowing(true);
  }
  public onHide() {
    gamex.updatePolarModalShowing(false);
    Global.stageControl.uiControl.closePolar();
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
