










































































































































































































import { SoundEfx } from '@/client/sound/SoundEfx';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CurrencyBar from '../gacha/CurrencyBar.vue';
import { authorizePoq, buyQuarters } from '../gacha/poq/poqFunctions';
import { ItemCode } from '@/game/infos/itemInfos';
import {
  timeLimitedQuests,
  TimeLimitedQuestCode,
} from '@/game/infos/questTimeLimited';
import { ServerTime } from '@/store/api/serverTime';
import ProductShowcase from './ProductShowcase.vue';

@Component({
  components: {
    CurrencyBar,
    ProductShowcase,
  },
})
export default class ShopModal extends Vue {
  public get saleBlock() {
    return this.expired || this.pending;
  }
  public get expired() {
    return this.quest.endTime && this.quest.endTime < this.time;
  }
  public get pending() {
    return this.quest.startTime && this.quest.startTime > this.time;
  }
  public get timeLeft() {
    if (!this.quest.startTime) {
      return '';
    }
    if (this.expired) {
      return 'Offer has ended';
    }

    if (this.pending) {
      const closeTime = this.quest.startTime || Date.now();
      let tl = closeTime - this.time;
      if (tl < 0) {
        tl = 0;
      }
      tl = Math.ceil(tl / 1000);
      const hour = Math.floor(tl / (60 * 60));
      tl -= hour * 60 * 60;
      const min = Math.floor(tl / 60);
      tl -= min * 60;
      const sec = tl;
      // tl -= min * 60000
      return `Coming soon in ${hour}h ${min}m ${sec}s `;
    } else {
      const closeTime = this.quest.endTime || Date.now();
      let tl = closeTime - this.time;
      if (tl < 0) {
        tl = 0;
      }
      //   const day = Math.floor(tl / (24 * 60 * 60 * 1000));
      //   tl -= day * 24 * 60 * 60000;
      tl = Math.ceil(tl / 1000);
      const hour = Math.floor(tl / (60 * 60));
      tl -= hour * 60 * 60;
      const min = Math.floor(tl / 60);
      tl -= min * 60;
      const sec = tl;
      // tl -= min * 60000
      return `${hour}h ${min}m ${sec}s`;
    }
  }
  public startAnimation = false;
  public baseUrl = process.env.BASE_URL;
  public showLoading = false;

  public quest = timeLimitedQuests[TimeLimitedQuestCode.Cny2024];

  public time = Date.now();
  public interval: any;
  @Watch('timeLeft')
  public timeLeftChanged(n: string, o: string) {
    this.startAnimation = true;
  }
  public created() {
    Global.cnyShopModal = this;
  }

  public show() {
    (this.$refs.myModal as BModal).show();
    this.startAnimation = false;
  }
  public mounted() {
    this.interval = setInterval(() => {
      this.time = ServerTime.time;
    }, 1000);
  }
  public beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  public buyBlackPearl() {
    Global.shopModal.show();
  }
  public buyQuarters() {
    buyQuarters();
  }
  public async onBuyHatOfFortune() {
    if (!userx.userDoc.poq) {
      Global.poqLinkModal.show();
      return;
    }
    const h = this.$createElement;
    if (userx.poqWallet.balance < 88) {
      (this.$refs.poqModal as BModal).show();
      return;
    }
    const messageVNode = h('div', {}, [
      'Purchase with ',
      h('img', {
        style: 'height: 25px',
        class: 'icon-quarters',
        domProps: { src: `${this.baseUrl}/images/icons/quarters.svg?v=1` },
      }),
      h('span', { class: 'fwb' }, [` x 888`]),
      ' ?',
    ]);
    const value = await this.$bvModal.msgBoxConfirm([messageVNode], {
      titleHtml: `Confirm Purchase`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'primary',
      cancelVariant: 'link btn-link',
      modalClass: 'funny-modal',
      okTitle: 'Yes',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true,
    });
    if (!value) {
      return;
    }
    this.showLoading = true;
    try {
      const result = await userx.cnyPurchase(ItemCode.HatOfFortune);
      this.showLoading = false;
      new SoundEfx('buyChaching').play();
      this.$bvModal.msgBoxOk(
        [
          h('div', { class: 'text-center' }, ['Bought 1 Hat Of Fortune!']),
          h('div', { class: 'text-center' }, ['Happy New Year!']),
        ],
        {
          titleHtml: `<i class="text-green fa fas fa-check-circle"></i> Succeed!`,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          modalClass: 'funny-modal',
          centered: true,
        },
      );
    } catch (error) {
      this.showLoading = false;
      this.showError(error);
    }
  }
  public async onBuyBeardOfFortune() {
    const h = this.$createElement;
    if ((userx.inventory.availables[ItemCode.Gem] || 0) < 888) {
      (this.$refs.bpModal as BModal).show();
      return;
    }
    const messageVNode = h('div', {}, [
      'Purchase with ',
      h('img', {
        style: 'height: 25px',
        class: 'icon-quarters',
        domProps: { src: `${this.baseUrl}/images/icons/blackpearl.svg?v=1` },
      }),
      h('span', { class: 'fwb' }, [` x 888`]),
      ' ?',
    ]);
    const value = await this.$bvModal.msgBoxConfirm([messageVNode], {
      titleHtml: `Confirm Purchase`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'primary',
      cancelVariant: 'link btn-link',
      modalClass: 'funny-modal',
      okTitle: 'Yes',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true,
    });
    if (!value) {
      return;
    }
    this.showLoading = true;
    try {
      const result = await userx.cnyPurchase(ItemCode.BeardOfFortune);
      this.showLoading = false;
      new SoundEfx('buyChaching').play();
      this.$bvModal.msgBoxOk(
        [
          h('div', { class: 'text-center' }, ['Bought 1 Beard Of Fortune!']),
          h('div', { class: 'text-center' }, ['Happy New Year!']),
        ],
        {
          titleHtml: `<i class="text-green fa fas fa-check-circle"></i> Succeed!`,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          modalClass: 'funny-modal',
          centered: true,
        },
      );
    } catch (error) {
      this.showLoading = false;
      this.showError(error);
    }
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
