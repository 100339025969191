































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  timeLimitedQuests,
  TimeLimitedQuestCode,
  isQuestTime,
} from '@/game/infos/questTimeLimited';
import { Global } from '@/store/globalz';
import ProductButtonComponent from './ProductButtonComponent.vue';
import { ServerTime } from '@/store/api/serverTime';

@Component({
  components: {
    ProductButtonComponent,
  },
})
export default class SideSaleButtons extends Vue {

  public get isActive() {
    return isQuestTime(this.quest.code);
  }
  public get timeLeft() {
    if (!this.quest.startTime) {
      return '';
    }

    const closeTime = this.quest.endTime || Date.now();
    let tl = closeTime - this.time;
    if (tl < 0) {
      tl = 0;
    }
    //   const day = Math.floor(tl / (24 * 60 * 60 * 1000));
    //   tl -= day * 24 * 60 * 60000;
    tl = Math.ceil(tl / 1000);
    const hour = Math.floor(tl / (60 * 60));
    tl -= hour * 60 * 60;
    const min = Math.floor(tl / 60);
    tl -= min * 60;
    let ss = tl.toString();
    let mm = min.toString();
    if (mm.length < 2) { mm = '0' + mm; }
    if (ss.length < 2) { ss = '0' + ss; }
    // tl -= min * 60000
    return `${hour}:${mm}:${ss}`;
  }
  public interval: any;
  public quest = timeLimitedQuests[TimeLimitedQuestCode.Cny2024];

  public time = Date.now();
  public mounted() {
    this.interval = setInterval(() => {
      this.time = ServerTime.time;
    }, 1000);
  }
  public beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  public onCnyPurchase() {
    Global.cnyShopModal.show();
  }
}
