import { FishType } from './fishInfos';
import { ItemCode } from './itemInfos';
import { QuestType, UnlockChallengeType, UnlockSteps } from './unlockModels';

export const fishUnlockRequirements: UnlockSteps[] = [];

function setSteps(steps: UnlockSteps) {
	const fishType = steps[0][0].unlockId;
	for (let i = 0; i < steps.length; i++) {
		const req = steps[i];
		for (let k = 0; k < req.length; k++) {
			const cha = req[k];
			cha.isMaster = false;
			cha.step = i;
			cha.index = k;
			cha.unlockId = fishType;
		}
	}
	fishUnlockRequirements[fishType] = steps;
}
const isMaster = false;
const index = 0;
let unlockId;
unlockId = FishType.HammerHead;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 5000,
			description: 'Score 5000 pts over time.',
		},
	],
]);
unlockId = FishType.HammeredHead;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 2,
			description: 'Reach Level 2',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.HammerHead,
			description: 'Unlock HammerHead',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 5,
			description: 'Being stabbed 5 times using Hammerhead.',
		},
	],
]);
unlockId = FishType.WhammyHead;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 3,
			description: 'Reach Level 3',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.HammeredHead,
			description: 'Unlock Hammered Head',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Boost and crush the impaled enemies into the wall using Hammered Head 10 times.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 2000,
			description: 'Pay Gold x2000g',
			material: ItemCode.Money,
		},
	],
]);


unlockId = FishType.KillerWhale;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 4,
			description: 'Reach Level 4',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Kill 5 enemies in ONE game.',
		},
	],
]);
unlockId = FishType.SerialKillerWhale;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 5,
			description: 'Reach Level 5',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.KillerWhale,
			description: 'Unlock Killer Whale',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 10,
			description: 'Kill 10 enemies using Killer Whale in ONE game.',
		},
	],
]);
unlockId = FishType.PsychoKillerWhale;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 6,
			description: 'Reach Level 6',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.SerialKillerWhale,
			description: 'Unlock Serial Killer Whale',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 20,
			description: 'Set 20 enemies on fire in ONE game using Serial Killer Whale.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 5000,
			description: 'Pay Gold x5000g',
			material: ItemCode.Money,
		},
	],
]);


unlockId = FishType.BottlenoseDolphin;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 7,
			description: 'Reach Level 7',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 6,
			description: 'Jump out of water and send Love Emoji x 6 times',
		},
	],
]);
unlockId = FishType.CommonDolphin;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 8,
			description: 'Reach Level 8',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.BottlenoseDolphin,
			description: 'Unlock Bottlenose Dolphin',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 5,
			description: 'Rescue impaled teammates x 5 times using Bottlenose Dolphin',
		},
	],
]);
unlockId = FishType.SpinnerDolphin;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 9,
			description: 'Reach Level 9',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.CommonDolphin,
			description: 'Unlock Common Dolphin',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Jump out of the water and spin 5 times while in the air using Common Dolphin',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
]);

unlockId = FishType.Pufferfish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 10,
			description: 'Impale 10 fish in ONE game',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Porcupinefish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Pufferfish,
			description: 'Unlock Puffer Fish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 20,
			description: 'Hit enemies with Puffer Fish\'s spikes 20 times',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Onifugu;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Porcupinefish,
			description: 'Unlock Porcupine Fish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Kill an ArchBeluga using Porcupine Fish',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.Narwhal;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5000,
			description: 'Score 5000 pts in ONE game only using Tusk',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Beluga;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Narwhal,
			description: 'Unlock Narwhal',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Win 1 Armageddon.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Narwhal Gem x1',
			material: ItemCode.NarwhalGem,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.ArchBeluga;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Beluga,
			description: 'Unlock Beluga',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Win 10 Armageddons.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Beluga Gem x1',
			material: ItemCode.BelugaGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.Goldfish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1000,
			description: 'Collect 1000 gold from killing enemies.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Goldenfish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Goldfish,
			description: 'Unlock Goldfish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 66,
			description: 'Mine 66 ores in ONE game using Goldfish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Diamondfish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Goldenfish,
			description: 'Unlock Golden Fish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Mine a diamond ore using Golden Fish',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.GreenSeaTurtle;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Decorate,
			goal: 1,
			description: 'Equip a "Shield" decoration on the BACK of any fish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.LeatherbackTurtle;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.GreenSeaTurtle,
			description: 'Unlock Green Sea Turtle',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Block enemies spear for 5 seconds in ONE game using Green Sea Turtle.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.AlligatorSnappingTurtle;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.LeatherbackTurtle,
			description: 'Unlock Leatherback Turtle',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 30,
			description: 'Get hit by enemies\' special skills such as Megabite 30 times using Leatherback Turtle',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.HumpbackWhale;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 1000,
			description: 'Score 1000 points from eating foods in ONE game.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.BlueWhale;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.HumpbackWhale,
			description: 'Unlock Humpback Whale',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 4,
			description: 'Make sashimi by crushing 4 frozen impaled enemies at the same time using Humpback Whale.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Jomama;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.BlueWhale,
			description: 'Unlock Blue Whale',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 20000,
			description: 'Accumulate 20,000 points from eating foods over time.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Blue Whale Gem x1',
			material: ItemCode.BlueWhaleGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.DumboOctopus;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 1,
			description: 'Die on land due to suffocation/dehydration',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Tako;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.DumboOctopus,
			description: 'Unlock Dumbo Octopus',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 8,
			description: 'Achieve 8 Stab Streak using Dumbo Octopus',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Kraken;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Tako,
			description: 'Unlock Tako',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 40,
			description: 'Achieve 40 Kill Streak',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Tako Gem x1',
			material: ItemCode.TakoGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.MantaRay;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Mission,
			goal: 3,
			description: 'Complete 3 Weekly Quest Challenges.',
			questType: QuestType.Weekly,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.MantraRay;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.MantaRay,
			description: 'Unlock Manta Ray',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 2500,
			description: 'Score 2500 points without any kills using Manta Ray in ONE game.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.BlueRay;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.MantraRay,
			description: 'Unlock Mantra Ray',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 1,
			description: 'Killed by Armageddon Poison as Mantra Ray and Revive, then finish the Armageddon with 4th place or above.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.MorayEel;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 4,
			description: 'Reach all 4 corners of the map in ONE game.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.GulperEel;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.MorayEel,
			description: 'Unlock Moray Eel',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 1,
			description: 'Make a stab while blinded by ink using Moray Eel.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.ElectricEel;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.GulperEel,
			description: 'Unlock Gulper Eel',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 10,
			description: 'Shock 10 enemies with electric-charged weapon in ONE game using Gulper Eel.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.Stingray;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 3,
			description: 'Kill your killer 3 times',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.BatRay;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Stingray,
			description: 'Unlock Stingray',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 3,
			description: 'Kill your team mate\'s killer 3 times using Stingray.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.ElectricRay;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.BatRay,
			description: 'Unlock Bat Ray',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 3,
			type: UnlockChallengeType.Accumulate,
			goal: 3,
			description: 'Shoot enemy with electric-charged bat darts 3 times.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);


unlockId = FishType.SakuraShrimp;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Die in the 3rd life with 0 highscore',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.PistolShrimp;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.SakuraShrimp,
			description: 'Unlock Sakura Shrimp',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 20,
			description: 'Shoot 20 enemies with Crossbow\'s Fish Bolts using Sakura Shrimp.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.MantisShrimp;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.PistolShrimp,
			description: 'Unlock Pistol Shrimp',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 3,
			type: UnlockChallengeType.OneGame,
			goal: 20,
			description: 'Shoot 20 Pistol Shrimp\'s Fire Bullets to enemies in ONE game.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);


unlockId = FishType.FirebellyNewt;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 10000,
			description: 'Recover 10000 HP.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.FireSalamander;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.FirebellyNewt,
			description: 'Unlock Fire-belly Newt',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 50,
			description: 'Recover from burning 50 times using Fire-belly Newt.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Axolotl;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.FireSalamander,
			description: 'Unlock Fire Salamander',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 3,
			type: UnlockChallengeType.Accumulate,
			goal: 20,
			description: 'Escape Impalement from your enemies 20 times without being rescued or released.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Fire Salamander Gem x1',
			material: ItemCode.FireSalamanderGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);


unlockId = FishType.VampireSquid;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 80,
			description: 'Released from impalement after losing more than 80% of blood.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Cuttlefish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.VampireSquid,
			description: 'Unlock Vampire Squid',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Witness the collision of 2 Ironmonites using Vampire Squid.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Witness the transfer of element in a zellyfish using Vampire Squid.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.ColossalSquid;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Cuttlefish,
			description: 'Unlock Cuttlefish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 3,
			type: UnlockChallengeType.Accumulate,
			goal: 30,
			description: 'Stab or kill 30 enemies that are trapped in a whirlpool.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Cuttlefish Gem x1',
			material: ItemCode.CuttlefishGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.Swordfish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 4,
			description: 'Buy 4 weapons from Theo\'s weaponry in ONE game.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Marlin;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Swordfish,
			description: 'Unlock Swordfish',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Stab 5 enemies with the Pickaxe using Swordfish in ONE game.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Stab 5 enemies with the Water Trident using Swordfish in ONE game.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Stab 5 enemies with the Chainsaw using Swordfish in ONE game.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 5,
			description: 'Stab 5 enemies with the Chillblade using Swordfish in ONE game.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Sailfish;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Marlin,
			description: 'Unlock Marlin',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 3,
			type: UnlockChallengeType.Accumulate,
			goal: 5,
			description: 'Spend at least 27,000 points on purchasing weapons from Theo\'s weaponry in ONE game, 5 times.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Marlin Gem x1',
			material: ItemCode.MarlinGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.BlueCrab;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Intercept 10 darts with your weapon.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.KingCrab;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.BlueCrab,
			description: 'Unlock Blue Crab',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 2,
			description: 'Become the champion in TWO games.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Blue Crab Gem x1',
			material: ItemCode.BlueCrabGem,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.SpiderCrab;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.KingCrab,
			description: 'Unlock King Crab',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 3,
			type: UnlockChallengeType.Accumulate,
			goal: 20,
			description: 'Kill 20 PVP zone champions.',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay King Crab Gem x1',
			material: ItemCode.KingCrabGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);

unlockId = FishType.Crocodile;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Level,
			goal: 10,
			description: 'Reach Level 10',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 1,
			description: 'Enter the hidden temple.',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Kronosaurus;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Crocodile,
			description: 'Unlock Crocodile',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.OneGame,
			goal: 1,
			description: 'Be part of the team that discovered the Golden Idol',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Crocodile Gem x1',
			material: ItemCode.CrocodileGem,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
unlockId = FishType.Mosasaurus;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.UnlockFish,
			goal: FishType.Kronosaurus,
			description: 'Unlock Kronosaurus',
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Accumulate,
			goal: 100,
			description: 'Kill 100 Nemeses',
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Kronosaurus Gem x1',
			material: ItemCode.KronosaurusGem,
		},
		{
			unlockId,
			isMaster,
			index,
			step: 1,
			type: UnlockChallengeType.Contribute,
			goal: 10000,
			description: 'Pay Gold x10000g',
			material: ItemCode.Money,
		},
	],
	[
		{
			unlockId,
			isMaster,
			index,
			step: 2,
			type: UnlockChallengeType.Contribute,
			goal: 1,
			description: 'Pay Level Crystal x1',
			material: ItemCode.LevelCrystal,
		},
	],
]);
