












import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import NewsPost from './news/NewsPost.vue';

@Component({
	components: {
		NewsPost,
		NewsPost4: () => import('./news/NewsPost4.vue'),
		NewsPost3: () => import('./news/NewsPost3.vue'),
		NewsPost2: () => import('./news/NewsPost2.vue'),
		NewsPost1: () => import('./news/NewsPost1.vue'),
		NewsPost0: () => import('./news/NewsPost0.vue'),
	},
})
export default class Newspaper extends Vue {
	@Prop({ default: 10 }) public readRemains!: number;

	public showNews = 5;

	@Watch('readRemains')
	public onReadRemainsChanged(newVal: number) {
		if (this.readRemains < 1 && this.showNews > 0) {
			this.showNews--;
		}
	}

	public onMounted(value: number) {
		this.showNews = value;
	}
}
