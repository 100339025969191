

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
	TimeLimitedQuestCode,
	eventTimes,
isTimeQuestTime,
} from '@/game/infos/questTimeLimited';
import { Global } from '@/store/globalz';
import { ServerTime } from '@/store/api/serverTime';
import gamex from '@/store/modules/gamex';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
	},
})
export default class SideBossButtons extends Vue {
	public get hasIndicator() {
		return !settingx.now.readBoss1Shop || !settingx.now.readBoss1Dif2 || !settingx.now.readBoss1Dif3;
	}
	public get isActive() {
		return isTimeQuestTime(this.time, this.questCode);
	}
	public get timeLeft() {
		if (!eventTimes[this.questCode][1]) {
			return '';
		}

		const closeTime = eventTimes[this.questCode][1] || Date.now();
		let tl = closeTime - this.time;
		if (tl < 0) {
			tl = 0;
		}
		const day = Math.floor(tl / (24 * 60 * 60 * 1000));
		tl -= day * 24 * 60 * 60000;
		const hour = Math.floor(tl / (60 * 60 * 1000));
		tl -= hour * 60 * 60000;
		const min = Math.round(tl / 60000);
		// tl -= min * 60000
		return day > 0 ? `${day}d\u00A0${hour}h\u00A0${min}m` : `${hour}h ${min}m`;
		// tl = Math.ceil(tl / 1000);
		// const hour = Math.floor(tl / (60 * 60));
		// tl -= hour * 60 * 60;
		// const min = Math.floor(tl / 60);
		// tl -= min * 60;
		// let ss = tl.toString();
		// let mm = min.toString();
		// if (mm.length < 2) { mm = '0' + mm; }
		// if (ss.length < 2) { ss = '0' + ss; }
		// // tl -= min * 60000
		// return `${hour}:${mm}:${ss}`;
	}
	public questCode = TimeLimitedQuestCode.XmasPenguin;
	public interval: any;

	public time = Date.now();

	public onClick() {
		gamex.setGameStage('BossList');
	}
	public mounted() {
		this.interval = setInterval(() => {
			this.time = ServerTime.time;
		}, 1000);
	}
	public beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}
}
