

































































































































































































import { DecoSet } from '@/game/infos/decorativeInfos';
import {
	HighscoreData,
	ArmageddonHighscoreData,
	CraftingHighscoreData,
	WealthHighscoreData,
} from '@/game/infos/highscoreInfos';
import { Global } from '@/store/globalz';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { addComma, roundToFixed } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ScrollContainer from '../../ScrollContainer.vue';
import AmgdHighscoreComponent from './AmgdHighscoreComponent.vue';
import CraftingHighscoreComponent from './CraftingHighscoreComponent.vue';
import WealthHighscoreComponent from './WealthHighscoreComponent.vue';
import HighscoreComponent from './HighscoreComponent.vue';
import NemesesHighscoreComponent from './NemesesHighscoreComponent.vue';
import { wait } from '@/util/wait';

type Tab =
	| 'today'
	| 'allTime'
	| 'personal'
	| 'crafting'
	| 'armageddon'
	| 'wealth'
	| 'nemeses';

@Component({
	components: {
		ScrollContainer,
		HighscoreComponent,
		CraftingHighscoreComponent,
		AmgdHighscoreComponent,
		WealthHighscoreComponent,
		NemesesHighscoreComponent,
	},
})
export default class HighscoreTab extends Vue {
	public get personal() {
		return (
			userx.userDoc.record || {
				kills: 0,
				stabs: 0,
				killstreaks: 0,
				stabstreaks: 0,
				money: 0,
				score: 0,
				lastSurvivors: 0,
				firstExplorer: 0,

				totalStabs: 0,
				totalKills: 0,
				totalMoneyEarned: 0,
			}
		);
	}
	@Prop({ default: false }) public hasCloseBtn!: boolean;

	public tab: Tab = 'today';
	public addComma = addComma;
	public roundToFixed = roundToFixed;
	public highscores: HighscoreData[] = [];
	public todayHighscores: HighscoreData[] = [];
	public nemesesHighscores: HighscoreData[] = [];
	public craftingHighscores: CraftingHighscoreData[] = [];
	public amgdHighscores: ArmageddonHighscoreData[] = [];
	public wealthHighscores: WealthHighscoreData[] = [];
	public selectTab(tab: Tab) {
		this.tab = tab;
		const json = globalx.rankings;
		if (json) {
			if (tab === 'today' && this.todayHighscores.length === 0) {
				this.insertHighscoreData(this.todayHighscores, json.todayHighscores, 0);
			} else if (tab === 'allTime' && this.highscores.length === 0) {
				this.insertHighscoreData(this.highscores, json.highscores, 0);
			} else if (tab === 'nemeses' && this.nemesesHighscores.length === 0) {
				this.insertHighscoreData(this.nemesesHighscores, json.nemesesHighscores, 0);
			} else if (tab === 'crafting' && this.craftingHighscores.length === 0) {
				this.insertHighscoreData(this.craftingHighscores, json.craftingHighscores, 0);
			} else if (tab === 'wealth' && this.wealthHighscores.length === 0) {
				this.insertHighscoreData(this.wealthHighscores, json.wealthHighscores, 0);
			} else if (tab === 'armageddon' && this.amgdHighscores.length === 0) {
				this.insertHighscoreData(this.amgdHighscores, json.amgdHighscores, 0);
			}
		}
	}
	public async insertHighscoreData(list: any[], list2: any[], index: number) {
		list.push(list2[index]);
		if (list2[index + 1]) {
			await wait(5);
			this.insertHighscoreData(list, list2, index + 1);
		}
	}

	public get amgdTimeUsed() {
		const time = this.personal.amgdTimeUsed;
		if (!time) {
			return '';
		}
		let secs = Math.floor((time % 60000) / 1000).toString();
		if (secs.length < 2) {
			secs = '0' + secs;
		}
		const mins = Math.floor(time / 60000);
		return `${mins}m ${secs}s`;
	}
	public get explorerTimeUsed() {
		const time = this.personal.explorerTimeUsed;
		if (!time) {
			return '';
		}
		let secs = Math.floor((time % 60000) / 1000).toString();
		if (secs.length < 2) {
			secs = '0' + secs;
		}
		const mins = Math.floor(time / 60000);
		return `${mins}m ${secs}s`;
	}

	public mounted() {
		this.loadHighscore();
	}
	protected async loadHighscore() {
		const lobbyServerAddress = globalx.lobbyServerAddress;
		try {
			const response = await fetch(`${lobbyServerAddress}/highscores?v=ae`, {
				method: 'GET',
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			this.todayHighscores.length = 0;
			this.highscores.length = 0;
			this.nemesesHighscores.length = 0;
			this.craftingHighscores.length = 0;
			this.amgdHighscores.length = 0;
			this.wealthHighscores.length = 0;
			globalx.updateRankings(json);
			this.selectTab(this.tab);
		} catch (error) {
			throw error;
		}
	}
}
