import { TrackerData, TrackerType } from '@/game/multithread/viewState';
import { Ease, Tween } from '@/util/tweents';
import { Container } from 'pixi.js';
import { getParticle, Particle } from '../factory/particles';

export class TrackerControl {

	public static get(id: string, type: TrackerType) {
		const control = TrackerControl._pool.shift() || new TrackerControl();
		control.init(id, type);
		return control;

	}

	private static _pool: TrackerControl[] = [];

	public id = '';
	public type: TrackerType = TrackerType.None;

	public symbol = new Container();
	public sprite = getParticle(Particle.Target);

	constructor() {
		this.symbol.addChild(this.sprite);
	}

	public init(id: string, type: TrackerType) {
		this.id = id;
		this.type = type;
		if (type === TrackerType.Nemesis) {
			this.sprite.tint = 0xff3560;
			this.sprite.scale.set(3);
		} else {
			this.sprite.tint = type === TrackerType.Armageddon ? 0xff3560 : 0xffff00;
			this.sprite.scale.set(10);
		}
		Tween.get(this.sprite.scale).to({ x: .7, y: .7 }, 500, Ease.quadIn);
	}

	public update(data: TrackerData) {
		this.symbol.x = data.x;
		this.symbol.y = data.y;
	}

	public dispose() {
		TrackerControl._pool.push(this);
	}
}
