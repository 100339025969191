import { ItemCode } from '@/game/infos/itemInfos';
import { BLEND_MODES, Container, Sprite, Texture, TilingSprite } from 'pixi.js';
import Factory from '..';
import { getItemSprite } from '../itemParticles';
import { TempleChest } from './publiChestMixin';
import { ModeBanner } from './safeZoneMixin';
import { Preset } from '@/game/infos/preset';

export class DojoSymbol extends Container {


	constructor() {
		super();
		const dojoSize = Preset.DOJO_SIZE;
		const dojoSizeHalf = Preset.DOJO_SIZE / 2;
		const bg = new TilingSprite(Texture.from('images/dojoBg.jpg'), dojoSize, dojoSize);
		this.addChild(bg);
		bg.y = bg.x = -dojoSizeHalf;

		const armoryIv = Sprite.from('mainAssets_ArmoryIv');
		armoryIv.x = -486;
		armoryIv.y = 298 - 512 + dojoSizeHalf;

		const modeBanner = Factory.get(ModeBanner);

		modeBanner.x = 400;
		modeBanner.y = 517 - 512 + dojoSizeHalf;
		this.addChild(armoryIv, modeBanner);
	}
}
