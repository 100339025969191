






















































































import { Mail, MailType } from '@/game/infos/firestoreFiles';
import { ServerTime } from '@/store/api/serverTime';
import userx from '@/store/modules/userx';
import { ordinalSuffix } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import WeeklyContestComponent from './contests/WeeklyContestComponent.vue';
import globalx from '@/store/modules/globalx';
import MailComponent from './contests/MailComponent.vue';

@Component({
  components: {
    WeeklyContestComponent,
    MailComponent,
  },
})
export default class ContestTab extends Vue {
  public get mails() {
    const mails: Mail[] = [];
    const ct = Date.now();
    const inbox = { ...userx.inventory.inbox };
    if (inbox) {
      for (const mailId in inbox) {
        if (Object.prototype.hasOwnProperty.call(inbox, mailId)) {
          const mail = inbox[mailId];
          mails.push(mail);
        }
      }
    }
    return mails.sort((a, b) => b.sentTime - a.sentTime);
  }
  public get contestRead() {
    return userx.userSettings.contestRead || 0;
  }
  public get hasNewContest() {
    return this.contestRead < globalx.latestContest;
  }
  public get hasUnreadMail() {
    for (const mail of this.mails) {
      if (!mail.readTime) {
        return true;
      }
    }
    return false;
  }

  public get timeLeft() {
    const startDate = 1638028800000;
    const week = 604800000;
    let nextWeek = startDate;
    while (nextWeek < this.time) {
      nextWeek += week;
    }

    const closeTime = nextWeek;
    let tl = closeTime - this.time;
    if (tl < 0) {
      tl = 0;
    }
    const day = Math.floor(tl / (24 * 60 * 60 * 1000));
    tl -= day * 24 * 60 * 60000;
    const hour = Math.floor(tl / (60 * 60 * 1000));
    tl -= hour * 60 * 60000;
    const min = Math.round(tl / 60000);
    // tl -= min * 60000
    return day > 0 ? `${day}d ${hour}h` : `${hour}h ${min}m`;
  }
  @Prop({ default: false }) public hasCloseBtn!: boolean;

  public tab: 'contests' | 'inbox' = 'contests';
  public time = Date.now();
  public interval: any;

  public selectTab(tab: 'contests' | 'inbox') {
    this.tab = tab;
  }
  public mounted() {
    this.interval = setInterval(() => {
      this.time = ServerTime.time;
    }, 1000);
  }
  public beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
