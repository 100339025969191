import { StageControl } from '../controls/stageControl';
import io from 'socket.io-client';
import { BossPacket, JoinPacket, JoinResponse, TourneyPacket } from '../../game/multithread/main/socketModels';
import userx from '@/store/modules/userx';
import { BroadcastState, PlayerGameState } from '@/game/multithread/state';
import { StateManager } from './stateManager';
import { IClientCommunicator } from './iclientCommunicator';
import gamex, { ChatMessage } from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import { CommandJoinGame, CommandQuitGame, GameCommandType, IGameCommand } from '@/game/multithread/command';
import { wait } from '@/util/wait';
import { auth } from '@/store/firestore';
import { ServerInfo, ServerInitInfo } from '@/game/infos/serverInfos';
import { ViewStateManager } from './viewStateManager';
import { UpdateState } from '@/game/multithread/viewState';
import { decodeUpdateState } from '@/game/multithread/viewStateDecoder';
import { Global } from '@/store/globalz';
import { CrazyAd } from '../ads/crazyads';
import { DeepPartial, Dictionary } from '@/util/dictionary';
import { TourneyLobbyData, TourneyLobbyState, TourneyEvent, TourneyEventType, TourneyOptions } from '@/game/infos/roomInfosTny';
import { ChatType } from '@/game/infos/eventType';
import { SimpleChatMessage } from '@/game/multithread/main/isocket';
import { GameType } from '@/game/infos/roomInfos';
import { BossJoinOptions, BossLobbyData, BossLobbyEvent, BossLobbyEventType, BossLobbyState, BossOptions, ClientBossPlayerData } from '@/game/infos/roomInfosBoss';
import { FishType } from '@/game/infos/fishInfos';

export class ClientCommunicator implements IClientCommunicator {
	public get stageControl(): StageControl | null {
		return this._stageControl;
	}
	public set stageControl(value: StageControl | null) {
		this._stageControl = value;
		if (value) {
			value.communicator = this;
		}
	}
	public socket: SocketIOClient.Socket | null = null;
	public stateManager = new ViewStateManager();
	public interval: any;
	public sessionId = '';
	public lastSessionId = '';
	public uid = '';

	public startRender = false;
	protected autoDisconnectTime = 0;
	protected autoToReportTime = 0;

	private _stageControl: StageControl | null = null;

	public update() {
		const ct = Date.now();
		if (this.autoDisconnectTime > 0 && this.autoDisconnectTime <= ct) {
			if (gamex.gameType === GameType.Armageddon) {
				this.disconnect();
			} else if (gamex.gameType === GameType.Boss) {
				this.disconnectBoss();
				gamex.updateGameType(GameType.None);
				gamex.setGameStage('BossList');
			}
		} else if (this.autoToReportTime > 0 && this.autoToReportTime <= ct) {
			if (gamex.gameType === GameType.Boss) {
				this.toReportBoss();
			}
		} else {
			const currentGameState = this.stateManager.getCurrentState();
			if (gamex.gameStage !== 'Start' && (currentGameState.state === PlayerGameState.Active || currentGameState.state === PlayerGameState.Waiting)) {
				gamex.setGameStage('Start');
			} else if (currentGameState.state === PlayerGameState.GameOver) {
				if (gamex.gameStage !== 'End') {
					gamex.setGameStage('End');
				}
				if (gamex.gameType === GameType.Armageddon || gamex.gameType === GameType.Boss) {
					if (this.autoDisconnectTime === 0) {
						this.autoDisconnectTime = ct + 4000;
					}
				}
			} else if (currentGameState.state === PlayerGameState.ArmageddonOver) {
				if (gamex.gameStage !== 'End') {
					gamex.setGameStage('End');
				}
				if (gamex.gameType === GameType.Boss) {
					if (this.autoToReportTime === 0) {
						this.autoToReportTime = ct + 4000;
					}
				}
			}
			if (this.stageControl) {
				this.stageControl.update(currentGameState);
			}
		}
		if (this.startRender) {
			window.requestAnimationFrame(this.update.bind(this));
		}
	}

	public async connect(serverInfo: ServerInitInfo, lifes: number[]) {
		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		gamex.updateGameType(GameType.Armageddon);
		gamex.setGameStage('Connecting');
		gamex.setRejectReason('');
		gamex.setSocketErrorMessage('');
		if (!auth.currentUser) {
			gamex.setRejectReason('Please Log In');
			return;
		}
		const token = await auth.currentUser.getIdToken(true);

		if (this.socket) {
			this.socket.disconnect();
			return;
		}
		userx.snapShotFishUnlockProgress();
		const socket = this.socket = io(serverInfo.url);
		socket.on('disconnect', (e: any) => { gamex.setSocketErrorMessage('Game Server Connection Lost: ' + e); this.disconnect(); });
		socket.on('connect_failed', (e: any) => { gamex.setSocketErrorMessage('Game Server Connection Failed: ' + e); this.disconnect(); });
		socket.on('connect_error', (e: any) => { gamex.setSocketErrorMessage('Game Server Server Offline: ' + e); this.disconnect(); });

		const packet: JoinPacket = {
			clientVersion: globalx.clientVersionString,
			lifes,
			token,
			uid: '',
		};
		socket.once('connect', () => {
			this.stateManager.initState(39);

			gamex.updateCurrentGameServer(serverInfo);
			socket.emit('j', packet);
		});
		socket.once('j', async (res: JoinResponse) => {
			if (this.stageControl && res.success) {
				gamex.updateGameType(GameType.Armageddon);
				gamex.setGameStage('Joining');
				this.stageControl.sessionId = this.sessionId = this.lastSessionId = res.sessionId!;
				this.stageControl.build(res.mapData!);
			} else {
				gamex.updateGameType(GameType.Armageddon);
				gamex.setGameStage('Rejected');
				gamex.setRejectReason(res.rejectReason!);
				this.disconnect();
			}
		});
		socket.on('q', (n: number) => {
			gamex.updateGameType(GameType.Armageddon);
			gamex.setGameStage('Queue');
			gamex.setQueueNumber(n);
		});
		socket.on('s', (buff: ArrayBuffer) => {
			const state = decodeUpdateState(buff);
			if (this.stageControl) {
				this.stateManager.addState(state);
			} else {
				this.disconnect();
			}
		});
	}

	public join(joinRandomTeam = false, capStreak = false) {
		if (this.sessionId) {
			gamex.updateCurrenUtility('Map');
			const joinCom: CommandJoinGame = {
				type: GameCommandType.JoinGame,
				sessionId: this.sessionId,
				join: joinRandomTeam,
				capStreak,
			};
			this.sendCommand(joinCom);
			// PIXI.Ticker.shared.add(this.update);
			this.startRender = true;
			window.requestAnimationFrame(this.update.bind(this));

			if (Global.adsdk === 'crazyads') {
				CrazyAd.instance.gameplayStart();
			}
		}
	}

	public async createTourneyLobby(serverRegion: string, lobbyOptions?: TourneyOptions) {
	}
	public async joinTourneyLobby(serverInfo: ServerInitInfo, lobbyId: string) {
	}

	public tourneyLobbyData(lobbyData: DeepPartial<TourneyLobbyData>) {
	}
	public tourneyChat(message: SimpleChatMessage) {
	}
	public tourneyEvent(event: TourneyEvent) {
	}
	public lockTourneyLobby() {
	}
	public changePlayerOptions(options: any) {
	}
	public readyTourneyLobby() {
	}
	public unreadyTourneyLobby() {
	}
	public async sendChatTourney(msg: string) {
	}
	public async muteTourney(targetUid: string, value: boolean) {
	}

	public sendCommand(command: IGameCommand) {
		if (!this.socket) { return; }
		this.socket.emit('c', command);
	}

	public quit() {
		if (!this.socket) { return; }
		if (this.sessionId) {
			const command: CommandQuitGame = {
				type: GameCommandType.QuitGame,
				sessionId: this.sessionId,
			};
			this.sendCommand(command);
		}
	}

	public disconnect() {
		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;

		if (this.socket) {
			this.socket.removeAllListeners();
			this.socket.disconnect();
			this.socket = null;
		}
		this.sessionId = '';
		this.startRender = false;
		if (gamex.gameStage === 'End') {
			gamex.setGameStage('Reward');

			if (Global.adsdk === 'crazyads') {
				CrazyAd.instance.gameplayStop();
			}
		}

		// PIXI.Ticker.shared.remove(this.update);
		this.stateManager.clear();
		if (this.stageControl) {
			this.stageControl.sessionId = '';

			this.stageControl.update(this.stateManager.getCurrentState());
			this.stageControl.purge();
		}
	}

	public disconnectTourney() {
		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		this.clearRoom();
		if (this.socket) {
			this.socket.removeAllListeners();
			this.socket.disconnect();
			this.socket = null;
		}
		this.sessionId = '';

		gamex.updateTourneyLobbyData(null);

		if (this.stageControl) {
			this.stageControl.sessionId = '';
		}

		if (Global.adsdk === 'crazyads') {
			CrazyAd.instance.gameplayStop();
		}
	}
	public disconnectFromTourney(reason: string) {
		if (gamex.gameStage !== 'Reward' || !gamex.tnyLobbyData
			|| gamex.tnyLobbyData.currentRound !== gamex.tnyLobbyData.roomOptions.totalRound) {
			gamex.setSocketErrorMessage(reason);
			this.disconnectTourney();
			return;
		}
		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		this.clearRoom();
		if (this.socket) {
			this.socket.removeAllListeners();
			this.socket.disconnect();
			this.socket = null;
		}
		if (this.stageControl) {
			this.stageControl.sessionId = '';
		}
	}
	public clearRoom() {
		this.startRender = false;
		this.stateManager.clear();
		if (this.stageControl) {
			this.stageControl.update(this.stateManager.getCurrentState());
			this.stageControl.purge();
		}
	}

	public toReportBoss() {
		this.autoToReportTime = 0;
		this.clearRoom();
		gamex.clearTemporaryMessage();
		gamex.updateGameType(GameType.Boss);
		gamex.setGameStage('Reward');
	}
	public disconnectBoss() {
		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		this.clearRoom();
		if (this.socket) {
			this.socket.removeAllListeners();
			this.socket.disconnect();
			this.socket = null;
		}
		this.sessionId = '';

		gamex.updateBossLobbyData(null);

		if (this.stageControl) {
			this.stageControl.sessionId = '';
		}

		if (Global.adsdk === 'crazyads') {
			CrazyAd.instance.gameplayStop();
		}
	}

	public disconnectFromBoss(reason: string) {
		gamex.setSocketErrorMessage(reason);
		this.disconnectTourney();
	}

	public async findBossLobby(serverRegion: string, fishType: FishType, lobbyId = '0', options?: BossJoinOptions) {

		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		gamex.updateGameType(GameType.Boss);
		gamex.setGameStage('BossCreate');
		gamex.setRejectReason('');
		gamex.setSocketErrorMessage('');

		if (!auth.currentUser) {
			gamex.setRejectReason('Please Log In');
			return;
		}

		// const token = await auth.currentUser.getIdToken(true);

		// get serverInfo & lobbyId from fetch from dataServer to create tourneyLobby
		// automatically join lobby
		if (lobbyId.trim().length === 0) {
			lobbyId = '0';
		}
		try {
			// const response = await fetch(`${dataServerAddress}/username?v=1`, {
			const response = await fetch(`${globalx.lobbyServerAddress}/bossServer/${globalx.clientVersionString}/${serverRegion}/${lobbyId}`, {
				method: 'GET',
				headers: {
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const result = await response.json() as { server: ServerInitInfo, lobbyId?: string; };
			if (result.lobbyId) {
				this.joinBossLobby(result.server, result.lobbyId, fishType);
			} else {
				if (lobbyId !== '0') {
					gamex.setRejectReason('Lobby not found.');
				} else {
					this.joinBossLobby(result.server, '', fishType, options);
				}
			}

		} catch (error) {
			console.error(error);
			gamex.setRejectReason((error as any).message || (error as string));
		}
	}
	public async joinTeamBossLobby(serverRegion: string, fishType: FishType, options?: BossJoinOptions) {

		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		gamex.updateGameType(GameType.Boss);
		gamex.setGameStage('BossCreate');
		gamex.setRejectReason('');
		gamex.setSocketErrorMessage('');

		if (!auth.currentUser) {
			gamex.setRejectReason('Please Log In');
			return;
		}
		const teamId = userx.userDoc.teamId;
		if (!teamId) {
			gamex.setRejectReason('You do not have a team');
			return;
		}

		// const token = await auth.currentUser.getIdToken(true);

		// get serverInfo & lobbyId from fetch from dataServer to create tourneyLobby
		// automatically join lobby

		try {
			// const response = await fetch(`${dataServerAddress}/username?v=1`, {
			const response = await fetch(`${globalx.lobbyServerAddress}/bossTeamServer/${globalx.clientVersionString}/${serverRegion}/${teamId}`, {
				method: 'GET',
				headers: {
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const serverInfo = await response.json();
			this.joinBossLobby(serverInfo, userx.userDoc.teamId, fishType, options);
		} catch (error) {
			console.error(error);
			gamex.setRejectReason((error as any).message || (error as string));
		}
	}
	public async createBossLobby(serverRegion: string, fishType: FishType, options?: BossOptions | undefined) {

		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		gamex.updateGameType(GameType.Boss);
		gamex.setGameStage('BossCreate');
		gamex.setRejectReason('');
		gamex.setSocketErrorMessage('');

		if (!auth.currentUser) {
			gamex.setRejectReason('Please Log In');
			return;
		}

		// const token = await auth.currentUser.getIdToken(true);

		// get serverInfo & lobbyId from fetch from dataServer to create tourneyLobby
		// automatically join lobby

		try {

			// const response = await fetch(`${dataServerAddress}/username?v=1`, {
			const response = await fetch(`${globalx.lobbyServerAddress}/bossServer/${globalx.clientVersionString}/${serverRegion}/${userx.uid}`, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(options),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			this.joinBossLobby(json.server, json.lobbyId, fishType);
		} catch (error) {
			console.error(error);
			gamex.setRejectReason((error as any).message || (error as string));
		}
	}
	public async reconnectBossLobby(serverUrl: string, lobbyId: string) {

		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		gamex.setRejectReason('');
		gamex.setSocketErrorMessage('');


		if (!auth.currentUser) {
			gamex.setRejectReason('Please Log In');
			return;
		}
		const token = await auth.currentUser.getIdToken(true);
		const uid = this.uid = auth.currentUser.uid;

		const packet: BossPacket = {
			clientVersion: globalx.clientVersionString,
			token,
			uid,
			lobbyId,
			fishType: 3,
		};
		if (this.socket) {
			this.socket.removeAllListeners();
			this.socket.disconnect();
		}

		const socket = this.socket = io(serverUrl);
		socket.on('disconnect', (e: any) => { this.disconnectFromBoss('Game Server Connection Lost: ' + e); });
		socket.on('connect_failed', (e: any) => { this.disconnectFromBoss('Game Server Connection Failed: ' + e); });
		socket.on('connect_error', (e: any) => { this.disconnectFromBoss('Game Server Server Offline: ' + e); });

		socket.once('connect', () => {
			socket.emit('bRj', packet);
		});
		socket.on('bD', this.bossLobbyData.bind(this));
		socket.on('bE', this.bossEvent.bind(this));
		socket.on('bC', this.bossChat.bind(this));
		socket.on('bJ', async (res: JoinResponse) => {
			if (res.success && this.stageControl) {
				this.stageControl.sessionId = this.lastSessionId = this.sessionId = res.sessionId!;
				this.stageControl.buildBossMap();
				this.startRender = true;
				this.update();

				userx.snapShotBossRecord();
			} else {
				gamex.setRejectReason(res.rejectReason!);
				this.disconnectBoss();
			}
		});
		socket.on('s', (buff: ArrayBuffer) => {
			const state = decodeUpdateState(buff);
			if (this.stageControl) {
				this.stateManager.addState(state);
			} else {
				this.disconnectBoss();
			}
		});

	}
	public changeBossPlayerOptions(options?: any) {
		this.socket?.emit('bO', options);
	}
	public changeBossRoomOptions(options?: Partial<BossOptions>) {
		this.socket?.emit('bRO', options);
	}
	public readyBossLobby() {
		this.stateManager.initState(53);
		this.socket?.emit('bR');
	}
	public unreadyBossLobby() {
		this.socket?.emit('bU');
	}
	public async sendChatBoss(msg: string) {
		this.socket?.emit('bC', msg);
	}
	public async muteBoss(targetUid: string, value: boolean) {
		if (value) {
			this.socket?.emit('bM', targetUid);
		} else {
			this.socket?.emit('bUm', targetUid);
		}
	}
	public claimHostBossLobby(): void {
		this.socket?.emit('bCH');
	}
	public rejectClaimHostBossLobby(): void {
		this.socket?.emit('bRCH');
	}
	public async kickBoss(targetUid: string) {
		this.socket?.emit('bK', targetUid);
	}
	public async sosBoss() {
		this.socket?.emit('bSos');
	}
	public bossChat(message: SimpleChatMessage) {
		if (!message.ct) {
			const msg: ChatMessage = {
				chatType: ChatType.Public,
				senderUid: message.uid,
				senderName: gamex.bossLobbyData?.participants[message.uid].name || `Stabfish_${message.uid.substring(0, 3)}`,
				msg: message.msg,
				t: Date.now(),
			};
			if (msg.senderName === 'dragonwhites' && msg.senderUid === 'fTaakPLzz0c8WTgQd8hkPZvaYu03') {
				msg.isDraco = true;
			}
			gamex.addSystemMessage(msg);
		} else {
			const msg: ChatMessage = {
				chatType: message.ct,
				senderName: message.uid || 'System',
				msg: message.msg,
				t: Date.now(),
			};
			gamex.addSystemMessage(msg);
		}
	}
	public bossLobbyData(lobbyData: DeepPartial<BossLobbyData>) {
		if (lobbyData.hostClaimTime !== undefined) {
			if (lobbyData.hostClaimTime !== 0) {
				lobbyData.hostClaimTime += Date.now();
			}
		}
		let announceHost = false;
		if (!gamex.bossLobbyData) {
			gamex.updateBossLobbyData(lobbyData as BossLobbyData);
			return;
		} else {
			announceHost = lobbyData.host ? true : false;
		}
		const currentData = gamex.bossLobbyData;
		const quits = lobbyData.quits;
		const participants = lobbyData.participants;
		const roomOptions = lobbyData.roomOptions;
		delete lobbyData.quits;
		delete lobbyData.participants;
		delete lobbyData.roomOptions;

		const newLobbyData: BossLobbyData = { ...currentData, ...lobbyData as Partial<BossLobbyData> };
		if (lobbyData.id) {
			gamex.addSystemMessage({
				chatType: ChatType.System,
				senderName: 'System',
				t: Date.now(),
				msg: `New room ID: ${lobbyData.id}`,
			});
		}
		if (roomOptions) {
			if (roomOptions.privacy !== undefined) {
				gamex.addSystemMessage({
					chatType: ChatType.ChatSystem,
					senderName: 'System',
					t: Date.now(),
					msg: `The room is now a ${roomOptions.privacy === 2 ? 'team-only' : roomOptions.privacy === 1 ? 'private' : 'public'} room.`,
				});
			}
			if (roomOptions.difficulty !== undefined) {

				gamex.addSystemMessage({
					chatType: ChatType.ChatSystem,
					senderName: 'System',
					t: Date.now(),
					msg: `The host changed the difficulty to ${roomOptions.difficulty}★`,
				});
			}
			const currentRoomOptions = currentData.roomOptions;
			const newRoomOptions = { ...currentRoomOptions, ...roomOptions as Partial<BossOptions> };
			newLobbyData.roomOptions = newRoomOptions as BossOptions;
		}

		if (lobbyData.state === BossLobbyState.Closed) {
			gamex.addSystemMessage({
				chatType: ChatType.System,
				senderName: 'System',
				t: Date.now(),
				msg: `The room is closed, chat system is no longer available. Kindly claim your points and join another game.`,
			});
		}
		if (quits) {
			for (const quit of quits) {
				const pdata = newLobbyData.participants[quit];
				if (pdata) {
					gamex.addSystemMessage({
						chatType: ChatType.ChatSystem,
						senderName: 'System',
						t: Date.now(),
						msg: `<u>${pdata.name}</u> left the room.`,
					});
				}
				delete newLobbyData.participants[quit];
			}
			newLobbyData.participants = { ...newLobbyData.participants };
		}
		if (participants) {
			for (const id in participants) {
				if (Object.prototype.hasOwnProperty.call(participants, id)) {
					const pdata = participants[id]!;
					if (!newLobbyData.participants[id]) {
						gamex.addSystemMessage({
							chatType: ChatType.ChatSystem,
							senderName: 'System',
							t: Date.now(),
							msg: `<u>${pdata.name}</u> joined the room.`,
						});
						newLobbyData.participants[id] = { ...pdata } as ClientBossPlayerData;
					} else {
						newLobbyData.participants[id] = { ...newLobbyData.participants[id], ...pdata };
						if (pdata.mutedYou !== undefined) {
							const eventer = newLobbyData.participants[id];
							if (eventer) {
								gamex.purgeChatSystemMessage(`${eventer.name} ${!pdata.mutedYou ? 'muted' : 'unmuted'} you`);
								gamex.addSystemMessage({
									chatType: ChatType.ChatSystem,
									t: Date.now(),
									msg: `${eventer.name} ${pdata.mutedYou ? 'muted' : 'unmuted'} you`,
								});
							}
						}
					}
				}
			}
			newLobbyData.participants = { ...newLobbyData.participants };
		}


		gamex.updateBossLobbyData(newLobbyData);

		if (announceHost && newLobbyData.participants[newLobbyData.host]) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				senderName: 'System',
				t: Date.now(),
				msg: `${newLobbyData.participants[newLobbyData.host].name} has become the new host of the room.`,
			});
		}
	}
	public bossEvent(event: BossLobbyEvent) {
		if (event.type === BossLobbyEventType.Join) {
			gamex.setGameStage('BossLobby');
			this.bossLobbyData(event.data);
		} else if (event.type === BossLobbyEventType.Error) {
			Global.$bvModal.msgBoxOk(event.data, {
				titleHtml: `<i class="fa fa-exclamation-triangle"></i> Error`,
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				modalClass: 'funny-modal',
			});
		} else if (event.type === BossLobbyEventType.RejectClaim) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				senderName: 'System',
				t: Date.now(),
				msg: `The host denied to change host.`,
			});
			this.bossLobbyData(event.data);

		} else if (event.type === BossLobbyEventType.MaintenanceClosing) {
			gamex.addSystemMessage({
				chatType: ChatType.Warning,
				senderName: 'Warning',
				t: Date.now(),
				msg: `Maintenance Incoming! Inactive room will be closing down in 10 seconds!`,
			});
		} else if (event.type === BossLobbyEventType.Transfer) {
			gamex.addSystemMessage({
				chatType: ChatType.Warning,
				senderName: 'Warning',
				t: Date.now(),
				msg: `Sit tight! Armageddon is hitting the sea! We are changing sea!`,
			});
			this.reconnectBossLobby(event.data.url, event.data.lobbyId);
			this.bossLobbyData({ state: BossLobbyState.Transfering });
		}
	}
	protected async joinBossLobby(serverInfo: ServerInitInfo, lobbyId: string, fishType: FishType, options?: BossJoinOptions) {

		this.autoDisconnectTime = 0;
		this.autoToReportTime = 0;
		gamex.updateGameType(GameType.Boss);
		gamex.setGameStage('Connecting');
		gamex.setRejectReason('');
		gamex.setSocketErrorMessage('');
		gamex.updateBossLobbyData(null);

		if (!auth.currentUser) {
			gamex.setRejectReason('Please Log In');
			return;
		}
		const token = await auth.currentUser.getIdToken(true);
		const uid = this.uid = auth.currentUser.uid;

		if (this.socket) {
			this.socket.disconnect();
		}
		// userx.snapShotFishUnlockProgress();
		const socket = this.socket = io(serverInfo.url);
		socket.on('disconnect', (e: any) => { this.disconnectFromBoss('Game Server Connection Lost: ' + e); });
		socket.on('connect_failed', (e: any) => { this.disconnectFromBoss('Game Server Connection Failed: ' + e); });
		socket.on('connect_error', (e: any) => { this.disconnectFromBoss('Game Server Server Offline: ' + e); });

		const packet: BossPacket = {
			clientVersion: globalx.clientVersionString,
			token,
			uid,
			lobbyId,
			fishType,
		};
		if (options) {
			packet.options = options;
		}
		socket.once('connect', () => {
			gamex.updateCurrentGameServer(serverInfo);
			gamex.updateGameType(GameType.Boss);
			gamex.setGameStage('Joining');
			socket.emit('bJ', packet);
			if (Global.adsdk === 'crazyads') {
				CrazyAd.instance.gameplayStart();
			}
		});
		socket.on('bD', this.bossLobbyData.bind(this));
		socket.on('bE', this.bossEvent.bind(this));
		socket.on('bC', this.bossChat.bind(this));
		socket.on('bJ', async (res: JoinResponse) => {
			if (res.success && this.stageControl) {
				this.stageControl.sessionId = this.lastSessionId = this.sessionId = res.sessionId!;
				this.stageControl.buildBossMap();
				this.startRender = true;
				this.update();

				userx.snapShotBossRecord();
			} else {
				gamex.setRejectReason(res.rejectReason!);
				this.disconnectBoss();
			}
		});
		socket.on('s', (buff: ArrayBuffer) => {
			const state = decodeUpdateState(buff);
			if (this.stageControl) {
				this.stateManager.addState(state);
			} else {
				this.disconnectBoss();
			}
		});
	}
}
