import { EmojiType } from '@/game/infos/emojiInfos';
import { EventType } from '@/game/infos/eventType';
import { StabfishAccountInventory, TeamMemberData } from '@/game/infos/firestoreFiles';
import { Preset } from '@/game/infos/preset';
import { TipId } from '@/game/infos/tipInfos';
import { CommandAction, CommandBleedoutAction, CommandBuyEquipSpearAction, CommandEmojiAction, CommandChatAction, GameCommandType, IGameCommand, CommandPlayeModeAction } from '@/game/multithread/command';
import { PartialMapData, SecretRoomData } from '@/game/multithread/roomMap';
import { BroadcastBuffEvent, BroadcastEmojiEvent, BroadcastJoinTempTeamEvent, BroadcastNewMemberEvent, BroadcastOuroborosOpenEvent, BroadcastSecretRoomEvent, BroadcastState, BroadcastWhirlpoolEvent, PlayerGameState } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import store from '@/store';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import settingx from '@/store/modules/settingx';
import tipx from '@/store/modules/tipx';
import Collision from '@/util/collide';
import { Dictionary } from '@/util/dictionary';
import { getScreenWidth } from '@/util/getScreenWidth';
import { Pool } from '@/util/pool';
import { Point, Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Common } from 'matter-js';
import { Bounds, Container, Graphics, InteractionEvent, Rectangle } from 'pixi.js';
import Factory from '../factory';
import { UiControlSymbol } from '../factory/assets/controls/UiControlMixin';
import { TeamMateEmoji, TeamMateIndicator } from '../factory/assets/teamMateIndicatorMixin';
import { disposeParticle, getParticle, Particle } from '../factory/particles';
import { clientBoost } from '../io/viewStateManager';
import { LongSoundEfx } from '../sound/LongSoundEfx';
import { SoundEfx } from '../sound/SoundEfx';
import { StageControl, TeamMemberInfo } from './stageControl';
import { TrackerControl } from './trackerControl';
import { GameType } from '@/game/infos/roomInfos';
import { PlayerMode } from '@/game/infos/enums';
import { ItemCode } from '@/game/infos/itemInfos';

type FingerType = 'none' | 'dpad' | 'skill1' | 'skill2' | 'skill3';
type InteractionTarget = 'polar' | 'iv' | 'polar2' | 'iv2' | 'publicChest' | 'templeChest' | 'templeNote' | 'idol' | 'dojoLobby' | 'bannerRewards' | 'dojoLobby2' | 'dojoSettings' | 'polar3' | 'iv3';

export class UiControl {

	public screenScale = Math.min(Math.max(document.documentElement.clientWidth / getScreenWidth(), 1), 2);

	public width = 0;
	public height = 0;
	public stageControl: StageControl;
	public askingQuit = false;

	public symbol = Factory.get(UiControlSymbol);

	public mode: '' | 'mouse' | 'touch' = '';
	public rightHanded = !settingx.now.leftHanded;

	public fingers: Dictionary<FingerType> = {};
	public touchStarts: Dictionary<Point> = {};

	public actionCommand: CommandAction = {
		sessionId: '',
		type: GameCommandType.Action,
		direction: -90,
		speedScale: 0,
		skill1: false,
		skill2: false,
		skill3: false,
	};
	public bleedoutCommand: CommandBleedoutAction = {
		sessionId: '',
		type: GameCommandType.Bleedout,
		delay: false,
		experdite: false,
	};

	public zoom = 1;
	public spearHeight = 100;

	public tickCount = 0;

	public teamMate1 = Factory.get(TeamMateIndicator);
	public teamMate2 = Factory.get(TeamMateIndicator);
	public teamMate3 = Factory.get(TeamMateIndicator);
	public teamMate4 = Factory.get(TeamMateIndicator);
	public teamMateEmojis: Dictionary<TeamMateEmoji> = {};

	public allTrackers: Dictionary<TrackerControl> = {};

	public teamLocatorContainer = new Container();
	public teamEmojiContainer = new Container();

	public lastEmojiSentTime = 0;

	public mouseX = 0;
	public mouseY = 0;

	public safeZoneX = 0;


	public isPublicChestOpened = false;
	public isTempleChestOpened = false;
	public isTalkingToPolar = false;
	public isOpeningIvHeal = false;
	public isOpeningIdol = false;
	public isInPolarZone = false;
	public isInSecretRoom = false;
	public isOpeningDojoLobby = false;
	public isOpeningDojoSettings = false;
	public isOpeningClaimGladiator = false;
	public secretRoomFoundTime = Number.MAX_SAFE_INTEGER;
	public secretRoomData: SecretRoomData | null = null;
	public gladiatorRewards = 0;
	public state: ViewState | null = null;

	public bleedingSoundNormal = new LongSoundEfx('bleedNormal');


	public interactionConditions: { [key in InteractionTarget]: (state: ViewState) => boolean } = {
		polar: () => this.isInPolarZone,
		iv: () => this.isInPolarZone,
		bannerRewards: () => this.isInPolarZone && Boolean(this.gladiatorRewards),
		dojoLobby: () => this.isInPolarZone,
		idol: (state: ViewState) => this.isInPolarZone && state.rt >= this.secretRoomFoundTime + 1500,
		publicChest: (state: ViewState) => {
			return state.publicChestX !== 0 && !this.isPublicChestOpened && !state.publicChestOpened
				&& Rotate.dist(state.x, state.y, state.publicChestX, state.publicChestY) < 700;
		},
		polar2: (state: ViewState) => this.isInSecretRoom && state.rt >= this.secretRoomFoundTime + 1500,
		iv2: (state: ViewState) => this.isInSecretRoom && state.rt >= this.secretRoomFoundTime + 1500,
		dojoLobby2: (state: ViewState) => this.isInSecretRoom && state.rt >= this.secretRoomFoundTime + 1500,
		templeChest: (state: ViewState) => this.isInSecretRoom && state.rt >= this.secretRoomFoundTime + 1500 && !this.isTempleChestOpened && !state.templeChestOpened,
		templeNote: (state: ViewState) => this.isInSecretRoom && state.rt >= this.secretRoomFoundTime + 1500,
		dojoSettings: (state: ViewState) => Boolean(state.dojoId),
		polar3: (state: ViewState) => Boolean(state.dojoId),
		iv3: (state: ViewState) => Boolean(state.dojoId),
	};
	public interactionBounds: { [key in InteractionTarget]: { x: number, y: number, x2: number, y2: number; } } = {
		polar: { x: 0, y: 0, x2: 0, y2: 0 },
		iv: { x: 0, y: 0, x2: 0, y2: 0 },
		idol: { x: 0, y: 0, x2: 0, y2: 0 },
		bannerRewards: { x: 0, y: 0, x2: 0, y2: 0 },
		dojoLobby: { x: 0, y: 0, x2: 0, y2: 0 },
		publicChest: { x: 0, y: 0, x2: 0, y2: 0 },
		polar2: { x: 0, y: 0, x2: 0, y2: 0 },
		iv2: { x: 0, y: 0, x2: 0, y2: 0 },
		templeChest: { x: 0, y: 0, x2: 0, y2: 0 },
		templeNote: { x: 0, y: 0, x2: 0, y2: 0 },
		dojoLobby2: { x: 0, y: 0, x2: 0, y2: 0 },
		dojoSettings: { x: 0, y: 0, x2: 0, y2: 0 },
		polar3: { x: 0, y: 0, x2: 0, y2: 0 },
		iv3: { x: 0, y: 0, x2: 0, y2: 0 },
	};
	public currentInteractionTarget: InteractionTarget | '' = '';

	protected onTouchStartBound = this.onTouchStart.bind(this);
	protected onTouchEndBound = this.onTouchEnd.bind(this);
	protected onTouchMoveBound = this.onTouchMove.bind(this);
	protected onMouseMoveBound = this.onMouseMove.bind(this);
	protected onRightDownBound = this.onRightDown.bind(this);
	protected onLeftDownBound = this.onLeftDown.bind(this);
	protected onLeftUpBound = this.onLeftUp.bind(this);
	protected onRightUpBound = this.onRightUp.bind(this);
	protected dojoId = 0;

	constructor(stageControl: StageControl) {
		this.bleedingSoundNormal.volume = 3;

		Global.$root.$on('attemptQuitGame', () => {
			this.onQuitGame();
		});
		window.addEventListener('keyup', (e) => {
			if (gamex.toShowChat) {
				if (e.code === 'Escape') {
					gamex.updateToShowChat(false);
				}
			} else {
				if (e.code === 'Escape') {
					if (
						gamex.currentGameState.state === PlayerGameState.Active ||
						gamex.currentGameState.state === PlayerGameState.Stabab ||
						gamex.currentGameState.state === PlayerGameState.FishBolt ||
						gamex.currentGameState.state === PlayerGameState.ArmageddonOver
					) {
						this.onQuitGame();
					} else
						if (gamex.gameStage === 'TourneyLobby' || gamex.gameStage === 'TourneyInterRound' ||
							(gamex.gameType === GameType.Tourney && gamex.gameStage === 'Reward' && gamex.tnyLobbyData!.currentRound < gamex.tnyLobbyData!.roomOptions.totalRound)) {
							this.onLeaveTourney();
						} else if (gamex.gameStage === 'BossLobby' ||
							(gamex.gameType === GameType.Boss && gamex.gameStage === 'Reward' && gamex.tnyLobbyData!.currentRound < gamex.tnyLobbyData!.roomOptions.totalRound)) {
							this.onLeaveBoss();
						}

				} else if (e.code === settingx.keys.skill1) {
					this.skill1Up();
				} else if (e.code === settingx.keys.skill2) {
					this.skill2Up();
				} else if (e.code === settingx.keys.skill3) {
					this.skill3Up();
				} else if (e.code === settingx.keys.wpnR) {
					this.changeWeapon(true);
				} else if (e.code === settingx.keys.wpnL) {
					this.changeWeapon(false);
				} else if (!isNaN(Number(e.key))) {

					const n = Number(e.key);
					this.sendEmoji(n);
				} else if (e.code === 'Backquote') {
					if (gamex.gameType === GameType.Armageddon || gamex.gameType === GameType.Boss) {
						gamex.updateToShowChat(true);
					}
				}
			}
		});
		window.addEventListener('keydown', (e) => {
			if (!gamex.toShowChat) {
				if (e.code === settingx.keys.skill1) {
					this.skill1Down();
				} else if (e.code === settingx.keys.skill2) {
					this.skill2Down();
				} else if (e.code === settingx.keys.skill3) {
					this.skill3Down();
				}
			}
		});

		this.stageControl = stageControl;
		this.zoom = this.stageControl.cameraControl.symbol.scale.x;

		this.changeMode(settingx.now.control);
		this.changeDpadLocation(!settingx.now.leftHanded);
		store.watch((states, getters) => getters['settingx/now'],
			(newValue, oldValue) => {
				this.changeMode(newValue.control);
				this.changeDpadLocation(!settingx.now.leftHanded);
			});
		this.utilityChanged();
		store.watch((states: any, getters) => states.gamex && states.gamex.currentUtility,
			(newValue, oldValue) => {
				this.utilityChanged();
			});

		store.watch((states, getters) => getters['gamex/buffs'],
			(newValue, oldValue) => {
				if (newValue !== oldValue) {
					this.symbol.buffIconsContainer.update(newValue);
				}
			});

		store.watch((states: any, getters) => states.userx && states.userx.inventory,
			(newValue: StabfishAccountInventory, oldValue: StabfishAccountInventory) => {
				const gladiator = newValue.accuRewards?.gladiator;
				this.gladiatorRewards = gladiator ? gladiator[ItemCode.Money] : 0;
				const roomControl = this.stageControl.cameraControl.roomControl;
				if (this.gladiatorRewards) {
					roomControl.safeZoneSymbol.modeBanner.rewards.visible = true;
				} else {
					roomControl.safeZoneSymbol.modeBanner.rewards.visible = false;
				}
			});
		Global.overlayGameIndicators.addChild(this.teamLocatorContainer, this.teamEmojiContainer);
	}

	public setSessionId(sessionid: string) {
		this.actionCommand.sessionId = sessionid;
		this.bleedoutCommand.sessionId = sessionid;
	}

	public setSize(width: number, height: number) {
		const screenWidth = getScreenWidth();
		this.screenScale = this.symbol.screenScale = (document.documentElement.clientWidth / screenWidth) / Global.appScale;
		if (this.screenScale < 1) { this.screenScale = this.symbol.screenScale = 1; }
		// if (this.screenScale > 2) { this.screenScale = this.symbol.screenScale = 2; }

		this.width = width;
		this.height = height;
		this.symbol.setSize(width, height, this.rightHanded);
		this.zoom = this.stageControl.cameraControl.symbol.scale.x;

	}
	public buildMiniMap(mapData: PartialMapData) {
		this.symbol.minimap.build(mapData);
		this.isPublicChestOpened = false;
		this.isTempleChestOpened = false;
		this.isTalkingToPolar = false;
		this.isOpeningIvHeal = false;
		this.isOpeningIdol = false;
		this.isOpeningDojoLobby = false;
		this.isOpeningDojoSettings = false;
		this.isOpeningClaimGladiator = false;

		this.safeZoneX = mapData.safe ? mapData.safe.x * Preset.SECTOR_SIZE + Preset.SECTOR_SIZE / 2 : -10000000;
		this.interactionBounds.polar.x = this.safeZoneX - 68;
		this.interactionBounds.polar.x2 = this.safeZoneX + 68;
		this.interactionBounds.polar.y = -131;
		this.interactionBounds.polar.y2 = 11;
		this.interactionBounds.iv.x = this.safeZoneX + 160;
		this.interactionBounds.iv.x2 = this.safeZoneX + 205;
		this.interactionBounds.iv.y = -156;
		this.interactionBounds.iv.y2 = -10;
		this.interactionBounds.idol.x = this.safeZoneX - 110;
		this.interactionBounds.idol.x2 = this.safeZoneX - 68;
		this.interactionBounds.idol.y = -78;
		this.interactionBounds.idol.y2 = -11;

		this.interactionBounds.dojoLobby.x = this.safeZoneX - 220;
		this.interactionBounds.dojoLobby.x2 = this.safeZoneX - 134;
		this.interactionBounds.dojoLobby.y = -187;
		this.interactionBounds.dojoLobby.y2 = -17;
		this.interactionBounds.bannerRewards.x = this.safeZoneX - 223;
		this.interactionBounds.bannerRewards.x2 = this.safeZoneX - 113;
		this.interactionBounds.bannerRewards.y = -48;
		this.interactionBounds.bannerRewards.y2 = -3;

		this.interactionBounds.publicChest.x = -10000;
		this.interactionBounds.publicChest.x2 = -10000;
		this.interactionBounds.publicChest.y = -10000;
		this.interactionBounds.publicChest.y2 = -10000;
	}

	public getInteractionTarget(state: ViewState, x: number, y: number): InteractionTarget | '' {
		for (const key in this.interactionConditions) {
			if (Object.prototype.hasOwnProperty.call(this.interactionConditions, key)) {
				const foo = this.interactionConditions[key];
				if (foo(state) && Object.prototype.hasOwnProperty.call(this.interactionBounds, key)) {
					const bound = this.interactionBounds[key];
					if (x > bound.x && x < bound.x2 && y > bound.y && y < bound.y2) {
						return key as InteractionTarget;
					}
				}
			}
		}
		return '';
	}

	public update(state: ViewState, teamMembers: TeamMemberInfo[]) {

		this.symbol.blackout.alpha = 0.95 - state.oxy * 1.1;

		this.tickCount++;
		this.symbol.skill1.update(state.skill1, state.skillActive1, state.skillDisabled1);
		this.symbol.skill2.update(state.skill2, state.skillActive2, state.skillDisabled2, state.skillCooldown2);
		this.symbol.skill3.update(state.skill3, state.skillActive3, state.skillDisabled3, state.skillCooldown3);
		// if (state.my.state === PlayerGameState.Active) {
		// 	this.symbol.bulletContainer.update(state.my.sk2);
		// }
		if (gamex.gameType === GameType.Armageddon) {

			this.isInPolarZone = Rotate.dist(state.x, state.y, this.safeZoneX, 0) < Preset.SECTOR_SIZE / 1.85;
			let isInSecretRoom = false;
			if (this.secretRoomData) {
				const scr = this.secretRoomData;
				if (state.x > scr.x * Preset.SECTOR_SIZE && state.x < (scr.x + 1) * Preset.SECTOR_SIZE
					&& state.y > scr.y * Preset.SECTOR_SIZE && state.y < (scr.y + 1) * Preset.SECTOR_SIZE
				) {
					isInSecretRoom = true;
				}
				this.isInSecretRoom = isInSecretRoom;
			}

			if (this.interactionBounds.publicChest.x === -10000 && state.publicChestX !== 0) {
				this.interactionBounds.publicChest.x = state.publicChestX - 110;
				this.interactionBounds.publicChest.x2 = state.publicChestX + 110;
				this.interactionBounds.publicChest.y = state.publicChestY - 140;
				this.interactionBounds.publicChest.y2 = state.publicChestY + 30;
			}
			this.symbol.minimap.update(teamMembers, state);
			if (state.armageddonStarted) {
				this.symbol.minimap.updateArmageddon(state);
			}

			if (state.state !== this.state?.state) {
				this.isPublicChestOpened = state.publicChestOpened;
				this.isTempleChestOpened = state.templeChestOpened;
			}

		}
		if (state.state === PlayerGameState.Active || state.state === PlayerGameState.Stabab || state.state === PlayerGameState.FishBolt) {
			this.symbol.bar.visible = true;
			this.symbol.buffIconsContainer.visible = true;
			this.symbol.skill1.visible = true;
			this.symbol.skill2.visible = true;
			this.symbol.skill3.visible = true;
			if (state.state === PlayerGameState.Stabab) {
				this.closeAllModal();
				this.symbol.bar.setProgress(state.blood);
				this.symbol.bar.barColor = 0xA20000;
				// if skill1 is active, then it is experditing blledout, skill2 delay, else none
				const bleeedoutscale = state.skillActive1 ? 2 : state.skillActive2 ? 0.5 : 1;
				this.bleedingSoundNormal.start();
				this.bleedingSoundNormal.sound.speed = bleeedoutscale === 2 ? 1.3 : bleeedoutscale === 1 ? 1 : 0.7;
				// do blood particle
				const factor = bleeedoutscale === 2 ? 1 : bleeedoutscale === 1 ? 6 : 15;
				if (this.tickCount % factor === 0) {
					const blood = getParticle(Particle.Circle);
					blood.scale.set(Math.random() * .1 + .1);
					blood.tint = 0xA20000;
					blood.alpha = 1;
					Tween.get(blood).to({ x: Math.random() * -50 - 20, y: Math.random() * 80 - 40 }, 300).call(
						() => {
							this.symbol.bloodParticleContainer.removeChild(blood);
							blood.scale.set(1);
							blood.x = blood.y = 0;
							blood.alpha = 1;
							blood.tint = 0xffffff;
							disposeParticle(Particle.Circle, blood);
						},
					);
					this.symbol.bloodParticleContainer.addChild(blood);
				}

			} else if (state.state === PlayerGameState.FishBolt) {
				this.closeAllModal();
				this.symbol.bar.visible = false;
			} else {
				this.bleedingSoundNormal.stop();
				this.symbol.bar.setProgress(state.stamina);
				this.symbol.bar.barColor = 0xECFF00;

				const player = state.players[state.pid];
				if (player) {
					this.spearHeight = player.spearHeight;
				}
			}
		} else {
			this.bleedingSoundNormal.stop();
			this.symbol.bar.visible = false;
			this.symbol.buffIconsContainer.visible = false;
			this.symbol.skill1.visible = false;
			this.symbol.skill2.visible = false;
			this.symbol.skill3.visible = false;
			this.closeAllModal();
		}

		const zoom = this.zoom = this.stageControl.cameraControl.symbol.scale.x;
		const mx = this.width / 2 - 30;
		const my = this.height / 2 - 30;

		if (teamMembers.length > 1) {
			for (let i = 0; i < 4; i++) {
				const indicator = this['teamMate' + (i + 1)] as TeamMateIndicator;
				indicator.visible = state.state !== PlayerGameState.GameOver;
				const player = teamMembers[i];
				if (!player) {
					if (indicator.parent) {
						indicator.parent.removeChild(indicator);
					}
				} else {

					if (!indicator.parent) {
						this.teamLocatorContainer.addChild(indicator);
					}
					// console.log(this.stageControl.cameraControl.roomControl.symbol.x, player.x, zoom, this.width)

					let x = (player.x + this.stageControl.cameraControl.roomControl.symbol.x) * zoom;
					let y = (player.y + this.stageControl.cameraControl.roomControl.symbol.y) * zoom;
					if (x < mx && x > -mx && y < my && y > -my) {

						if (player.isStabbed) {

							const offset = 15 * zoom;
							indicator.x = x + this.width / 2 + offset;
							indicator.y = y + this.height / 2 + offset;
						} else {

							indicator.x = x + this.width / 2;
							indicator.y = y + this.height / 2 - 140 * zoom;
						}

						indicator.update(player, true);
						indicator.scale.set(0.5 * zoom);
					} else {

						indicator.update(player, false);
						if (x > mx) { x = mx; }
						if (x < -mx) { x = -mx; }
						if (y > my) { y = my; }
						if (y < -my) { y = -my; }
						indicator.x = x + this.width / 2;
						indicator.y = y + this.height / 2;
						indicator.scale.set((player.isStabbed ? 3 : 1) * zoom);
					}
				}
			}

		} else {
			for (let i = 0; i < 4; i++) {
				const indicator = this['teamMate' + (i + 1)] as TeamMateIndicator;
				if (indicator.parent) {
					indicator.parent.removeChild(indicator);
				}
			}
		}

		for (const evt of state.evts) {
			if (evt.type === EventType.Emoji) {
				const e = evt as BroadcastEmojiEvent;
				if (e.tid === state.tid && e.pid !== state.pid) {
					if (!this.teamMateEmojis[e.pid]) {
						this.teamMateEmojis[e.pid] = TeamMateEmoji.get();
						this.teamMateEmojis[e.pid].playerId = e.pid;
					}
					const emoji = this.teamMateEmojis[e.pid];
					emoji.show(e.eid, e.pos);
					this.teamEmojiContainer.addChild(emoji);
				}
			} else if (evt.type === EventType.NewMember) {
				const e = evt as BroadcastNewMemberEvent;
				this.teamToast(e.name + ' joined your team.');
				new SoundEfx('teammate').play({ volume: 0.3 });
			} else if (evt.type === EventType.JoinTempTeam) {
				const e = evt as BroadcastJoinTempTeamEvent;
				this.teamToast(e.isOldTeam ? 'Team found and joined.' : 'No available team. New team created.');

			} else if (evt.type === EventType.SecretRoomData) {
				const e = evt as BroadcastSecretRoomEvent;
				this.secretRoomData = e;
				const sx = (e.x + .5) * Preset.SECTOR_SIZE;
				const sy = (e.y + .5) * Preset.SECTOR_SIZE;

				this.interactionBounds.polar2.x = sx - 485;
				this.interactionBounds.polar2.y = sy + 294;
				this.interactionBounds.polar2.x2 = sx - 375;
				this.interactionBounds.polar2.y2 = sy + 508;
				this.interactionBounds.iv2.x = sx - 318;
				this.interactionBounds.iv2.y = sy + 361;
				this.interactionBounds.iv2.x2 = sx - 257;
				this.interactionBounds.iv2.y2 = sy + 508;
				this.interactionBounds.templeChest.x = sx - 92;
				this.interactionBounds.templeChest.y = sy + 382;
				this.interactionBounds.templeChest.x2 = sx + 94;
				this.interactionBounds.templeChest.y2 = sy + 508;
				this.interactionBounds.templeNote.x = sx - 50;
				this.interactionBounds.templeNote.y = sy + 30;
				this.interactionBounds.templeNote.x2 = sx + 50;
				this.interactionBounds.templeNote.y2 = sy + 98;
				this.interactionBounds.dojoLobby2.x = sx + 357;
				this.interactionBounds.dojoLobby2.y = sy + 327;
				this.interactionBounds.dojoLobby2.x2 = sx + 443;
				this.interactionBounds.dojoLobby2.y2 = sy + 497;


			} else if (evt.type === EventType.SecretRoomFound) {
				const e = evt as BroadcastSecretRoomEvent;
				this.secretRoomFoundTime = e.t;
			}
		}
		if (state.dojoId !== this.dojoId) {
			this.dojoId = state.dojoId;
			const sx = state.dojoId * Preset.DOJO_GAP;
			const sy = Preset.DOJO_Y - 512 + Preset.DOJO_SIZE / 2;

			this.interactionBounds.polar3.x = sx - 485;
			this.interactionBounds.polar3.y = sy + 294;
			this.interactionBounds.polar3.x2 = sx - 375;
			this.interactionBounds.polar3.y2 = sy + 508;
			this.interactionBounds.iv3.x = sx - 318;
			this.interactionBounds.iv3.y = sy + 361;
			this.interactionBounds.iv3.x2 = sx - 257;
			this.interactionBounds.iv3.y2 = sy + 508;
			this.interactionBounds.dojoSettings.x = sx + 357;
			this.interactionBounds.dojoSettings.y = sy + 327;
			this.interactionBounds.dojoSettings.x2 = sx + 443;
			this.interactionBounds.dojoSettings.y2 = sy + 497;
			this.closeAllModal();
		}
		for (const pid in this.teamMateEmojis) {
			if (Object.prototype.hasOwnProperty.call(this.teamMateEmojis, pid)) {
				const emoji = this.teamMateEmojis[pid];
				const ct = Date.now();
				const stopUpdate = emoji.next(ct);
				if (!stopUpdate) {

					emoji.scale.set(zoom);
					const index = state.team.findIndex((m) => m.toString() === pid);
					if (index !== -1) {
						const indicator = this['teamMate' + (index + 1)] as TeamMateIndicator;
						emoji.x = indicator.x;
						emoji.y = indicator.y;
						// don't show emoji if they are within sight
						emoji.visible = indicator.text2.visible;
					} else {
						const pos = emoji.showPosition;
						let x = (pos.x + this.stageControl.cameraControl.roomControl.symbol.x) * zoom;
						let y = (pos.y + this.stageControl.cameraControl.roomControl.symbol.y) * zoom;
						if (x < mx && x > -mx && y < my && y > -my) {
							emoji.visible = false;
						} else {
							if (x > mx) { x = mx; }
							if (x < -mx) { x = -mx; }
							if (y > my) { y = my; }
							if (y < -my) { y = -my; }
							emoji.x = x + this.width / 2;
							emoji.y = y + this.height / 2;
							emoji.visible = true;
						}
					}
				}
			}
		}

		const allTrackers: Dictionary<TrackerControl> = {};
		for (const trackerData of state.ts) {
			const tid = trackerData.id + '|' + trackerData.type;
			if (this.allTrackers[tid]) {
				allTrackers[tid] = this.allTrackers[tid];
				delete this.allTrackers[tid];
			} else {
				allTrackers[tid] = TrackerControl.get(tid, trackerData.type);
				this.symbol.trackerContainer.addChild(allTrackers[tid].symbol);
			}
			const tracker = allTrackers[tid];
			const pos = trackerData;
			let x = (pos.x + this.stageControl.cameraControl.roomControl.symbol.x) * zoom;
			let y = (pos.y + this.stageControl.cameraControl.roomControl.symbol.y) * zoom;
			if (x < mx && x > -mx && y < my && y > -my) {


			} else {

				if (x > mx) { x = mx; }
				if (x < -mx) { x = -mx; }
				if (y > my) { y = my; }
				if (y < -my) { y = -my; }
			}
			tracker.symbol.x = x + this.width / 2;
			tracker.symbol.y = y + this.height / 2;
			tracker.symbol.scale.set(zoom);
		}

		for (const tid in this.allTrackers) {
			if (Object.prototype.hasOwnProperty.call(this.allTrackers, tid)) {
				const tracker = this.allTrackers[tid];
				this.symbol.trackerContainer.removeChild(tracker.symbol);
				tracker.dispose();
			}
		}
		this.allTrackers = allTrackers;

		const roomMouseX = (this.mouseX - this.stageControl.cameraControl.symbol.x) / zoom - this.stageControl.cameraControl.roomControl.symbol.x;
		const roomMouseY = (this.mouseY - this.stageControl.cameraControl.symbol.y) / zoom - this.stageControl.cameraControl.roomControl.symbol.y;
		// this.stageControl.cameraControl.roomControl.symbol.addChild(this.symbol.pointer);
		// this.symbol.pointer.x = roomMouseX;
		// this.symbol.pointer.y = roomMouseY;

		// this.clickingMyself = Rotate.dist(roomMouseX, roomMouseY, state.x, state.y) < 50;

		this.currentInteractionTarget = this.getInteractionTarget(state, roomMouseX, roomMouseY);
		this.symbol.touchable.cursor = !this.currentInteractionTarget
			? 'url("/images/cursors/crosshairX.png") 10 10, crosshair'
			: this.currentInteractionTarget === 'publicChest' || this.currentInteractionTarget === 'templeChest' || this.currentInteractionTarget === 'bannerRewards'
				? 'url("/images/cursors/hand.png") 16 16, pointer'
				: 'url("/images/cursors/talk.png") 14 14, pointer';

		this.state = state;
	}

	public closeAllModal() {

		if (this.isTalkingToPolar) {
			Global.$root.$bvModal.hide('polar-modal');
		}
		if (this.isOpeningIvHeal) {
			Global.$root.$bvModal.hide('iv-modal');
		}
		if (this.isOpeningIdol) {
			Global.$root.$bvModal.hide('idol-modal');
		}
		if (this.isOpeningDojoLobby) {
			Global.$root.$bvModal.hide('dojoLobby-modal');
		}
		if (this.isOpeningDojoSettings) {
			Global.$root.$bvModal.hide('dojoSettings-modal');
		}
		if (this.isOpeningClaimGladiator) {
			Global.$root.$bvModal.hide('claimGladiator-modal');
		}
	}
	public purge() {
		for (const pid in this.teamMateEmojis) {
			if (Object.prototype.hasOwnProperty.call(this.teamMateEmojis, pid)) {
				const emoji = this.teamMateEmojis[pid];
				emoji.dispose();
			}
		}
		this.state = null;
		this.secretRoomData = null;
		this.isInSecretRoom = false;
		this.secretRoomFoundTime = Number.MAX_SAFE_INTEGER;
		gamex.updateToShowChat(false);
	}

	public utilityChanged() {
		const utility = gamex.currentUtility;
		this.symbol.minimap.visible = utility === 'Map';
		this.symbol.emojiContainer.visible = utility === 'Emoji';
	}

	public sendEmoji(emojiType: EmojiType) {
		if (this.lastEmojiSentTime + 500 < Date.now()) {

			const command: CommandEmojiAction = {
				type: GameCommandType.Emoji,
				sessionId: this.stageControl.sessionId,
				eid: emojiType,
			};
			Global.clientCommunicator.sendCommand(command);
			this.lastEmojiSentTime = Date.now();
		}
	}

	public changeDpadLocation(rightHanded: boolean) {
		if (this.rightHanded === rightHanded) { return; }
		this.rightHanded = rightHanded;
		this.symbol.setSize(this.width, this.height, this.rightHanded);
	}

	public changeMode(mode: 'mouse' | 'touch') {
		if (this.mode === mode) { return; }
		// console.log(mode);
		this.mode = mode;
		if (mode === 'touch') {
			this.mouseX = this.mouseY = 0;
			this.symbol.dpad.visible = true;
			this.symbol.pointer.visible = true;
			this.symbol.touchable.on('touchstart', this.onTouchStartBound);
			this.symbol.touchable.on('touchend', this.onTouchEndBound)
				.on('touchendoutside', this.onTouchEndBound);
			this.symbol.touchable.on('touchmove', this.onTouchMoveBound);

			this.symbol.touchable.off('mousemove', this.onMouseMoveBound);
			this.symbol.touchable.off('rightdown', this.onRightDownBound);
			this.symbol.touchable.off('mousedown', this.onLeftDownBound);
			this.symbol.touchable.off('mouseup', this.onLeftUpBound);
			this.symbol.touchable.off('rightup', this.onRightUpBound);
			this.symbol.touchable.off('mouseupoutside', this.onLeftUpBound);
			this.symbol.touchable.off('rightupoutside', this.onRightUpBound);

		} else {
			this.symbol.dpad.visible = false;
			this.symbol.pointer.visible = false;
			this.symbol.touchable.off('touchstart', this.onTouchStartBound);
			this.symbol.touchable.off('touchend', this.onTouchEndBound)
				.off('touchendoutside', this.onTouchEndBound);
			this.symbol.touchable.off('touchmove', this.onTouchMoveBound);


			this.symbol.touchable.on('mousedown', this.onLeftDownBound);
			this.symbol.touchable.on('rightdown', this.onRightDownBound);
			this.symbol.touchable.on('mouseup', this.onLeftUpBound);
			this.symbol.touchable.on('mouseupoutside', this.onLeftUpBound);
			this.symbol.touchable.on('rightup', this.onRightUpBound);
			this.symbol.touchable.on('rightupoutside', this.onRightUpBound);
			this.symbol.touchable.on('mousemove', this.onMouseMoveBound);
		}
	}
	public async onQuitGame() {
		if (
			!(
				gamex.currentGameState.state === PlayerGameState.Active ||
				gamex.currentGameState.state === PlayerGameState.Stabab ||
				gamex.currentGameState.state === PlayerGameState.FishBolt ||
				gamex.currentGameState.state === PlayerGameState.ArmageddonOver
			)
			|| this.askingQuit) { return false; }
		if (gamex.gameType === GameType.Boss && gamex.currentGameState.state === PlayerGameState.ArmageddonOver) {
			{ return false; }
		}
		this.askingQuit = true;
		let value: any;
		if (gamex.gameType === GameType.Armageddon || gamex.gameType === GameType.Boss) {
			value = await Global.$bvModal.msgBoxConfirm(
				'Are you sure you want to quit the game?',
				{
					size: 'sm',
					cancelVariant: 'link',
					okVariant: 'danger',
					okTitle: 'Yes',
					cancelTitle: 'No',
					modalClass: 'funny-modal',
					titleHtml: `Quit Game`,
				},
			);
		} else {
			value = await Global.$bvModal.msgBoxConfirm(
				'Are you sure you want to surrender?',
				{
					size: 'sm',
					cancelVariant: 'link',
					okVariant: 'danger',
					okTitle: 'Yes',
					cancelTitle: 'No',
					modalClass: 'funny-modal',
					titleHtml: 'Surrender',
				},
			);
		}
		this.askingQuit = false;
		if (value) {
			Global.clientCommunicator.quit();
			return true;
		}
		return false;
	}
	public async onLeaveTourney() {
		if (gamex.gameType !== GameType.Tourney
			|| this.askingQuit) { return false; }

		if (gamex.gameStage === 'Reward' && gamex.tnyLobbyData
			&& gamex.tnyLobbyData.currentRound === gamex.tnyLobbyData.roomOptions.totalRound) {
			gamex.updateGameType(GameType.None);
			gamex.setGameStage('TourneyList');
			Global.clientCommunicator.disconnectTourney();
			return true;
		}
		this.askingQuit = true;
		const value = await Global.$bvModal.msgBoxConfirm(
			'Are you sure you want to leave the game early?',
			{
				size: 'sm',
				cancelVariant: 'link',
				okVariant: 'danger',
				okTitle: 'Yes',
				cancelTitle: 'No',
				modalClass: 'funny-modal',
				titleHtml: `Leave Game`,
			},
		);

		this.askingQuit = false;
		if (value) {
			gamex.updateGameType(GameType.None);
			gamex.setGameStage('TourneyList');
			Global.clientCommunicator.disconnectTourney();
			return true;
		}
		return false;
	}
	public async onLeaveBoss() {
		if (gamex.gameType !== GameType.Boss
			|| this.askingQuit) { return false; }

		this.askingQuit = true;
		const value = await Global.$bvModal.msgBoxConfirm(
			'Are you sure you want to leave the room?',
			{
				size: 'sm',
				cancelVariant: 'link',
				okVariant: 'danger',
				okTitle: 'Yes',
				cancelTitle: 'No',
				modalClass: 'funny-modal',
				titleHtml: `Leave Game`,
			},
		);

		this.askingQuit = false;
		if (value) {
			gamex.updateGameType(GameType.None);
			gamex.setGameStage('BossList');
			Global.clientCommunicator.disconnectBoss();
			return true;
		}
		return false;
	}

	public openPublicChest() {
		const state = this.state?.state;
		if (state !== PlayerGameState.Active && state !== PlayerGameState.Stabab && state !== PlayerGameState.FishBolt) {
			return;
		}
		this.stageControl.cameraControl.roomControl.openPublicChest();
		this.isPublicChestOpened = true;
		const command: IGameCommand = {
			type: GameCommandType.OpenPublicChest,
			sessionId: this.stageControl.sessionId,
		};
		Global.clientCommunicator.sendCommand(command);
		new SoundEfx('openChest').play();
		if (tipx.currentShowingTipId === TipId.Chest && !tipx.tipsRead[TipId.Chest]) {
			tipx.achieveTip(TipId.Chest);
		}
	}
	public openTempleChest() {
		const state = this.state?.state;
		if (state !== PlayerGameState.Active && state !== PlayerGameState.Stabab && state !== PlayerGameState.FishBolt) {
			return;
		}
		this.stageControl.cameraControl.roomControl.openTempleChest();
		this.isTempleChestOpened = true;
		const command: IGameCommand = {
			type: GameCommandType.OpenTempleChest,
			sessionId: this.stageControl.sessionId,
		};
		Global.clientCommunicator.sendCommand(command);
		new SoundEfx('openChest').play();
	}
	public talkToPolarBear() {

		if (tipx.currentShowingTipId === TipId.Armory && !tipx.tipsRead[TipId.Armory]) {
			tipx.achieveTip(TipId.Armory);
		}
		Global.$root.$bvModal.show('polar-modal');
		this.isTalkingToPolar = true;
		this.actionCommand.speedScale = 0;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 0.25 },
			1000,
		);
		new SoundEfx('polar' + Math.ceil(Math.random() * 4) as any).play();
	}

	public closePolar() {
		this.isTalkingToPolar = false;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 1 },
			1000,
		);
	}
	public openIvHeal() {
		if (this.state?.armageddonStarted) {
			return;
		}
		Global.$root.$bvModal.show('iv-modal');
		this.isOpeningIvHeal = true;
		this.actionCommand.speedScale = 0;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 0.25 },
			1000,
		);
	}

	public closeIvHeal() {
		this.isOpeningIvHeal = false;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 1 },
			1000,
		);
	}
	public openIdol() {

		Global.$root.$bvModal.show('idol-modal');
		this.isOpeningIdol = true;
		this.actionCommand.speedScale = 0;
	}
	public closeIdol() {
		this.isOpeningIdol = false;
	}
	public openDojoLobby() {
		if (this.state?.armageddonStarted) {
			return;
		}
		Global.$root.$bvModal.show('dojoLobby-modal');
		this.isOpeningDojoLobby = true;
		this.actionCommand.speedScale = 0;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 0.25 },
			1000,
		);
	}
	public closeDojoLobby() {
		this.isOpeningDojoLobby = false;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 1 },
			1000,
		);
	}

	public openDojoSettings() {
		if (this.state?.armageddonStarted) {
			return;
		}
		Global.$root.$bvModal.show('dojoSettings-modal');
		this.isOpeningDojoSettings = true;
		this.actionCommand.speedScale = 0;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 0.25 },
			1000,
		);
	}
	public closeDojoSettings() {
		this.isOpeningDojoSettings = false;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 1 },
			1000,
		);
	}

	public openClaimGladiator() {
		Global.$root.$bvModal.show('claimGladiator-modal');
		this.isOpeningClaimGladiator = true;
		this.actionCommand.speedScale = 0;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 0.25 },
			1000,
		);
	}

	public closeClaimGladiator() {
		this.isOpeningClaimGladiator = false;
		Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
			{ volume: 1 },
			1000,
		);
	}

	public changeWeapon(toRight = true) {

		if (gamex.currentGameState.spearsBought > 1 && gamex.currentGameState.state === PlayerGameState.Active) {
			const command: CommandBuyEquipSpearAction = {
				type: GameCommandType.EquipSpear,
				st: toRight ? -1 : -3,
				sessionId: Global.clientCommunicator.sessionId,
			};
			Global.clientCommunicator.sendCommand(command);
			new SoundEfx('changeWeapon').play({ volume: 0.3 });

			if (tipx.currentShowingTipId === TipId.ChangeWeapon && !tipx.tipsRead[TipId.ChangeWeapon]) {
				tipx.achieveTip(TipId.ChangeWeapon);
			}
			if (tipx.currentShowingTipId === TipId.ChangeWeaponTouch && !tipx.tipsRead[TipId.ChangeWeaponTouch]) {
				tipx.achieveTip(TipId.ChangeWeaponTouch);
			}
		}
	}

	public teamToast(content: string) {
		const h = Global.$root.$createElement;
		Global.$root.$bvToast.toast('abc', {
			variant: 'dark',
			title: [
				h('span', { class: ['text-center', 'text-light', 'w-100'] }, content),
			],
			toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-team',
			solid: false,
			autoHideDelay: 1500,
			noCloseButton: true,
			appendToast: true,
		});
	}

	protected skill1Down() {
		this.symbol.skill1.pressed = true;
		this.actionCommand.skill1 = true;
		this.bleedoutCommand.experdite = true;
		// clientBoost(true);
	}
	protected skill1Up() {
		this.symbol.skill1.pressed = false;
		this.actionCommand.skill1 = false;
		this.bleedoutCommand.experdite = false;
		// clientBoost(false);
	}
	protected skill2Down() {
		this.symbol.skill2.pressed = true;
		this.actionCommand.skill2 = true;
		this.bleedoutCommand.delay = true;
	}
	protected skill2Up() {
		this.symbol.skill2.pressed = false;
		this.actionCommand.skill2 = false;
		this.bleedoutCommand.delay = false;
	}
	protected skill3Down() {
		this.symbol.skill3.pressed = true;
		this.actionCommand.skill3 = true;
	}
	protected skill3Up() {
		this.symbol.skill3.pressed = false;
		this.actionCommand.skill3 = false;
	}
	protected activateInteractionTarget() {
		if (this.currentInteractionTarget) {
			if (this.currentInteractionTarget === 'polar' || this.currentInteractionTarget === 'polar2' || this.currentInteractionTarget === 'polar3') {
				this.talkToPolarBear();
			} else if (this.currentInteractionTarget === 'iv' || this.currentInteractionTarget === 'iv2' || this.currentInteractionTarget === 'iv3') {
				this.openIvHeal();
			} else if (this.currentInteractionTarget === 'idol' || this.currentInteractionTarget === 'templeNote') {
				this.openIdol();
			} else if (this.currentInteractionTarget === 'publicChest') {
				this.openPublicChest();
			} else if (this.currentInteractionTarget === 'templeChest') {
				this.openTempleChest();
			} else if (this.currentInteractionTarget === 'dojoLobby' || this.currentInteractionTarget === 'dojoLobby2') {
				this.openDojoLobby();
			} else if (this.currentInteractionTarget === 'dojoSettings') {
				this.openDojoSettings();
			} else if (this.currentInteractionTarget === 'bannerRewards') {
				this.openClaimGladiator();
			}
			return true;
		}
		return false;
	}

	protected onLeftDown(e: InteractionEvent) {
		if (!this.activateInteractionTarget()) {
			if (settingx.now.skill1 === 'Left-Click') {
				this.skill1Down();
			} else if (settingx.now.skill2 === 'Left-Click') {
				this.skill2Down();
			} else if (settingx.now.skill3 === 'Left-Click') {
				this.skill3Down();
			} else if (settingx.now.wpnL === 'Left-Click') {
				this.changeWeapon(false);
			} else if (settingx.now.wpnR === 'Left-Click') {
				this.changeWeapon(true);
			}
		}
	}
	protected onLeftUp(e: InteractionEvent) {
		if (settingx.now.skill1 === 'Left-Click') {
			this.skill1Up();
		} else if (settingx.now.skill2 === 'Left-Click') {
			this.skill2Up();
		} else if (settingx.now.skill3 === 'Left-Click') {
			this.skill3Up();
		}
	}
	protected onRightDown(e: InteractionEvent) {
		if (settingx.now.skill1 === 'Right-Click') {
			this.skill1Down();
		} else if (settingx.now.skill2 === 'Right-Click') {
			this.skill2Down();
		} else if (settingx.now.skill3 === 'Right-Click') {
			this.skill3Down();
		} else if (settingx.now.wpnL === 'Right-Click') {
			this.changeWeapon(false);
		} else if (settingx.now.wpnR === 'Right-Click') {
			this.changeWeapon(true);
		}
	}
	protected onRightUp(e: InteractionEvent) {
		if (settingx.now.skill1 === 'Right-Click') {
			this.skill1Up();
		} else if (settingx.now.skill2 === 'Right-Click') {
			this.skill2Up();
		} else if (settingx.now.skill3 === 'Right-Click') {
			this.skill3Up();
		}
	}
	protected onMouseMove(e: InteractionEvent) {
		const point = e.data.getLocalPosition(e.currentTarget);
		const cx = this.width / 2;
		const cy = this.height / 2;
		// const mx = e.pageX;
		// const my = e.pageY;
		const mx = point.x;
		const my = point.y;
		const direction = Rotate.degree(
			cx, cy, mx, my,
		);
		const speedScale = Common.clamp(
			(Rotate.dist(
				cx, cy, mx, my,
			) -
				45) /
			40,
			0,
			1,
		);
		if (this.isTalkingToPolar || this.isOpeningIvHeal || this.isOpeningIdol || gamex.watchingAds || this.isOpeningDojoLobby || this.isOpeningDojoSettings || this.isOpeningClaimGladiator) {
			this.actionCommand.speedScale = 0;
		} else {
			this.actionCommand.direction = direction;
			this.actionCommand.speedScale = gamex.toShowChat ? 0 : speedScale * (this.currentInteractionTarget ? 0.5 : 1);
		}
		// this.symbol.pointer.x = mx;
		// this.symbol.pointer.y = my;
		this.mouseX = mx;
		this.mouseY = my;
	}

	protected onTouchStart(e: InteractionEvent) {
		const id = e.data.pointerId;
		const point = e.data.getLocalPosition(e.currentTarget);
		let type: FingerType = 'none';
		const { dpad, pointer, skill1, skill2, skill3 } = this.symbol;
		const dpadDistance = Rotate.dist(point.x, point.y, dpad.x, dpad.y);
		if (dpadDistance < 70 * this.screenScale) {
			type = 'dpad';
			this.calculateDpadPoint(point.x, point.y, dpadDistance);
			pointer.visible = true;
		} else if (Rotate.dist(point.x, point.y, skill1.x, skill1.y) < 30 * this.screenScale) {
			skill1.pressed = true;
			this.actionCommand.skill1 = true;
			this.bleedoutCommand.experdite = true;
			type = 'skill1';
		} else if (Rotate.dist(point.x, point.y, skill2.x, skill2.y) < 30 * this.screenScale) {
			skill2.pressed = true;
			this.actionCommand.skill2 = true;
			this.bleedoutCommand.delay = true;
			type = 'skill2';
		} else if (Rotate.dist(point.x, point.y, skill3.x, skill3.y) < 30 * this.screenScale) {
			skill3.pressed = true;
			this.actionCommand.skill3 = true;
			type = 'skill3';
		}
		this.fingers[id] = type;
		this.touchStarts[id] = point;

		const state = this.state;
		if (state) {
			const roomMouseX = (point.x - this.stageControl.cameraControl.symbol.x) / this.zoom - this.stageControl.cameraControl.roomControl.symbol.x;
			const roomMouseY = (point.y - this.stageControl.cameraControl.symbol.y) / this.zoom - this.stageControl.cameraControl.roomControl.symbol.y;

			this.currentInteractionTarget = this.getInteractionTarget(state, roomMouseX, roomMouseY);
			if (
				!this.activateInteractionTarget() &&
				Rotate.dist(roomMouseX, roomMouseY, state.x, state.y) < 50
			) {
				this.changeWeapon();
			}
		}

	}
	protected onTouchMove(e: InteractionEvent) {

		const id = e.data.pointerId;
		const point = e.data.getLocalPosition(e.currentTarget);
		const type = this.fingers[id];
		const { dpad, skill1 } = this.symbol;
		if (type === 'dpad') {
			const dpadDistance = Rotate.dist(point.x, point.y, dpad.x, dpad.y);
			this.calculateDpadPoint(point.x, point.y, dpadDistance);
		}
	}

	protected onTouchEnd(e: InteractionEvent) {
		const id = e.data.pointerId;
		const type = this.fingers[id];
		const { dpad, pointer, skill1, skill2, skill3 } = this.symbol;
		if (type === 'dpad') {
			dpad.handle.x = 0;
			dpad.handle.y = 0;
			pointer.visible = false;
		} else if (type === 'skill1') {
			skill1.pressed = false;
			this.actionCommand.skill1 = false;
			this.bleedoutCommand.experdite = false;
		} else if (type === 'skill2') {
			skill2.pressed = false;
			this.actionCommand.skill2 = false;
			this.bleedoutCommand.delay = false;
		} else if (type === 'skill3') {
			skill3.pressed = false;
			this.actionCommand.skill3 = false;
			this.bleedoutCommand.delay = false;
		} else if (type === 'none') {
			const point = e.data.getLocalPosition(e.currentTarget);
			const deltaX = this.touchStarts[id] ? point.x - this.touchStarts[id].x : 0;
			if (deltaX > 10) {
				this.changeWeapon(true);
			} else if (deltaX < -10) {
				this.changeWeapon(false);
			}
		}
		delete this.fingers[id];
		delete this.touchStarts[id];
	}

	protected calculateDpadPoint(mx: number, my: number, dist: number) {
		const { dpad, pointer, skill1 } = this.symbol;
		const distance = Math.min(dist, 40 * this.screenScale);
		this.actionCommand.speedScale = distance > 30 * this.screenScale ? 1 : distance < 10 * this.screenScale ? 0 : (distance - 10 * this.screenScale) / (20 * this.screenScale);
		const radian = Collision.radian(dpad.x, dpad.y, mx, my);
		const angle = Rotate.r2d(radian);
		this.actionCommand.direction = angle;

		const p = Collision.move(radian, distance / this.screenScale);
		dpad.handle.x = p.x;
		dpad.handle.y = p.y;

		const scale = this.spearHeight * 0.037 * this.zoom;
		// console.log(this.spearHeight);

		pointer.x = this.width / 2 + p.x * scale;
		pointer.y = this.height / 2 + p.y * scale;
	}
}
