import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsFishBolt } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { BurningEffect, FrozenEffect, ParalyzedEffect, ZompkinEffect } from '../hazardMixin';
import { PlayerSymbol } from '../playerMixin';
import { MiscSymbol } from './miscSymbol';
import { PlayerMode, PlayerType, StababType } from '@/game/infos/enums';
import { Pumpkinbab } from '@/client/controls/stababControl';
import { skinGroups } from '../skinAssets';
import { rgbToInteger, TintContainer } from '../../helper';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];
export class SsFishBoltSymbol extends Container {
}
export class SsFishBoltSymbolController implements MiscSymbol {

	public static get() {
		return SsFishBoltSymbolController._pool.get();
	}

	private static _pool: Pool<SsFishBoltSymbolController> = new Pool(SsFishBoltSymbolController);


	public area: Graphics = new Graphics();
	public data: MiscDataSsFishBolt | null = null;
	public isSameTeam = false;

	public container = new Container();
	public tintContainer = new TintContainer();
	public symbol: MovieClip | null = null;
	public fishSymbol: PlayerSymbol = PlayerSymbol.get();
	public paralyzedEffect: ParalyzedEffect | null = null;
	public burningEffect: BurningEffect | null = null;
	public frozenEffect: FrozenEffect | null = null;
	public zompkinEffect: ZompkinEffect | null = null;

	public playedSound = false;

	public constructor() {
		this.container.scale.set(0.6);
		this.container.addChild(this.tintContainer);
	}

	public dispose(room: RoomControl) {
		this.area.clear();
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.container.parent) {
			this.container.parent.removeChild(this.container);
		}
		if (this.data && this.data.s) {
			const player = room.allPlayers[this.data.s.pid];
			if (player) {
				if (this.fishSymbol.topContainer.children[0]) {
					player.symbol.topContainer.addChild(this.fishSymbol.topContainer.children[0]);
				}
				if (this.fishSymbol.bottomContainer.children[0]) {
					player.symbol.bottomContainer.addChild(this.fishSymbol.bottomContainer.children[0]);
				}
			}
		}
		if (this.data?.s?.ft === -StababType.Pumpkin) {
			Factory.pool(Pumpkinbab, this.symbol);
			this.symbol!.scale.set(1);
			this.symbol!.angle = 0;
			this.symbol = null;
		}
		this.fishSymbol.disposeDecos();
		this.fishSymbol.reset();
		this.tintContainer.removeChildren();

		this.playedSound = false;
		this.data = null;
		this.removeParalizedEffect();
		this.removeBurningEffect();
		this.removeFrozenEffect();
		this.removeZompkinEffect();

		SsFishBoltSymbolController._pool.pool(this);


	}

	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataSsFishBolt;
		if (!this.data || data.ac !== this.data.ac) {
			this.isSameTeam = data.tid === state.tid || state.playerMode === PlayerMode.PvE && data.tid === '_pve';
		}
		if (data.ac) {
			this.tintContainer.angle += 23;
			if (data.s && (!this.data || !this.data.s)) {

				if (data.s.ft > 0) {
					this.tintContainer.addChild(this.fishSymbol);
					this.fishSymbol.setAction(data.s.d === 1 ? 'dead' : 'idle');

					if (data.s.d === -1) {
						this.zompkinEffect = Factory.get(ZompkinEffect);
						this.tintContainer.addChild(this.zompkinEffect);
						// this.zompkinEffect.gotoAndStop('idle');
					}
					const player = room.allPlayers[data.s.pid];
					const skinGroup = player && player.toShowSkin && player.data && player.data.skinGroup || 0;
					this.fishSymbol.setFishType(data.s.ft, skinGroup);
					this.fishSymbol.brightness = 1;

					if (player) {
						this.fishSymbol.topContainer.visible = this.fishSymbol.bottomContainer.visible = player.toShowDecos;
						if (player.symbol.topContainer.children[0]) {
							this.fishSymbol.topContainer.addChild(player.symbol.topContainer.children[0]);
						}
						if (player.symbol.bottomContainer.children[0]) {
							this.fishSymbol.bottomContainer.addChild(player.symbol.bottomContainer.children[0]);
						}
						if (player.data?.playerType === PlayerType.Nemesis) {
							this.fishSymbol.brightness = 0.5;
							if (!data.s.d) {
								if (!this.fishSymbol.evilEyes) {
									this.fishSymbol.showEvilEyes();
								}
							}
						}
					}
				} else if (-data.s.ft === StababType.Pumpkin) {
					const pumpkin = this.symbol = Factory.get(Pumpkinbab);
					pumpkin.setZompkin(data.s.d === -1);
					this.tintContainer.addChild(pumpkin);
				} else if (-data.s.ft === StababType.Tail) {
					const skinGroup = data.s.pid;
					const tail = this.symbol = new skinGroups['s' + skinGroup].Tail_Axolotl();
					this.tintContainer.addChild(tail);
					tail.scale.set(2);
				}
				if (data.pr) {
					if (!this.paralyzedEffect) {
						this.paralyzedEffect = Factory.get(ParalyzedEffect);
						this.container.addChild(this.paralyzedEffect);
					}
				}
				if (data.ff > 0) {
					if (!this.burningEffect) {
						this.burningEffect = Factory.get(BurningEffect);
						this.container.addChild(this.burningEffect);
						this.burningEffect.angle = - 90;
					}
				} else if (data.ff < 0) {
					if (!this.frozenEffect) {
						this.frozenEffect = Factory.get(FrozenEffect);
						this.tintContainer.addChild(this.frozenEffect);
					}
				}
			}
			if (!this.data || !this.data.ac) {
				room.playerTopContainer.addChild(this.container);
				if (this.area.parent) {
					this.area.parent.removeChild(this.area);
				}
			}
			this.container.x = data.x;
			this.container.y = data.y;
			this.container.angle = data.d;

		} else {
			if (!this.playedSound) {

				new SoundEfx('crossbow').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
				this.playedSound = true;
			}
			const lineColor = this.isSameTeam ? 0xFFFF00 : 0xFF3399;
			this.area.clear();
			this.area.lineStyle(2, lineColor, 0.5);
			this.area.moveTo(data.x, data.y);
			const end = Rotate.move(Rotate.d2r(data.d), 750);
			this.area.lineTo(data.x + end.x, data.y + end.y);
		}


		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.data) {
			if (this.data.ac) {
				room.playerTopContainer.addChild(this.container);
			} else {
				room.playerBottomContainer.addChild(this.area);
			}
		}
	}
	public hide(room: RoomControl) {
		if (this.container.parent) {
			this.container.parent.removeChild(this.container);
		}
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
	}

	protected removeZompkinEffect() {
		if (this.zompkinEffect) {
			this.tintContainer.removeChild(this.zompkinEffect);
			Factory.pool(ZompkinEffect, this.zompkinEffect);
			this.zompkinEffect = null;
		}
	}
	protected removeParalizedEffect() {
		if (this.paralyzedEffect) {
			this.paralyzedEffect.x = 0;
			this.paralyzedEffect.y = 0;
			this.paralyzedEffect.scale.set(1);
			this.container.removeChild(this.paralyzedEffect);
			Factory.pool(ParalyzedEffect, this.paralyzedEffect);
			this.paralyzedEffect = null;
		}
	}
	protected removeBurningEffect() {
		if (this.burningEffect) {
			this.burningEffect.x = 0;
			this.burningEffect.y = 0;
			this.burningEffect.angle = 0;
			this.burningEffect.scale.set(1);
			this.container.removeChild(this.burningEffect);
			Factory.pool(BurningEffect, this.burningEffect);
			this.burningEffect = null;
		}
	}
	protected removeFrozenEffect() {
		if (this.frozenEffect) {
			this.frozenEffect.x = 0;
			this.frozenEffect.y = 0;
			this.frozenEffect.angle = 0;
			this.frozenEffect.scale.set(1);
			this.tintContainer.removeChild(this.frozenEffect);
			Factory.pool(FrozenEffect, this.frozenEffect);
			this.frozenEffect = null;
		}
	}

}
