import { nonReact } from '@/util/nonReact';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Sprite } from 'pixi.js';
import { getAllTimedChildren } from './helper';
import { ParticleData } from './particles';

const particles: ParticleData[] = nonReact([]);
const particlePools: Sprite[][] = nonReact([]);

export enum MapAsset {
	MainNode,
	MainNodeR,
	MainNodeB,
	MainNodeL,
	MainNodeT,
	TopNode,
	TopNodeR,
	TopNodeL,
	LeftNode,
	LeftNodeB,
	LeftNodeT,
	MainNodeSurface,
	MainNodeRSurface,
	MainNodeLSurface,
	TopNodeSurface,
	TopNodeRSurface,
	TopNodeLSurface,


	MiniMainNode,
	MiniMainNodeR,
	MiniMainNodeB,
	MiniMainNodeL,
	MiniMainNodeT,
	MiniTopNode,
	MiniTopNodeR,
	MiniTopNodeL,
	MiniLeftNode,
	MiniLeftNodeB,
	MiniLeftNodeT,
	MiniMainNodeSurface,
	MiniMainNodeRSurface,
	MiniMainNodeLSurface,
	MiniTopNodeSurface,
	MiniTopNodeRSurface,
	MiniTopNodeLSurface,
	Arrow,
	Target,
	Plus,
	Whirlpool,
	Ouroboros,
	Siren,

	RedOre,
	YellowOre,
	GreenOre,
	BlueOre,
	PurpleOre,
	WhiteOre,
}
export interface WallSprite extends Sprite {
	spriteId: MapAsset,
}
export function registerWallSprites(symbol: MovieClip) {

	const list = particles;
	const pools = particlePools;
	const timeline = (symbol as any)._timelines;
	const children = getAllTimedChildren(symbol);
	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		if (i === 0) { child.x = child.y = 0; }
		const pos = timeline[i]?._currentProps || { x: 0, y: 0 };
		const rect = child.getLocalBounds();
		const u = -(child.x + pos.x || 0) / rect.width;
		const v = -(child.y + pos.y || 0) / rect.height;
		const texture = (child as Sprite).texture;
		list[i] = { u, v, texture };
		pools[i] = [];
	}
	// console.log(symbol);
	symbol.destroy();
}
export function getWallSprite(index: MapAsset, x = 0, y = 0) {

	const pool = particlePools[index];
	let symbol = pool.shift();

	const data = particles[index];
	if (!symbol) {
		symbol = Sprite.from(data.texture);
	}
	symbol.anchor.set(data.u, data.v);
	symbol.x = x;
	symbol.y = y;
	(symbol as WallSprite).spriteId = index;

	return symbol;
}
export function disposeWallSprite(wallSprite: Sprite) {
	particlePools[(wallSprite as WallSprite).spriteId].push(wallSprite);
}
