














































import gamex, { GameUtility } from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UtilityBarButton from './UtilityBarButton.vue';
import { GameType } from '@/game/infos/roomInfos';

@Component({
  components: {
    UtilityBarButton,
  },
})
export default class UtilityBarComponent extends Vue {
  public get isArmageddon() {
    return gamex.gameType === GameType.Armageddon;
  }

  public currentUtility: GameUtility = 'None';
  public onClick(utility: GameUtility) {
    if (
      gamex.gameType !== GameType.Armageddon &&
      (utility === 'Map' ||
        utility === 'Server' ||
        utility === 'Rank' ||
        utility === 'Bag')
    ) {
      return;
    }
    if (utility === 'Settings') {
      this.showSettings();
      return;
    }
    if (this.currentUtility === utility) {
      this.currentUtility = 'None';
    } else {
      this.currentUtility = utility;
    }
    gamex.updateCurrenUtility(this.currentUtility);
  }

  public showSettings() {
    this.$root.$emit('settings');
  }
  public get gameUtilities(): GameUtility[] {
    return this.isArmageddon
      ? ['Map', 'Tips', 'Rank', 'Bag', 'Emoji', 'Settings']
      : ['Tips', 'Emoji', 'Settings'];
  }

  public onKeyUp(e: KeyboardEvent) {
    if (!gamex.toShowChat) {
      switch (e.code) {
        case 'KeyQ':
          this.onClick(this.gameUtilities[0]);
          break;
        case 'KeyW':
          this.onClick(this.gameUtilities[1]);
          break;
        case 'KeyE':
          this.onClick(this.gameUtilities[2]);
          break;
        case 'KeyR':
          this.onClick(this.gameUtilities[3]);
          break;
        case 'KeyT':
          this.onClick(this.gameUtilities[4]);
          break;
        case 'KeyY':
          this.onClick(this.gameUtilities[5]);
          break;
      }
    }
  }

  public mounted() {
    window.addEventListener('keyup', this.onKeyUp);
    this.$el.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
  public beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp);
    this.$el.removeEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
}
