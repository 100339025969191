import { ItemCode } from '@/game/infos/itemInfos';
import { isQuestTime, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';
import { Container, Sprite } from 'pixi.js';
import { getItemSprite } from '../itemParticles';
import { PlayerMode } from '@/game/infos/enums';
import { Ease, Tween } from '@/util/tweents';
import { Rotate } from '@/util/rotate';
import store from '@/store';
import { StabfishAccountInventory } from '@/game/infos/firestoreFiles';

export class SafeZoneSymbol extends Container {
	// public polarBubble!: Container;
	public santaHat!: Container;
	public cny!: Container;
	public halloween!: Container;
	public goldenIdol = getItemSprite(ItemCode.GoldenIdol);
	public modeBanner!: ModeBanner;

	public init() {
		this.goldenIdol.tint = 0xffff00;
		this.goldenIdol.x = -78;
		this.goldenIdol.y = -38;
		this.goldenIdol.scale.set(.7);
		this.addChildAt(this.goldenIdol, 0);
		this.goldenIdol.visible = false;
		// this.santaHat.visible = isQuestTime(TimeLimitedQuestCode.Xmas2021);
		// this.cny.visible = isQuestTime(TimeLimitedQuestCode.Cny2024);
		// this.halloween.visible = isQuestTime(TimeLimitedQuestCode.Halloween2023);
	}
}


export class ModeBanner extends Container {

	public rewards!: Sprite;

	public init() {
		this.rewards = this.children[1] as Sprite;
		this.rewards.visible = false;
	}
}
