import { Dictionary } from '@/util/dictionary';
import { ItemCode, itemInfos } from './itemInfos';

export const decoLimitCost = [5000, 50000, 500000, 5000000, 10000];

export const decoNames = [
	'Katana',
	'Top_Hat',
	'Mustache',
	'Glasses',
	'UnicornHorn',
	'CuteWing',
	'AngelHalo',
	'AmericanFootball',
	'CapOA',
	'Hoop',
	'WarpaintForehead',
	'WarpaintCheek',
	'WarpaintFace',
	'SpiralGlasses',
	'LongGlasses',
	'ScarOne',
	'ScarX',
	'RibbonPink',
	'RedNose',
	'SquirtingBlood',
	'BrokenBottle',
	'Blush',
	'Monocle',
	'Fez',
	'BumpOnHead',
	'Bungalow',
	'WhiteFlower',
	'Bandaid',
	'BandaidX',
	'MusicNote',
	'Clownfish',
	'BlackAfro',
	'RainbowAfro',
	'WhiteWig',
	'TrapperHat',
	'PropellerHat',
	'NeckRuffle',
	'Hachimaki',
	'BeachBall',
	'Fountain',
	'CoconutTree',
	'SailingShip',
	'FootballHelmet',
	'RainbowRay',
	'Sombrero',
	'SeaUrchin',
	'Ghost',
	'Balloons',
	'ElectricNode',
	'SmallBubble',
	'Diamond',
	'Bling',
	'Moss',
	'BrodieHelm',
	'HydroTank',

	'BikerSunglasses',
	'Saber',
	'SharkSaberBlue',
	'SharkSaberRed',
	'SharkSaberYellow',
	'SharkSaberPurple',
	'Sword',
	'DolphinSwordBlack',
	'DolphinSwordPurple',
	'DolphinSwordTeal',
	'DolphinSwordGreen',

	'Shield',
	'SantaHat',
	'CandyStick',
	'ReindeerHeadband',
	'StarLantern',
	'SantaBeard',

	'AviatorSunglasses',
	'BaseballBat',
	'Battery',
	'Quill',
	'HaloRing',
	'CloudHalo',

	'MedalSss',
	'MedalSs',
	'MedalS',
	'MedalA',
	'MedalB',

	'Kill5Crown',
	'Kill4Collar',
	'Kill3Belt',
	'Kill2Pendant',
	'Kill1Ring',

	'Score5Crown',
	'Score4Collar',
	'Score3Belt',
	'Score2Pendant',
	'Score1Ring',

	'Tuna',
	'SurgicalMask',
	'StageLight',
	'PaperPlane',
	'LargeBubble',
	'JugglingClub',
	'Ink',
	'GhostPepperRed',
	'GhostPepperGreen',
	'FlowerBouquet',
	'Eyepatch',
	'DizzyHalo',
	'Baseball',
	'BalloonGreen',
	'BalloonYellow',
	'BalloonRed',
	'Potato',
	'Apple',
	'ChefKnife',
	'RabbitEarsHeadband',
	'RedPacket',
	'BeardOfFortune',
	'HatOfFortune',

	'Card',
	'JokerCard',
	'Maraca',
	'Book',
	'Wheel',

	'JackOLantern',
	'JackOLanternJaw',

	'Wristband',
	'UmbrellaRed',
	'IncognitoMask',

	'PirateBandana',
	'PirateCaptainBicorne',

	'DragonHorns',

	'SkewerStick',
	'Crayon',
	'DoodlesShark',
	'DoodlesOctopus',
	'DoodlesFish',
	'DoodlesJellyfish',
	'CaptainCap',
	'OperaGlasses',
	'Staff',
	'MasqueradeMask',
	'KindergartenHat',
	'String',
	'Handcuffs',
	'Box',
	'Scissors',
	'Speaker',
	'Ukulele',
	'Headphone',

	'SpiritOfStabfish',
	'HopeOfStabfish',

	'PlasticBag',
	'BaseballGlove',
	'Pencil',
	'Egg',
	'Toast',
	'PotatoChips',
	'Schoolbag',
	'Clock',
	'WheatStraw',
	'Bone',
	'Skull',
	'FishSkeleton',
	'Tattoo',
	'MenacingEffect',
	'SpearGun',
	'BunnyToy',
	'TeddyBear',
	'CarWheel',
	'PocketWatch',
	'Watch',
	'Smartphone',
	'Skateboard',

] as const;
export type DecoName = typeof decoNames[number];


export const decoBasicInfos: { [T in DecoName]: BasicInfo } = {
	Katana: { name: 'Katana Ichimonji' },
	Top_Hat: {},
	Mustache: { type: 'mouthpiece' },
	Glasses: { type: 'eyewear' },
	UnicornHorn: { name: 'Unicorn Horn' },
	CuteWing: { name: 'Cute Wing' },
	AngelHalo: { name: 'Angel Halo' },
	AmericanFootball: { name: 'American Football' },
	CapOA: { name: 'Blue Cap' },
	Hoop: { name: 'Hula Hoop' },
	WarpaintForehead: { name: 'Warpaint 1' },
	WarpaintCheek: { name: 'Warpaint 2' },
	WarpaintFace: { name: 'Warpaint 4' },
	SpiralGlasses: { name: 'Spiral Glasses', type: 'eyewear' },
	LongGlasses: { name: 'Long Glasses', type: 'eyewear' },
	ScarOne: { name: 'Scar 1' },
	ScarX: { name: 'Scar X' },
	RibbonPink: { name: 'Ribbon Pink' },
	RedNose: { name: 'Red Nose', type: 'mouthpiece' },
	SquirtingBlood: { name: 'Squirting Blood' },
	BrokenBottle: { name: 'Broken Bottle' },
	Blush: { name: 'Blush' },
	Monocle: { type: 'eyewear' },
	Fez: { name: 'Fez' },
	BumpOnHead: { name: 'Bump On Head' },
	Bungalow: { name: 'Bungalow' },
	WhiteFlower: { name: 'White Flower' },
	Bandaid: { name: 'Bandaid' },
	BandaidX: { name: 'Bandaid X' },
	MusicNote: { name: 'Music Note' },
	Clownfish: { name: 'Clownfish' },
	BlackAfro: { name: 'Black Afro' },
	RainbowAfro: { name: 'Rainbow Afro' },
	WhiteWig: { name: 'White Wig' },
	TrapperHat: { name: 'Trapper Hat' },
	PropellerHat: { name: 'Propeller Hat' },
	NeckRuffle: { name: 'Neck Ruffle' },
	Hachimaki: { name: 'Hachimaki' },
	BeachBall: { name: 'Beach Ball' },
	Fountain: { name: 'Fountain' },
	CoconutTree: { name: 'Coconut Tree' },
	SailingShip: { name: 'Sailing Ship' },
	FootballHelmet: { name: 'Football Helmet' },
	RainbowRay: { name: 'Rainbow Ray' },
	Sombrero: { name: 'Sombrero' },
	SeaUrchin: { name: 'Sea Urchin' },
	Ghost: { name: 'Ghost' },
	Balloons: { name: 'Balloons' },
	ElectricNode: { name: 'Electric Node' },
	SmallBubble: { name: 'SmallBubble' },
	Diamond: { name: 'Diamond' },
	Bling: { name: 'Bling' },
	Moss: { name: 'Moss' },
	BrodieHelm: { name: 'BrodieHelm' },
	HydroTank: { name: 'HydroTank' },

	BikerSunglasses: { type: 'eyewear' },
	Saber: {},
	SharkSaberBlue: {},
	SharkSaberRed: {},
	SharkSaberYellow: {},
	SharkSaberPurple: {},

	Sword: {},
	DolphinSwordBlack: {},
	DolphinSwordPurple: {},
	DolphinSwordTeal: {},
	DolphinSwordGreen: {},

	Shield: {},
	SantaHat: {},
	CandyStick: {},
	ReindeerHeadband: {},
	StarLantern: {},
	SantaBeard: { type: 'mouthpiece' },

	AviatorSunglasses: { type: 'eyewear' },
	BaseballBat: {},
	Battery: {},
	Quill: {},
	HaloRing: {},
	CloudHalo: {},

	MedalSss: {},
	MedalSs: {},
	MedalS: {},
	MedalA: {},
	MedalB: {},

	Kill5Crown: {},
	Kill4Collar: {},
	Kill3Belt: {},
	Kill2Pendant: {},
	Kill1Ring: {},

	Score5Crown: {},
	Score4Collar: {},
	Score3Belt: {},
	Score2Pendant: {},
	Score1Ring: {},

	Tuna: {},
	SurgicalMask: { type: 'mouthpiece' },
	StageLight: {},
	PaperPlane: {},
	LargeBubble: {},
	JugglingClub: {},
	Ink: {},
	GhostPepperRed: {},
	GhostPepperGreen: {},
	FlowerBouquet: {},
	Eyepatch: { type: 'eyewear' },
	DizzyHalo: {},
	Baseball: {},
	BalloonGreen: {},
	BalloonYellow: {},
	BalloonRed: {},
	Potato: {},
	Apple: {},
	ChefKnife: {},
	RabbitEarsHeadband: {},
	RedPacket: {},
	BeardOfFortune: { type: 'mouthpiece' },
	HatOfFortune: {},
	Card: {},
	JokerCard: {},
	Book: {},
	Wheel: {},
	Maraca: {},

	JackOLantern: { type: 'eyewear' },
	JackOLanternJaw: {},

	Wristband: {},
	UmbrellaRed: {},
	IncognitoMask: { type: 'eyewear' },
	PirateBandana: {},
	PirateCaptainBicorne: {},

	DragonHorns: {},

	SkewerStick: {},
	Crayon: {},
	DoodlesShark: {},
	DoodlesOctopus: {},
	DoodlesFish: {},
	DoodlesJellyfish: {},
	CaptainCap: {},
	OperaGlasses: { type: 'eyewear' },
	Staff: {},
	MasqueradeMask: { type: 'eyewear' },

	KindergartenHat: {},
	String: {},
	Handcuffs: {},
	Box: {},
	Scissors: {},
	Speaker: {},
	Ukulele: {},
	Headphone: {},

	SpiritOfStabfish: {},
	HopeOfStabfish: {},


	PlasticBag: {},
	BaseballGlove: {},
	Pencil: {},
	Egg: {},
	Toast: { type: 'mouthpiece' },
	PotatoChips: {},
	Schoolbag: {},
	Clock: {},
	WheatStraw: { type: 'mouthpiece' },
	Bone: {},
	Skull: { type: 'eyewear' },
	FishSkeleton: {},
	Tattoo: {},
	MenacingEffect: {},
	SpearGun: {},
	BunnyToy: {},
	TeddyBear: {},
	CarWheel: {},
	PocketWatch: {},
	Watch: {},
	Smartphone: {},
	Skateboard: {},

};

export const decoDic: DecoName[] = [];
decoDic[ItemCode.BumpOnHead] = 'BumpOnHead';
decoDic[ItemCode.Bungalow] = 'Bungalow';
decoDic[ItemCode.Bandaid] = 'Bandaid';
decoDic[ItemCode.BandaidX] = 'BandaidX';
decoDic[ItemCode.Glasses] = 'Glasses';
decoDic[ItemCode.SpiralGlasses] = 'SpiralGlasses';
decoDic[ItemCode.GentlemanMustache] = 'Mustache';
decoDic[ItemCode.Scar1] = 'ScarOne';
decoDic[ItemCode.ScarX] = 'ScarX';
decoDic[ItemCode.Warpaint1] = 'WarpaintForehead';
decoDic[ItemCode.Warpaint2] = 'WarpaintCheek';
decoDic[ItemCode.Warpaint4] = 'WarpaintFace';
decoDic[ItemCode.RedNose] = 'RedNose';
decoDic[ItemCode.Monocle] = 'Monocle';
decoDic[ItemCode.BikerSunglasses] = 'BikerSunglasses';
decoDic[ItemCode.Saber] = 'Saber';
decoDic[ItemCode.SharkSaberBlue] = 'SharkSaberBlue';
decoDic[ItemCode.SharkSaberRed] = 'SharkSaberRed';
decoDic[ItemCode.SharkSaberYellow] = 'SharkSaberYellow';
decoDic[ItemCode.SharkSaberPurple] = 'SharkSaberPurple';
decoDic[ItemCode.BeachBall] = 'BeachBall';
decoDic[ItemCode.Hoop] = 'Hoop';
decoDic[ItemCode.AmericanFootball] = 'AmericanFootball';
decoDic[ItemCode.Sword] = 'Sword';
decoDic[ItemCode.DolphinSwordBlack] = 'DolphinSwordBlack';
decoDic[ItemCode.DolphinSwordPurple] = 'DolphinSwordPurple';
decoDic[ItemCode.DolphinSwordTeal] = 'DolphinSwordTeal';
decoDic[ItemCode.DolphinSwordGreen] = 'DolphinSwordGreen';
decoDic[ItemCode.BlueCap] = 'CapOA';
decoDic[ItemCode.Shield] = 'Shield';
decoDic[ItemCode.SantaHat] = 'SantaHat';
decoDic[ItemCode.CandyStick] = 'CandyStick';
decoDic[ItemCode.ReindeerHeadband] = 'ReindeerHeadband';
decoDic[ItemCode.StarLantern] = 'StarLantern';
decoDic[ItemCode.Moss] = 'Moss';
decoDic[ItemCode.Fountain] = 'Fountain';
decoDic[ItemCode.UnicornHorn] = 'UnicornHorn';
decoDic[ItemCode.SeaUrchin] = 'SeaUrchin';
decoDic[ItemCode.CoconutTree] = 'CoconutTree';
decoDic[ItemCode.PropellerHat] = 'PropellerHat';

decoDic[ItemCode.WhiteWig] = 'WhiteWig';
decoDic[ItemCode.Quill] = 'Quill';
decoDic[ItemCode.Hachimaki] = 'Hachimaki';
decoDic[ItemCode.MiniBubble] = 'SmallBubble';
decoDic[ItemCode.Ghost] = 'Ghost';
decoDic[ItemCode.MusicNote] = 'MusicNote';
decoDic[ItemCode.Battery] = 'Battery';
decoDic[ItemCode.Bling] = 'Bling';
decoDic[ItemCode.SantaBeard] = 'SantaBeard';
decoDic[ItemCode.RankBMedal] = 'MedalB';
decoDic[ItemCode.RankAMedal] = 'MedalA';
decoDic[ItemCode.RankSMedal] = 'MedalS';
decoDic[ItemCode.RankSSMedal] = 'MedalSs';
decoDic[ItemCode.RankSSSMedal] = 'MedalSss';
decoDic[ItemCode.Score1Ring] = 'Score1Ring';
decoDic[ItemCode.Score2Pendant] = 'Score2Pendant';
decoDic[ItemCode.Score3Belt] = 'Score3Belt';
decoDic[ItemCode.Score4Collar] = 'Score4Collar';
decoDic[ItemCode.Score5Crown] = 'Score5Crown';
decoDic[ItemCode.Kill1Ring] = 'Kill1Ring';
decoDic[ItemCode.Kill2Pendant] = 'Kill2Pendant';
decoDic[ItemCode.Kill3Belt] = 'Kill3Belt';
decoDic[ItemCode.Kill4Collar] = 'Kill4Collar';
decoDic[ItemCode.Kill5Crown] = 'Kill5Crown';

decoDic[ItemCode.WhiteFlower] = 'WhiteFlower';
decoDic[ItemCode.Tuna] = 'Tuna';
decoDic[ItemCode.SurgicalMask] = 'SurgicalMask';
decoDic[ItemCode.StageLight] = 'StageLight';
decoDic[ItemCode.PaperPlane] = 'PaperPlane';
decoDic[ItemCode.LargeBubble] = 'LargeBubble';
decoDic[ItemCode.JugglingClub] = 'JugglingClub';
decoDic[ItemCode.Ink] = 'Ink';
decoDic[ItemCode.GhostPepperRed] = 'GhostPepperRed';
decoDic[ItemCode.GhostPepperGreen] = 'GhostPepperGreen';
decoDic[ItemCode.FlowerBouquet] = 'FlowerBouquet';
decoDic[ItemCode.Eyepatch] = 'Eyepatch';
decoDic[ItemCode.DizzyHalo] = 'DizzyHalo';
decoDic[ItemCode.Baseball] = 'Baseball';
decoDic[ItemCode.BalloonGreen] = 'BalloonGreen';
decoDic[ItemCode.BalloonYellow] = 'BalloonYellow';
decoDic[ItemCode.BalloonRed] = 'BalloonRed';
decoDic[ItemCode.Potato] = 'Potato';
decoDic[ItemCode.Apple] = 'Apple';
decoDic[ItemCode.BlackAfro] = 'BlackAfro';
decoDic[ItemCode.TrapperHat] = 'TrapperHat';
decoDic[ItemCode.RabbitEarsHeadband] = 'RabbitEarsHeadband';
decoDic[ItemCode.AviatorSunglasses] = 'AviatorSunglasses';
decoDic[ItemCode.ChefKnife] = 'ChefKnife';
decoDic[ItemCode.RedPacket] = 'RedPacket';
decoDic[ItemCode.BeardOfFortune] = 'BeardOfFortune';
decoDic[ItemCode.HatOfFortune] = 'HatOfFortune';
decoDic[ItemCode.Blush] = 'Blush';
decoDic[ItemCode.LongGlasses] = 'LongGlasses';
decoDic[ItemCode.RainbowAfro] = 'RainbowAfro';
decoDic[ItemCode.Balloons] = 'Balloons';
decoDic[ItemCode.Card] = 'Card';
decoDic[ItemCode.JokerCard] = 'JokerCard';
decoDic[ItemCode.Maraca] = 'Maraca';
decoDic[ItemCode.Book] = 'Book';
decoDic[ItemCode.Wheel] = 'Wheel';
decoDic[ItemCode.JackOLantern] = 'JackOLantern';
decoDic[ItemCode.JackOLanternJaw] = 'JackOLanternJaw';
decoDic[ItemCode.SquirtingBlood] = 'SquirtingBlood';
decoDic[ItemCode.NeckRuffle] = 'NeckRuffle';
decoDic[ItemCode.Wristband] = 'Wristband';
decoDic[ItemCode.UmbrellaRed] = 'UmbrellaRed';
decoDic[ItemCode.IncognitoMask] = 'IncognitoMask';
decoDic[ItemCode.PirateBandana] = 'PirateBandana';
decoDic[ItemCode.PirateCaptainBicorne] = 'PirateCaptainBicorne';
decoDic[ItemCode.DragonHorns] = 'DragonHorns';
decoDic[ItemCode.SkewerStick] = 'SkewerStick';
decoDic[ItemCode.Crayon] = 'Crayon';
decoDic[ItemCode.DoodlesShark] = 'DoodlesShark';
decoDic[ItemCode.DoodlesOctopus] = 'DoodlesOctopus';
decoDic[ItemCode.DoodlesFish] = 'DoodlesFish';
decoDic[ItemCode.BaseballBat] = 'BaseballBat';
decoDic[ItemCode.CaptainCap] = 'CaptainCap';
decoDic[ItemCode.SailingShip] = 'SailingShip';
decoDic[ItemCode.OperaGlasses] = 'OperaGlasses';
decoDic[ItemCode.Staff] = 'Staff';
decoDic[ItemCode.Katana] = 'Katana';
decoDic[ItemCode.MasqueradeMask] = 'MasqueradeMask';

decoDic[ItemCode.KindergartenHat] = 'KindergartenHat';
decoDic[ItemCode.String] = 'String';
decoDic[ItemCode.Handcuffs] = 'Handcuffs';
decoDic[ItemCode.BrodieHelm] = 'BrodieHelm';
decoDic[ItemCode.FootballHelmet] = 'FootballHelmet';
decoDic[ItemCode.TopHat] = 'Top_Hat';
decoDic[ItemCode.Box] = 'Box';
decoDic[ItemCode.Scissors] = 'Scissors';
decoDic[ItemCode.Speaker] = 'Speaker';
decoDic[ItemCode.Headphone] = 'Headphone';
decoDic[ItemCode.Ukulele] = 'Ukulele';
decoDic[ItemCode.SpiritOfStabfish] = 'SpiritOfStabfish';
decoDic[ItemCode.HopeOfStabfish] = 'HopeOfStabfish';

decoDic[ItemCode.PlasticBag] = 'PlasticBag';
decoDic[ItemCode.BaseballGlove] = 'BaseballGlove';
decoDic[ItemCode.Pencil] = 'Pencil';
decoDic[ItemCode.Egg] = 'Egg';
decoDic[ItemCode.Toast] = 'Toast';
decoDic[ItemCode.PotatoChips] = 'PotatoChips';
decoDic[ItemCode.Schoolbag] = 'Schoolbag';
decoDic[ItemCode.Clock] = 'Clock';
decoDic[ItemCode.HydroTank] = 'HydroTank';
decoDic[ItemCode.WheatStraw] = 'WheatStraw';
decoDic[ItemCode.Sombrero] = 'Sombrero';
decoDic[ItemCode.ClownfishDoll] = 'Clownfish';
decoDic[ItemCode.Bone] = 'Bone';
decoDic[ItemCode.Skull] = 'Skull';
decoDic[ItemCode.FishSkeleton] = 'FishSkeleton';
decoDic[ItemCode.Tattoo] = 'Tattoo';
decoDic[ItemCode.MenacingEffect] = 'MenacingEffect';
decoDic[ItemCode.SpearGun] = 'SpearGun';
decoDic[ItemCode.BunnyToy] = 'BunnyToy';
decoDic[ItemCode.TeddyBear] = 'TeddyBear';
decoDic[ItemCode.CarWheel] = 'CarWheel';
decoDic[ItemCode.PocketWatch] = 'PocketWatch';
decoDic[ItemCode.Watch] = 'Watch';
decoDic[ItemCode.Smartphone] = 'Smartphone';
decoDic[ItemCode.Skateboard] = 'Skateboard';


for (const code in decoDic) {
	if (Object.prototype.hasOwnProperty.call(decoDic, code)) {
		const decoName = decoDic[code];
		const itemInfo = itemInfos[Number(code)];
		decoBasicInfos[decoName].name = itemInfo.name;
	}
}

interface BasicInfo {
	name?: string,
	type?: DecoType,
}
export type DecoType = 'gear' | 'mouthpiece' | 'eyewear';

export interface DecoData {
	decoId: string,
	decoName: DecoName,
	x: number,
	y: number,
	angle: number,
	scale: number,
	flipX: boolean,
	flipY: boolean,
	type?: DecoType,
	preferedScale?: number,
}
export interface BodyData {
	skin: number,
}
export interface DecoSet {
	version?: number,
	decos: Dictionary<DecoData>,
	top: string[],
	body: BodyData | null,
	bottom: string[],
}

export function decodeDecoSet(str: string): DecoSet | null {

	if (!str) {
		return null;
	}
	let strPos = 0;
	function readCode() {
		const code = str.charCodeAt(strPos++);
		if (code > 32768) {
			return (code - 65536);
		}
		return code;
	}
	const version = readCode();
	const decoLen = readCode();
	const result: DecoSet = {
		version,
		decos: {},
		body: null,
		top: [],
		bottom: [],
	};
	for (let i = 0; i < decoLen; i++) {
		const itemCode = readCode();
		const x = readCode() / 10;
		const y = readCode() / 10;
		const angle = readCode() / 10;
		const scale = readCode() / 100;
		const flipX = readCode() ? true : false;
		const flipY = readCode() ? true : false;
		const decoName = decoDic[itemCode];
		if (!decoName) {
			continue;
		}
		result.decos[i] = {
			decoId: i.toString(),
			decoName,
			x,
			y,
			angle,
			scale,
			flipX,
			flipY,
		};
	}
	const topLen = readCode();
	for (let i = 0; i < topLen; i++) {
		result.top.push(readCode().toString());
	}
	const bottomLen = readCode();
	for (let i = 0; i < bottomLen; i++) {
		result.bottom.push(readCode().toString());
	}
	return result;
}

export function decodeDecoSetForServer(str: string): Array<{ itemCode: ItemCode, scale: number, x: number, y: number; }> {

	if (!str) {
		return [];
	}
	let strPos = 0;
	function readCode() {
		const code = str.charCodeAt(strPos++);
		if (code > 32768) {
			return (code - 65536);
		}
		return code;
	}
	const version = readCode();
	const decoLen = readCode();
	const result: Array<{ itemCode: ItemCode, scale: number, x: number, y: number; }> = [];
	for (let i = 0; i < decoLen; i++) {
		const itemCode = readCode();
		const x = readCode() / 10;
		const y = readCode() / 10;
		const angle = readCode() / 10;
		const scale = readCode() / 100;
		const flipX = readCode() ? true : false;
		const flipY = readCode() ? true : false;
		result.push({
			itemCode,
			x,
			y,
			scale,
		});
	}
	return result;
}

export function decodeItemCodeFromDecoSet(str: string): ItemCode[] {
	if (!str) {
		return [];
	}
	let strPos = 0;
	function readCode() {
		const code = str.charCodeAt(strPos++);
		if (code > 65534) {
			return 65534 - code;
		}
		return code;
	}
	const version = readCode();
	const decoLen = readCode();
	const result: number[] = [];
	for (let i = 0; i < decoLen; i++) {
		const itemCode = readCode();
		result.push(itemCode);
		strPos += 6;
	}
	return result;
}
export function encodeDecosSet(decoSet: DecoSet | null): string {
	if (!decoSet) { return ''; }
	const arr = [decoSet.version || 0]; // version
	const idDic: Dictionary<number> = {};
	const decos = Object.values(decoSet.decos);
	arr.push(decos.length);
	for (let i = 0; i < decos.length; i++) {
		const deco = decos[i];
		let itemCode = decoDic.indexOf(deco.decoName);
		if (itemCode < 0) { itemCode = 0; }
		arr.push(itemCode);
		arr.push(Math.round(deco.x * 10));
		arr.push(Math.round(deco.y * 10));
		arr.push(Math.round(deco.angle * 10));
		arr.push(Math.round(deco.scale * 100));
		arr.push(deco.flipX ? 1 : 0);
		arr.push(deco.flipY ? 1 : 0);
		idDic[deco.decoId] = i;
	}

	arr.push(decoSet.top.length);
	for (const decoId of decoSet.top) {
		arr.push(idDic[decoId]);
	}

	arr.push(decoSet.bottom.length);
	for (const decoId of decoSet.bottom) {
		arr.push(idDic[decoId]);
	}
	return String.fromCharCode(...arr);
}


export const randomDecos: DecoSet[] = [
	{ decos: { PMrOElvdHzaHTjVVLczn: { decoName: 'Glasses', decoId: 'PMrOElvdHzaHTjVVLczn', x: -0.1, y: -25.1, scale: 0.35, angle: 0, type: 'eyewear', flipX: false, flipY: false }, pTd9TTzPxBCb1t9EUm8r: { decoName: 'BlackAfro', decoId: 'pTd9TTzPxBCb1t9EUm8r', x: 0, y: -37, scale: 0.462, angle: 0, type: 'gear', flipX: false, flipY: false }, ngwjPnZFRDRzX1ZtibNt: { decoName: 'MusicNote', decoId: 'ngwjPnZFRDRzX1ZtibNt', x: -66.59, y: -67.24, scale: 0.29, angle: 180, type: 'gear', flipX: false, flipY: false } }, body: null, top: ['ngwjPnZFRDRzX1ZtibNt', 'PMrOElvdHzaHTjVVLczn'], bottom: ['pTd9TTzPxBCb1t9EUm8r'] },
	{ decos: { 'YDhSwf8TEWqK4At8OBAY': { decoName: 'TrapperHat', decoId: 'YDhSwf8TEWqK4At8OBAY', x: 0, y: -54.2, scale: 0.393, angle: 0, type: 'gear', flipX: false, flipY: false }, '9NQMhv64KbmkbTZBJuO4': { decoName: 'BrokenBottle', decoId: '9NQMhv64KbmkbTZBJuO4', x: 35.69000244140625, y: -14.789990234375026, scale: 0.26, angle: 29.851101126315893, type: 'gear', flipX: false, flipY: false }, 'Ruy4a3vsgK82Ng6cY4ho': { decoName: 'BrokenBottle', decoId: 'Ruy4a3vsgK82Ng6cY4ho', x: -19.670007324218744, y: 14.00998535156252, scale: 0.26, angle: 29.851101126315893, type: 'gear', flipX: false, flipY: false }, 'HpKv1Rqj6xkcE5ESooPb': { decoName: 'HydroTank', decoId: 'HpKv1Rqj6xkcE5ESooPb', x: -39.71485976061066, y: -4.75998535156252, scale: 0.335, angle: -44.97718691776962, type: 'gear', flipX: false, flipY: false }, 'DWi6FE3p6hnSDyK5VnAs': { decoName: 'HydroTank', decoId: 'DWi6FE3p6hnSDyK5VnAs', x: 57.46632678339798, y: 9.320043945312548, scale: 0.22, angle: 6, type: 'gear', flipX: false, flipY: false } }, body: null, top: ['DWi6FE3p6hnSDyK5VnAs', 'Ruy4a3vsgK82Ng6cY4ho', '9NQMhv64KbmkbTZBJuO4', 'YDhSwf8TEWqK4At8OBAY'], bottom: ['HpKv1Rqj6xkcE5ESooPb'] },
	{ decos: { 'dE2vbrOryicywC1GvyE3': { decoName: 'WarpaintForehead', decoId: 'dE2vbrOryicywC1GvyE3', x: 0.35, y: -39.45, scale: 0.275, angle: 0, type: 'gear', flipX: false, flipY: false }, 'xuaZlRNnjqWF3QJbpkMy': { decoName: 'WarpaintForehead', decoId: 'xuaZlRNnjqWF3QJbpkMy', x: -23.00999755859376, y: 2.149999999999963, scale: 0.275, angle: -143, type: 'gear', flipX: false, flipY: false }, '1Sv4mNONqGgddOBWzKgl': { decoName: 'WarpaintForehead', decoId: '1Sv4mNONqGgddOBWzKgl', x: 19.869995117187536, y: 4.7099853515625085, scale: 0.275, angle: 157, type: 'gear', flipX: true, flipY: false }, 'rKkBHb6zLNEa9zIZNFhM': { decoName: 'RainbowRay', decoId: 'rKkBHb6zLNEa9zIZNFhM', x: 0, y: -6.8, scale: 0.5, angle: 0, type: 'gear', flipX: false, flipY: false }, '9evyVSzSqzBmR7FHP4Ku': { decoName: 'AmericanFootball', decoId: '9evyVSzSqzBmR7FHP4Ku', x: 0.35, y: -59.45, scale: 0.275, angle: 0, type: 'gear', flipX: false, flipY: false } }, body: null, top: ['1Sv4mNONqGgddOBWzKgl', 'xuaZlRNnjqWF3QJbpkMy', 'dE2vbrOryicywC1GvyE3'], bottom: ['rKkBHb6zLNEa9zIZNFhM', '9evyVSzSqzBmR7FHP4Ku'] },
	{ decos: { d4zeQk8BFGQTs8g5dhme: { decoName: 'BumpOnHead', decoId: 'd4zeQk8BFGQTs8g5dhme', x: -29.15, y: -47.4, scale: 0.268, angle: -30.02298846485514, type: 'gear', flipX: false, flipY: false }, KC3v1mEGcax83k6eHMjG: { decoName: 'Bungalow', decoId: 'KC3v1mEGcax83k6eHMjG', x: 28.779998779296868, y: -49, scale: 0.202, angle: -30.02298846485514, type: 'gear', flipX: false, flipY: false }, TU75ltj6OsKDVzfl6tPO: { decoName: 'Bungalow', decoId: 'TU75ltj6OsKDVzfl6tPO', x: -51.859997558593754, y: -14.439990234374989, scale: 0.202, angle: -30.02298846485514, type: 'gear', flipX: false, flipY: false }, HRTxPgLmhbHsQe1cftdV: { decoName: 'BumpOnHead', decoId: 'HRTxPgLmhbHsQe1cftdV', x: 27.809991455078126, y: 20.760009765625036, scale: 0.268, angle: -30.02298846485514, type: 'gear', flipX: false, flipY: false } }, body: null, top: ['HRTxPgLmhbHsQe1cftdV', 'd4zeQk8BFGQTs8g5dhme'], bottom: ['KC3v1mEGcax83k6eHMjG', 'TU75ltj6OsKDVzfl6tPO'] },
	{ decos: { U7hq7hGSCsid18tZt3R8: { decoName: 'SquirtingBlood', decoId: 'U7hq7hGSCsid18tZt3R8', x: 0, y: -63.65, scale: 0.162, angle: 0, type: 'gear', flipX: false, flipY: false }, veMiPZlSDawP8RyeuVcD: { decoName: 'Bandaid', decoId: 'veMiPZlSDawP8RyeuVcD', x: -15.699999999999996, y: 8.8599853515625, scale: 0.184, angle: 0, type: 'gear', flipX: false, flipY: false }, Jzd1u24Dlwfa66qM9CdN: { decoName: 'Bandaid', decoId: 'Jzd1u24Dlwfa66qM9CdN', x: 46.379992675781274, y: 15.259985351562477, scale: 0.184, angle: 0, type: 'gear', flipX: false, flipY: false }, hD804bi0VZMvaPgUVd0t: { decoName: 'ScarX', decoId: 'hD804bi0VZMvaPgUVd0t', x: -1.2400024414062445, y: 23.49999999999998, scale: 0.25, angle: 9.453803619658583, type: 'gear', flipX: false, flipY: false }, pee7PhLBfBCuojkciawa: { decoName: 'SquirtingBlood', decoId: 'pee7PhLBfBCuojkciawa', x: -32.63999633789062, y: -59.80998535156251, scale: 0.162, angle: 0, type: 'gear', flipX: false, flipY: false } }, body: null, top: ['Jzd1u24Dlwfa66qM9CdN', 'veMiPZlSDawP8RyeuVcD', 'hD804bi0VZMvaPgUVd0t'], bottom: ['U7hq7hGSCsid18tZt3R8', 'pee7PhLBfBCuojkciawa'] },
	{ decos: { 'W8NNPKAFGdoxHTvgupJo': { decoName: 'SpiralGlasses', decoId: 'W8NNPKAFGdoxHTvgupJo', x: -0.1, y: -33.15, scale: 0.37651133333333336, angle: 0, type: 'eyewear', flipX: false, flipY: false }, 'sIRrOPcOHCYz56nYdED5': { decoName: 'Clownfish', decoId: 'sIRrOPcOHCYz56nYdED5', x: 43.14000244140625, y: 18.299999999999976, scale: 0.226, angle: 0, type: 'gear', flipX: false, flipY: false }, '2cpdaJI2n0y8VYt5eByo': { decoName: 'PropellerHat', decoId: '2cpdaJI2n0y8VYt5eByo', x: -16.340002441406252, y: -50.4300048828125, scale: 0.242, angle: -26.98631215066177, type: 'gear', flipX: false, flipY: false }, '7qOJqx0VR3jsZJtvXo4E': { decoName: 'Ghost', decoId: '7qOJqx0VR3jsZJtvXo4E', x: -46.35, y: -51.15, scale: 0.184, angle: 0, type: 'gear', flipX: false, flipY: false } }, body: null, top: ['sIRrOPcOHCYz56nYdED5', 'W8NNPKAFGdoxHTvgupJo'], bottom: ['2cpdaJI2n0y8VYt5eByo', '7qOJqx0VR3jsZJtvXo4E'] },
];
