











































































































































import StateButton from '@/components/inputs/StateButton.vue';
import {
  getChallengeProgress,
  getQuestProgress,
} from '@/game/infos/firestoreFiles';
import { FishType } from '@/game/infos/fishInfos';
import { itemInfos } from '@/game/infos/itemInfos';
import {
  Quest,
  QuestProgress,
  QuestType,
  UnlockChallengeProgress,
  UnlockChallengeType,
  UnlockSteps,
} from '@/game/infos/unlockModels';
import { Global } from '@/store/globalz';
import { EditState } from '@/store/models.def';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { floorToFixed } from '@/util/number';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ItemIntelData } from '../../bag/ItemIntelTooltip.vue';
import ItemButton from '../../tools/inventory/ItemButton.vue';
import FishChallengeComponent from './FishChallengeComponent.vue';
import QuestChallengeComponent from './QuestChallengeComponent.vue';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { BvModal } from 'bootstrap-vue';
import { MainQuestCode, mainQuests } from '@/game/infos/questMain';
import { WeeklyQuestCode, weeklyQuests } from '@/game/infos/questWeekly';
@Component({
  components: {
    QuestChallengeComponent,
    ItemButton,
    StateButton,
  },
})
export default class QuestComponent extends Vue {
  public get buttonType() {
    if (this.completed) {
      return 'completed';
    }
    if (
      this.quest === mainQuests[MainQuestCode.LinkPoq] &&
      !this.progress.progresses[0]
    ) {
      return 'linkPoq';
    }
    if (
      this.quest === mainQuests[MainQuestCode.EmailVerify] &&
      this.progress.currentStep === 1 &&
      !this.progress.progresses[0]
    ) {
      return 'verifyEmail';
    }
    if (
      this.quest === mainQuests[MainQuestCode.EmailVerify] &&
      this.progress.currentStep === 0 &&
      !this.progress.progresses[0]
    ) {
      return 'linkEmail';
    }
    if (
      this.quest === weeklyQuests[WeeklyQuestCode.Purchase] &&
      !this.progress.progresses[0]
    ) {
      return 'purchase';
    }
    if (
      this.quest === weeklyQuests[WeeklyQuestCode.Gacha] &&
      !this.progress.progresses[0]
    ) {
      return 'summon';
    }
    return 'claim';
  }
  public get rewardsTooltip() {
    let str = `
					<div class='text-left'>
					<div class='fwb text-sm text-white'>Rewards:</div>
				`;
    for (const reward of this.step.rewards) {
      const itemCode = reward.itemCode;
      const info = itemInfos[itemCode];
      const color = Global.rarityColors[info.rarity];
      str += `
					<div class='text-left'>
					<div class='text-sm'><span style="color:${color}">${info.name}</span> x${reward.amount}</div>
					 </div> `;
    }
    return str;
  }

  public get initials() {
    return this.quest.type === QuestType.Main
      ? 'QM'
      : this.quest.type === QuestType.Weekly
      ? 'QW'
      : 'QT';
  }
  public get progress() {
    const progresses =
      this.quest.type === QuestType.Main
        ? userx.userDoc.mainQuestProgress
        : this.quest.type === QuestType.Weekly
        ? userx.userDoc.weeklyQuestProgress
        : userx.userDoc.timeLimitedQuestProgress;
    return getQuestProgress(progresses, this.quest.code);
  }
  public get displayStep() {
    const numSteps = this.numSteps;
    const currentStep = this.progress.currentStep;
    return currentStep >= numSteps ? numSteps - 1 : currentStep;
  }
  public get numSteps() {
    return this.quest.steps.length;
  }
  public get step() {
    return this.quest.steps[this.displayStep];
  }
  public get completed() {
    return this.progress.currentStep > this.displayStep;
  }
  public get claimable() {
    if (this.completed) {
      return false;
    }
    for (let i = 0; i < this.step.challenges.length; i++) {
      const challenge = this.step.challenges[i];
      const current =
        challenge.type === UnlockChallengeType.Level
          ? userx.userDoc.lvl
          : this.progress.progresses[i] || 0;

      if (current < challenge.goal) {
        return false;
      }
    }
    return true;
  }
  public get progressPercent() {
    if (this.claimable || this.completed) {
      return 100;
    }
    const numChallenges = this.step.challenges.length;
    let percent = 0;
    for (let i = 0; i < numChallenges; i++) {
      const challenge = this.step.challenges[i];
      if (challenge.type === UnlockChallengeType.UnlockFish) {
        percent += userx.fishUnlocked[challenge.goal] ? 1 / numChallenges : 0;
      } else {
        const current =
          challenge.type === UnlockChallengeType.Level
            ? userx.userDoc.lvl
            : this.progress.progresses[i] || 0;

        percent += Math.min(current / challenge.goal, 1) / numChallenges;
      }
    }
    return floorToFixed(percent * 100);
  }

  public get newBarStyle() {
    return {
      width: `${this.progressPercent}%`,
      borderRadius: this.progressPercent < 98 ? '10px 0px 0px 10px' : '10px',
    };
  }

  public get id() {
    return 'reward-item' + random.v4();
  }
  public get pair(): ItemCodeAmountPair {
    const reward = this.step.rewards[0];
    return reward;
  }
  public get submitLabel() {
    const labels = {
      active: 'Claim',
      sending: '',
      done: 'Claimed',
    };
    return labels[this.submitState];
  }

  public get expired() {
    return this.quest.endTime && this.quest.endTime < this.time;
  }
  public get pending() {
    return this.quest.startTime && this.quest.startTime > this.time;
  }

  public get timeLeft() {
    if (!this.quest.startTime) {
      return '';
    }
    if (this.expired) {
      return 'Event ended.';
    }

    if (this.pending) {
      const closeTime = this.quest.startTime || Date.now();
      let tl = closeTime - this.time;
      if (tl < 0) {
        tl = 0;
      }
      const day = Math.floor(tl / (24 * 60 * 60 * 1000));
      tl -= day * 24 * 60 * 60000;
      const hour = Math.floor(tl / (60 * 60 * 1000));
      tl -= hour * 60 * 60000;
      const min = Math.round(tl / 60000);
      // tl -= min * 60000
      return day > 0
        ? `Begins in ${day}d ${hour}h`
        : `Begins in ${hour}h ${min}m`;
    } else {
      const closeTime = this.quest.endTime || Date.now();
      let tl = closeTime - this.time;
      if (tl < 0) {
        tl = 0;
      }
      const day = Math.floor(tl / (24 * 60 * 60 * 1000));
      tl -= day * 24 * 60 * 60000;
      const hour = Math.floor(tl / (60 * 60 * 1000));
      tl -= hour * 60 * 60000;
      const min = Math.round(tl / 60000);
      // tl -= min * 60000
      return day > 0 ? `${day}d ${hour}h left` : `${hour}h ${min}m left`;
    }
  }
  @Prop() public quest!: Quest;
  @Prop({ default: Date.now() }) public time!: number;
  @Prop({ default: true }) public reading!: boolean;

  public submitState: EditState = 'active';

  public modalShow = false;

  public async onButton() {
    if (this.buttonType === 'linkPoq') {
      Global.poqLinkModal.show();
    } else if (this.buttonType === 'linkEmail') {
      this.$router.push({ name: 'user' });
    } else if (this.buttonType === 'verifyEmail') {
      try {
        await userx.sendEmailVerification();
        this.$bvModal.msgBoxOk(
          'Verification email sent, please check your inbox or junk mail.',
          {
            titleHtml: `<i class="fa fa-check-circle"></i> Success`,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            modalClass: 'funny-modal',
          },
        );
      } catch (error) {
        this.$root.$emit('error', (error as any).message);
      }
    } else if (this.buttonType === 'summon') {
      this.$router.push({ name: 'Summon' });
    } else if (this.buttonType === 'purchase') {
      Global.shopModal.show();
    }
  }

  @Watch('pair', { immediate: true })
  public displayStepChanged(newVal: string) {
    this.submitState = 'active';
  }
  public showItemIntel(i: number) {
    const id = this.id + '-' + i;
    const data: ItemIntelData = {
      targetId: id,
      placement: 'top',
      container: 'quest-component',
      itemCode: this.step.rewards[i].itemCode,
    };
    this.$root.$emit('showItemIntel', data);
  }
  public hideItemIntel() {
    this.$root.$emit('hideItemIntel');
  }
  public onOpenClaim() {
    this.modalShow = true;
  }

  public async onClaim() {
    this.submitState = 'sending';
    try {
      await userx.claimQuest({
        questType: this.quest.type,
        questId: this.quest.code,
      });
      this.submitState = 'done';
      new SoundEfx('buyChaching').play();
      this.modalShow = false;
    } catch (error) {
      this.$root.$emit('error', (error as any).message);
      this.submitState = 'active';
    }
  }
}
