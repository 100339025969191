import { ItemCode } from './itemInfos';
import { QuestType, UnlockChallengeType, UnlockSteps } from './unlockModels';

export const blueprintQuests: UnlockSteps[] = [];


function setSteps(steps: UnlockSteps) {
	const fishType = steps[0][0].unlockId;
	for (let i = 0; i < steps.length; i++) {
		const req = steps[i];
		for (let k = 0; k < req.length; k++) {
			const cha = req[k];
			cha.isMaster = false;
			cha.step = i;
			cha.index = k;
			cha.unlockId = fishType;
		}
	}
	blueprintQuests[fishType] = steps;
}
const isMaster = false;
const index = 0;
let unlockId;
unlockId = ItemCode.BpRankSSSMedal;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Achieve SSS Rank 1 time',
		},
	],
]);
unlockId = ItemCode.BpRankSSMedal;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 3,
			description: 'Achieve SS Rank 3 times',
		},
	],
]);
unlockId = ItemCode.BpRankSMedal;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Achieve S Rank 10 times',
		},
	],
]);
unlockId = ItemCode.BpRankAMedal;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Achieve A Rank 10 times',
		},
	],
]);
unlockId = ItemCode.BpRankBMedal;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Achieve B Rank 10 times',
		},
	],
]);


unlockId = ItemCode.BpScore5Crown;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Score 500,000 or above in ONE game 1 time',
		},
	],
]);
unlockId = ItemCode.BpScore4Collar;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 3,
			description: 'Score 100,000-499,999 in ONE game 3 times',
		},
	],
]);
unlockId = ItemCode.BpScore3Belt;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Score 10,000-99,999 in ONE game 10 times',
		},
	],
]);
unlockId = ItemCode.BpScore2Pendant;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Score 5,000-9,999 in ONE game 10 times',
		},
	],
]);
unlockId = ItemCode.BpScore1Ring;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Score 1,000-4,999 in ONE game 10 times',
		},
	],
]);



unlockId = ItemCode.BpKill5Crown;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 1,
			description: 'Achieve 200 kills or above in ONE game 1 time',
		},
	],
]);
unlockId = ItemCode.BpKill4Collar;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 3,
			description: 'Achieve 100-199 kills in ONE game 3 times',
		},
	],
]);
unlockId = ItemCode.BpKill3Belt;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Achieve 50-99 kills in ONE game 10 times',
		},
	],
]);
unlockId = ItemCode.BpKill2Pendant;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Achieve 10-49 kills in ONE game 10 times',
		},
	],
]);
unlockId = ItemCode.BpKill1Ring;
setSteps([
	[
		{
			unlockId,
			isMaster,
			index,
			step: 0,
			type: UnlockChallengeType.Accumulate,
			goal: 10,
			description: 'Achieve 5-9 kills in ONE game 10 times',
		},
	],
]);

