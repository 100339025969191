





















































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import NewDecoComponent from '../NewDecoComponent.vue';
import NewFishComponent from '../NewFishComponent.vue';
import NewSkinComponent from '../NewSkinComponent.vue';
import ProductButtonComponent from '@/components/shop/ProductButtonComponent.vue';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import { TimeLimitedQuestCode, isQuestTime } from '@/game/infos/questTimeLimited';
import FishSkillIconComponent from '@/components/ui/mainMenu/tools/fishSelector/FishSkillIconComponent.vue';

@Component({
	components: {
		NewDecoComponent,
		NewFishComponent,
		NewSkinComponent,
		ProductButtonComponent,
		FishSkillIconComponent,
	},
})
export default class NewsPost extends Vue {
	// public onMechguin() {
	// 	gamex.setGameStage('BossList');
	// }
	// public get isXmas() {
	// 	return isQuestTime(TimeLimitedQuestCode.Xmas2022);
	// }
	// public get isXmasPenguin() {
	// 	return isQuestTime(TimeLimitedQuestCode.XmasPenguin);
	// }
	public onCnyPurchase() {
		Global.cnyShopModal.show();
	}
}
