import { SpecialSkill, NormalSkill, PassiveSkill } from './skills';

export enum FishType {
	Random,
	BlueShark,
	GreatWhiteShark,
	Megalodon,
	KillerWhale,
	SerialKillerWhale,
	PsychoKillerWhale,
	HammerHead,
	HammeredHead,
	WhammyHead,
	HumpbackWhale,
	BlueWhale,
	Jomama,
	MantaRay,
	MantraRay,
	BlueRay,
	Pufferfish,
	Porcupinefish,
	Onifugu,
	BottlenoseDolphin,
	CommonDolphin,
	SpinnerDolphin,
	Goldfish,
	Goldenfish,
	Diamondfish,
	Narwhal,
	Beluga,
	ArchBeluga,
	DumboOctopus,
	Tako,
	Kraken,
	MorayEel,
	GulperEel,
	ElectricEel,
	GreenSeaTurtle,
	LeatherbackTurtle,
	AlligatorSnappingTurtle,
	Stingray,
	BatRay,
	ElectricRay,
	SakuraShrimp,
	PistolShrimp,
	MantisShrimp,
	FirebellyNewt,
	FireSalamander,
	Axolotl,
	VampireSquid,
	Cuttlefish,
	ColossalSquid,
	Swordfish,
	Marlin,
	Sailfish,
	BlueCrab,
	KingCrab,
	SpiderCrab,
	Crocodile,
	Kronosaurus,
	Mosasaurus,
}

export type FishFamily = 'Random' | 'Shark' | 'Whale' | 'Dolphin' | 'Narwhal' | 'Ray' | 'Pufferfish' | 'Carp' | 'Knifefish' | 'Cephalopod' | 'Eel' | 'Turtle' | 'Crustacean' | 'Mesozoic' | 'Mythical' | 'Amphibian' | 'Swordfish' | 'Reptile';
export type FishColor = 'Random' | 'Green' | 'Blue' | 'Red' | 'Black' | 'Purple' | 'Yellow' | 'White' | 'All';
export type UnlockType = 'skill' | 'item';
export interface UnlockCondition { condition: string, maxCount?: number, }

export interface FishPassiveSkill {
	type: PassiveSkill,
	lvl: number,
}

export interface FishInfo extends Submission {
	type: FishType,
}

interface Submission {
	name: string,
	tier: number,
	desc: string,
	family: FishFamily[],
	color: FishColor[],
	stat: {
		size: number,
		attack: number,
		defense: number,
		health: number,
		speed: number,
		boostSpeed: number,
		torque: number,
		stunPower: number,
		elemRes: number,
		stunRec: number,
	};
	skill1: NormalSkill,
	skill2: SpecialSkill,
	genes: FishPassiveSkill[],
	recessives: FishPassiveSkill[],
	passives: FishPassiveSkill[],
}

export const fishInfos: FishInfo[] = [];

// max size 1.4

function setInfo(type: FishType, submission: Submission) {
	fishInfos[type] = submission as any;
	fishInfos[type].type = type;
}
setInfo(FishType.Random, {
	name: 'Random',
	tier: 0,
	desc: '',
	family: ['Random'],
	color: ['Random'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.None,
	skill2: SpecialSkill.None,
	genes: [],
	recessives: [],
	passives: [],
});
setInfo(FishType.BlueShark, {
	name: 'Blue Shark',
	tier: 1,
	desc: 'Efficient at fighting mobs and earning scores quickly.',
	family: ['Shark'],
	color: ['Blue'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.ScoreBonus, lvl: 1 }, { type: PassiveSkill.AttackUpNemesis, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUpNemesis, lvl: 1 }],
	passives: [],
});
setInfo(FishType.GreatWhiteShark, {
	name: 'Great White Shark',
	tier: 2,
	desc: 'Strong attacks against players and mobs.',
	family: ['Shark'],
	color: ['Blue'],
	stat: {
		size: 1.15,
		attack: 105,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.AttackUp, lvl: 1 }, { type: PassiveSkill.AttackUpNemesis, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Megalodon, {
	name: 'Megalodon',
	tier: 3,
	desc: 'Extremely powerful with a devastating Special Attack.',
	family: ['Shark'],
	color: ['Red'],
	stat: {
		size: 1.3,
		attack: 125,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.MegaBite,
	genes: [],
	recessives: [{ type: PassiveSkill.ScoreBonus, lvl: 1 }],
	passives: [],
});
setInfo(FishType.KillerWhale, {
	name: 'Killer Whale',
	tier: 1,
	desc: 'Gains bonus score from kills; reveals champion location.',
	family: ['Dolphin'],
	color: ['Black'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.KillScoreBonus, lvl: 1 }, { type: PassiveSkill.ChampHunter, lvl: 1 }],
	recessives: [{ type: PassiveSkill.Daredevil, lvl: 1 }],
	passives: [],
});
setInfo(FishType.SerialKillerWhale, {
	name: 'Serial Killer Whale',
	tier: 2,
	desc: 'Specializes in hunting champions with great attacks.',
	family: ['Dolphin'],
	color: ['Black'],
	stat: {
		size: 1.15,
		attack: 110,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.ChampHunter, lvl: 1 }, { type: PassiveSkill.AttackUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.Daredevil, lvl: 1 }],
	passives: [],
});
setInfo(FishType.PsychoKillerWhale, {
	name: 'Psycho Killer Whale',
	tier: 3,
	desc: 'Strong attack with an annoying special skill.',
	family: ['Dolphin'],
	color: ['Purple'],
	stat: {
		size: 1.25,
		attack: 120,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.SwimmingFang,
	genes: [],
	recessives: [{ type: PassiveSkill.Revenger, lvl: 1 }],
	passives: [],
});
setInfo(FishType.HammerHead, {
	name: 'HammerHead',
	tier: 1,
	desc: 'Wide eyes grant superior eyesight.',
	family: ['Shark'],
	color: ['Green'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.ScoreBonus, lvl: 1 }, { type: PassiveSkill.Detective, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.HammeredHead, {
	name: 'Hammered Head',
	tier: 2,
	desc: 'Heals using bandages, sharing recovery with teammates.',
	family: ['Shark'],
	color: ['Yellow'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 28,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Bandage,
	genes: [{ type: PassiveSkill.Teamwork, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.WhammyHead, {
	name: 'Whammy Head',
	tier: 3,
	desc: 'Stuns nearby enemies with a shockwave.',
	family: ['Shark'],
	color: ['Purple'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 30,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.WhammyWave,
	genes: [],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Narwhal, {
	name: 'Narwhal',
	tier: 1,
	desc: 'Original stabfish with a longer weapon and high elemental resistance.',
	family: ['Narwhal'],
	color: ['Blue'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.WpnSizeUp, lvl: 1 }, { type: PassiveSkill.ElemResUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Beluga, {
	name: 'Beluga',
	tier: 2,
	desc: 'Strong attack with high elemental resistance.',
	family: ['Narwhal'],
	color: ['White'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.05,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.AttackUp, lvl: 1 }, { type: PassiveSkill.ElemResUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.ArchBeluga, {
	name: 'ArchBeluga',
	tier: 3,
	desc: 'Wields the Holy Sword, delivering divine judgment.',
	family: ['Narwhal'],
	color: ['White'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.1,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.HolySword,
	genes: [],
	recessives: [{ type: PassiveSkill.OreRarityUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.BottlenoseDolphin, {
	name: 'Bottlenose Dolphin',
	tier: 1,
	desc: 'Fast swimmer, excels at earning points solo or with teammates.',
	family: ['Dolphin'],
	color: ['Blue'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.ScoreBonus, lvl: 1 }, { type: PassiveSkill.Teamwork, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.CommonDolphin, {
	name: 'Common Dolphin',
	tier: 2,
	desc: 'Balanced team player.',
	family: ['Dolphin'],
	color: ['Green'],
	stat: {
		size: 1.1,
		attack: 100,
		defense: 25,
		health: 400,
		speed: 1.03,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.Teamwork, lvl: 1 }, { type: PassiveSkill.AttackUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.SpinnerDolphin, {
	name: 'Spinner Dolphin',
	tier: 3,
	desc: 'Dodges attacks with graceful spins.',
	family: ['Dolphin'],
	color: ['Blue'],
	stat: {
		size: 1.2,
		attack: 100,
		defense: 25,
		health: 500,
		speed: 1.05,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.SpinDodge,
	genes: [],
	recessives: [{ type: PassiveSkill.Assassin, lvl: 1 }],
	passives: [],
});
setInfo(FishType.MantaRay, {
	name: 'Manta Ray',
	tier: 1,
	desc: 'Strong defense against mobs and nemeses.',
	family: ['Ray'],
	color: ['Black'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.Daredevil, lvl: 1 }, { type: PassiveSkill.DefenseUpNemesis, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUpNemesis, lvl: 1 }],
	passives: [],
});
setInfo(FishType.MantraRay, {
	name: 'Mantra Ray',
	tier: 2,
	desc: 'Peaceful vegan activist, creates a temporary neutral zone.',
	family: ['Ray'],
	color: ['Red'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 425,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.TempSafeZone,
	genes: [{ type: PassiveSkill.Vegetarian, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.BlueRay, {
	name: 'Blue Ray',
	tier: 3,
	desc: 'Generates a psychic force field to keep enemies at bay.',
	family: ['Ray'],
	color: ['Blue'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 25,
		health: 550,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.ForceField,
	genes: [],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.DumboOctopus, {
	name: 'Dumbo Octopus',
	tier: 1,
	desc: 'Efficient at tasks with its eight tentacles.',
	family: ['Cephalopod'],
	color: ['Yellow'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.CoolDownTimeDown, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.CoolDownTimeDown, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Tako, {
	name: 'Tako',
	tier: 2,
	desc: 'Temporarily blinds enemies with ink.',
	family: ['Cephalopod'],
	color: ['Red'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 410,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.InkSac,
	genes: [{ type: PassiveSkill.SkillTimeUp, lvl: 1 }, { type: PassiveSkill.EfxTimeUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.CoolDownTimeDown, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Kraken, {
	name: 'Kraken',
	tier: 3,
	desc: 'Mythical beast! Sacrifices victims to gain God-like power.',
	family: ['Mythical'],
	color: ['Green'],
	stat: {
		size: 1.35,
		attack: 100,
		defense: 25,
		health: 520,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Sacrifice,
	genes: [],
	recessives: [{ type: PassiveSkill.Teamwork, lvl: 1 }],
	passives: [],
});
setInfo(FishType.HumpbackWhale, {
	name: 'Humpback Whale',
	tier: 1,
	desc: 'High HP and stamina regeneration.',
	family: ['Whale'],
	color: ['Black'],
	stat: {
		size: 1.1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }, { type: PassiveSkill.StamRegenUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.BlueWhale, {
	name: 'Blue Whale',
	tier: 2,
	desc: 'Massive HP and stamina regeneration.',
	family: ['Whale'],
	color: ['Blue'],
	stat: {
		size: 1.2,
		attack: 100,
		defense: 25,
		health: 450,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }, { type: PassiveSkill.StamRegenUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Jomama, {
	name: 'Jomama',
	tier: 3,
	desc: 'Body slams to rescue children, with great HP.',
	family: ['Whale'],
	color: ['Yellow'],
	stat: {
		size: 1.3,
		attack: 100,
		defense: 25,
		health: 600,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.BodySlam,
	genes: [],
	recessives: [{ type: PassiveSkill.StamRegenUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Pufferfish, {
	name: 'Puffer Fish',
	tier: 1,
	desc: 'Short spikes make it lethal under pressure.',
	family: ['Pufferfish'],
	color: ['Green'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.KillScoreBonus, lvl: 1 }, { type: PassiveSkill.Daredevil, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Porcupinefish, {
	name: 'Porcupine Fish',
	tier: 2,
	desc: 'Thrill-seeker covered in dangerous spikes.',
	family: ['Pufferfish'],
	color: ['Yellow'],
	stat: {
		size: 1.15,
		attack: 110,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.Daredevil, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Onifugu, {
	name: 'Onifugu',
	tier: 3,
	desc: 'Grows larger when angered, covered with lethal spikes.',
	family: ['Pufferfish'],
	color: ['Red'],
	stat: {
		size: 1.2,
		attack: 125,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.SpikeArmor,
	genes: [],
	recessives: [{ type: PassiveSkill.MoneyUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.MorayEel, {
	name: 'Moray Eel',
	tier: 1,
	desc: 'Expert in executing special skills.',
	family: ['Eel'],
	color: ['Green'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.CoolDownTimeDown, lvl: 1 }, { type: PassiveSkill.SkillTimeUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.GulperEel, {
	name: 'Gulper Eel',
	tier: 2,
	desc: 'Uses bioluminescence for detection; punishes mobs and nemeses.',
	family: ['Eel'],
	color: ['Purple'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.05,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.Detective, lvl: 1 }, { type: PassiveSkill.AttackUpNemesis, lvl: 1 }],
	recessives: [{ type: PassiveSkill.AttackUpNemesis, lvl: 1 }],
	passives: [],
});
setInfo(FishType.ElectricEel, {
	name: 'Electric Eel',
	tier: 3,
	desc: 'Shocks surrounding enemies with electric charge.',
	family: ['Knifefish'],
	color: ['Blue'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.1,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.ElectricField,
	genes: [],
	recessives: [{ type: PassiveSkill.SkillTimeUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Goldfish, {
	name: 'Goldfish',
	tier: 1,
	desc: 'Skilled with money.',
	family: ['Carp'],
	color: ['Red'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.MoneyUp, lvl: 1 }, { type: PassiveSkill.DefenseUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Goldenfish, {
	name: 'Goldenfish',
	tier: 2,
	desc: 'Strong defense, adept at finding legendary ores.',
	family: ['Carp'],
	color: ['Yellow'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 30,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.OreRarityUp, lvl: 1 }, { type: PassiveSkill.DefenseUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Diamondfish, {
	name: 'Diamondfish',
	tier: 3,
	desc: 'Conjures a diamond shield to block attacks.',
	family: ['Carp'],
	color: ['White'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 35,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.DiamondHarden,
	genes: [],
	recessives: [{ type: PassiveSkill.ElemResUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.GreenSeaTurtle, {
	name: 'Green Sea Turtle',
	tier: 1,
	desc: 'Vegetarian with strong defense and health.',
	family: ['Turtle'],
	color: ['Green'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.DefenseUp, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.Vegetarian, lvl: 1 }],
	passives: [],
});
setInfo(FishType.LeatherbackTurtle, {
	name: 'Leatherback Turtle',
	tier: 2,
	desc: 'High defense and health, carries a leather backpack.',
	family: ['Turtle'],
	color: ['Green'],
	stat: {
		size: 1.1,
		attack: 100,
		defense: 30,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.DefenseUp, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.UtilityBelt, lvl: 1 }],
	passives: [],
});
setInfo(FishType.AlligatorSnappingTurtle, {
	name: 'Alligator Snapping Turtle',
	tier: 3,
	desc: 'Hides in its tough shell for defense.',
	family: ['Turtle'],
	color: ['Yellow'],
	stat: {
		size: 1.2,
		attack: 100,
		defense: 35,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.ShellHiding,
	skill2: SpecialSkill.Burst,
	genes: [],
	recessives: [{ type: PassiveSkill.DefenseUpNemesis, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Stingray, {
	name: 'Stingray',
	tier: 1,
	desc: 'Tracks down and darts those who harm it.',
	family: ['Ray'],
	color: ['Purple'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost2,
	skill2: SpecialSkill.Dart,
	genes: [{ type: PassiveSkill.MissileDamageUp, lvl: 1 }, { type: PassiveSkill.Revenger, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.BatRay, {
	name: 'Bat Ray',
	tier: 2,
	desc: 'Increases projectile-based special skills.',
	family: ['Ray'],
	color: ['Black'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.05,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost2,
	skill2: SpecialSkill.BatDart,
	genes: [{ type: PassiveSkill.UtilityBelt, lvl: 1 }, { type: PassiveSkill.Revenger, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MissileDamageUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.ElectricRay, {
	name: 'Electric Ray',
	tier: 3,
	desc: 'Shoots electrically charged darts.',
	family: ['Ray'],
	color: ['Yellow'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.1,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost2,
	skill2: SpecialSkill.ElectricDart,
	genes: [],
	recessives: [{ type: PassiveSkill.EfxTimeUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.SakuraShrimp, {
	name: 'Sakura Shrimp',
	tier: 1,
	desc: 'A gemologist with a tiny body.',
	family: ['Crustacean'],
	color: ['Red'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.TinyHero, lvl: 1 }, { type: PassiveSkill.GemRarityUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
// max body size -50%, weapon size -50%, speed +50%
setInfo(FishType.PistolShrimp, {
	name: 'Pistol Shrimp',
	tier: 2,
	desc: 'Fires mid-range explosive bullets.',
	family: ['Crustacean'],
	color: ['Red'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 30,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.FireBullet,
	genes: [{ type: PassiveSkill.EfxTimeUp, lvl: 1 }, { type: PassiveSkill.DefenseUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.EfxTimeUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.MantisShrimp, {
	name: 'Mantis Shrimp',
	tier: 3,
	desc: 'Punches so fast it creates a mini sun.',
	family: ['Crustacean'],
	color: ['All'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 35,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.SunPunch,
	genes: [],
	recessives: [{ type: PassiveSkill.Detective, lvl: 1 }],
	passives: [],
});
setInfo(FishType.FirebellyNewt, {
	name: 'Fire-belly Newt',
	tier: 1,
	desc: 'Regenerates wounds over time.',
	family: ['Amphibian'],
	color: ['Black'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.HpRegenUp, lvl: 1 }, { type: PassiveSkill.EfxTimeUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.AttackUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.FireSalamander, {
	name: 'Fire Salamander',
	tier: 2,
	desc: 'Strong defense against attacks and elements.',
	family: ['Amphibian'],
	color: ['Yellow'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.05,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.ElemResUp, lvl: 1 }, { type: PassiveSkill.DefenseUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.ElemResUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Axolotl, {
	name: 'Axolotl',
	tier: 3,
	desc: 'Escapes impalement by cutting its tail.',
	family: ['Amphibian'],
	color: ['Red'],
	stat: {
		size: 1.2,
		attack: 100,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0.1,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Decoy,
	genes: [],
	recessives: [{ type: PassiveSkill.HpRegenUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.VampireSquid, {
	name: 'Vampire Squid',
	tier: 1,
	desc: 'Drains blood from impaled victims to restore health and stamina.',
	family: ['Cephalopod'],
	color: ['Red'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.Vampire, lvl: 1 }, { type: PassiveSkill.HpRegenUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.StamRegenUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Cuttlefish, {
	name: 'Cuttlefish',
	tier: 2,
	desc: 'Disguises as ironmonite or zellyfish.',
	family: ['Cephalopod'],
	color: ['Yellow'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 425,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Disguise,
	genes: [{ type: PassiveSkill.Assassin, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.ColossalSquid, {
	name: 'Colossal Squid',
	tier: 3,
	desc: 'Creates a weapon from its own tentacle.',
	family: ['Cephalopod'],
	color: ['Purple'],
	stat: {
		size: 1.34,
		attack: 100,
		defense: 25,
		health: 550,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.TentaBola,
	genes: [],
	recessives: [{ type: PassiveSkill.KillScoreBonus, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Swordfish, {
	name: 'Swordfish',
	tier: 1,
	desc: 'High-speed chaser with a strong heart.',
	family: ['Swordfish'],
	color: ['Blue'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.Speedster, lvl: 1 }, { type: PassiveSkill.MaxHpUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.WpnSizeUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Marlin, {
	name: 'Marlin',
	tier: 2,
	desc: 'Fast swimmer with good defense.',
	family: ['Swordfish'],
	color: ['Blue'],
	stat: {
		size: 1.15,
		attack: 100,
		defense: 25,
		health: 400,
		speed: 1.03,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.Speedster, lvl: 1 }, { type: PassiveSkill.DefenseUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.WpnSizeUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Sailfish, {
	name: 'Sailfish',
	tier: 3,
	desc: 'Summons all its weapons and dances with them.',
	family: ['Swordfish'],
	color: ['Blue'],
	stat: {
		size: 1.25,
		attack: 100,
		defense: 25,
		health: 500,
		speed: 1.05,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.SwordDance,
	genes: [],
	recessives: [{ type: PassiveSkill.Speedster, lvl: 1 }],
	passives: [],
});
setInfo(FishType.BlueCrab, {
	name: 'Blue Crab',
	tier: 1,
	desc: 'Strong defense, especially against mobs and nemeses.',
	family: ['Crustacean'],
	color: ['Blue'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.DefenseUpNemesis, lvl: 1 }, { type: PassiveSkill.HpRegenUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.KingCrab, {
	name: 'King Crab',
	tier: 2,
	desc: 'Ultra-strong defense.',
	family: ['Crustacean'],
	color: ['Red'],
	stat: {
		size: 1.1,
		attack: 100,
		defense: 30,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.DefenseUp, lvl: 1 }, { type: PassiveSkill.HpRegenUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.DefenseUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.SpiderCrab, {
	name: 'Spider Crab',
	tier: 3,
	desc: 'Captures and controls enemies with long claws.',
	family: ['Crustacean'],
	color: ['Red'],
	stat: {
		size: 1.2,
		attack: 100,
		defense: 35,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.StrongArms,
	genes: [],
	recessives: [{ type: PassiveSkill.Daredevil, lvl: 1 }],
	passives: [],
});

setInfo(FishType.Crocodile, {
	name: 'Crocodile',
	tier: 1,
	desc: 'Strong attack but with compromised defense.',
	family: ['Reptile'],
	color: ['Green'],
	stat: {
		size: 1,
		attack: 100,
		defense: 25,
		health: 300,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration,
	genes: [{ type: PassiveSkill.Berserker, lvl: 1 }, { type: PassiveSkill.ElemResUp, lvl: 1 }],
	recessives: [{ type: PassiveSkill.MaxHpUp, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Kronosaurus, {
	name: 'Kronosaurus',
	tier: 2,
	desc: 'Built for spamming high attack skills.',
	family: ['Reptile'],
	color: ['Yellow'],
	stat: {
		size: 1.15,
		attack: 105,
		defense: 25,
		health: 400,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.Ration2,
	genes: [{ type: PassiveSkill.Berserker, lvl: 1 }, { type: PassiveSkill.CoolDownTimeDown, lvl: 1 }],
	recessives: [{ type: PassiveSkill.AttackUpNemesis, lvl: 1 }],
	passives: [],
});
setInfo(FishType.Mosasaurus, {
	name: 'Mosasaurus',
	tier: 3,
	desc: 'Strong attack with teeth that can shatter weaponry.',
	family: ['Reptile'],
	color: ['Green'],
	stat: {
		size: 1.3,
		attack: 125,
		defense: 25,
		health: 500,
		speed: 1,
		boostSpeed: 1,
		torque: 1,
		stunRec: 1,
		elemRes: 0,
		stunPower: 1,
	},
	skill1: NormalSkill.Boost,
	skill2: SpecialSkill.MosaBite,
	genes: [],
	recessives: [{ type: PassiveSkill.ChampHunter, lvl: 1 }],
	passives: [],
});

export const availableFishes = [
	fishInfos[FishType.BlueShark],
	fishInfos[FishType.GreatWhiteShark],
	fishInfos[FishType.Megalodon],
	fishInfos[FishType.HammerHead],
	fishInfos[FishType.HammeredHead],
	fishInfos[FishType.WhammyHead],
	fishInfos[FishType.KillerWhale],
	fishInfos[FishType.SerialKillerWhale],
	fishInfos[FishType.PsychoKillerWhale],
	fishInfos[FishType.BottlenoseDolphin],
	fishInfos[FishType.CommonDolphin],
	fishInfos[FishType.SpinnerDolphin],
	fishInfos[FishType.Pufferfish],
	fishInfos[FishType.Porcupinefish],
	fishInfos[FishType.Onifugu],
	fishInfos[FishType.Narwhal],
	fishInfos[FishType.Beluga],
	fishInfos[FishType.ArchBeluga],
	fishInfos[FishType.Goldfish],
	fishInfos[FishType.Goldenfish],
	fishInfos[FishType.Diamondfish],
	fishInfos[FishType.GreenSeaTurtle],
	fishInfos[FishType.LeatherbackTurtle],
	fishInfos[FishType.AlligatorSnappingTurtle],
	fishInfos[FishType.HumpbackWhale],
	fishInfos[FishType.BlueWhale],
	fishInfos[FishType.Jomama],
	fishInfos[FishType.DumboOctopus],
	fishInfos[FishType.Tako],
	fishInfos[FishType.Kraken],
	fishInfos[FishType.MantaRay],
	fishInfos[FishType.MantraRay],
	fishInfos[FishType.BlueRay],
	fishInfos[FishType.MorayEel],
	fishInfos[FishType.GulperEel],
	fishInfos[FishType.ElectricEel],
	fishInfos[FishType.Stingray],
	fishInfos[FishType.BatRay],
	fishInfos[FishType.ElectricRay],
	fishInfos[FishType.SakuraShrimp],
	fishInfos[FishType.PistolShrimp],
	fishInfos[FishType.MantisShrimp],
	fishInfos[FishType.FirebellyNewt],
	fishInfos[FishType.FireSalamander],
	fishInfos[FishType.Axolotl],
	fishInfos[FishType.VampireSquid],
	fishInfos[FishType.Cuttlefish],
	fishInfos[FishType.ColossalSquid],
	fishInfos[FishType.Swordfish],
	fishInfos[FishType.Marlin],
	fishInfos[FishType.Sailfish],
	fishInfos[FishType.BlueCrab],
	fishInfos[FishType.KingCrab],
	fishInfos[FishType.SpiderCrab],
	fishInfos[FishType.Crocodile],
	fishInfos[FishType.Kronosaurus],
	fishInfos[FishType.Mosasaurus],
];

export const availableFishesIncludesRandom = [
	fishInfos[FishType.Random],
	...availableFishes,
];

export const fishTiers = [
	availableFishes.filter((f) => f.tier === 1),
	availableFishes.filter((f) => f.tier === 2),
	availableFishes.filter((f) => f.tier === 3),
];
export const fishTiersForNms = [
	fishTiers[0],
	fishTiers[1],
	fishTiers[2],
];
export const fishTiersAndBelow = [
	availableFishes.filter((f) => f.tier === 1),
	availableFishes.filter((f) => f.tier <= 2),
	availableFishes.filter((f) => f.tier <= 3),
];

export const fishTiersType = [
	fishTiersAndBelow[0].map((f) => f.type),
	fishTiersAndBelow[1].map((f) => f.type),
	fishTiersAndBelow[2].map((f) => f.type),
];

export const cpsFishTiersAndBelow = [
	fishTiersAndBelow[0],
	fishTiersAndBelow[1],
	availableFishes.filter((f) => f.tier === 3 || f.tier === 2),
];
export const cpsFishTiersType = [
	fishTiersType[0],
	fishTiersType[1],
	cpsFishTiersAndBelow[2].map((f) => f.type),
];
