import { Dictionary } from '@/util/dictionary';
import { BroadcastEvent } from '../infos/eventType';
import { SsBandage } from './skills/specialSkills/bandage';
import { ScoreboardData } from './state';
import { BottleData, FoodData, HazardData, MyData, OreData, PartialFoodData, PartialPlayerData, PartialStababData, PlayerData, StababData, TrackerData, UpdateMyData, UpdateState, UserData, ViewState } from './viewState';


const updateProps: Array<keyof UpdateState> = ([
	'ranks',
	'evts',
	'ps',
	'fds',
	'mis',
	'ts',
	'ors',
	'bottles',
	'hzds',
	'my',
	'ps',
]);
export const ud = propToDic(updateProps);

const myDataProps: Array<keyof UpdateMyData> = ([
	'sid',
	'tid',
	'buffs',
	'team',
	'playerRemove',
	'foodRemove',
	'miscRemove',
	'playerType',
	'life0',
	'life1',
	'life2',
	'rank',
	'life',
	'stabs',
	'streak',
	'kills',
	'kStreak',
	'pid',
	'skill1',
	'skillActive1',
	'skillDisabled1',
	'skill2',
	'skillActive2',
	'skillCooldown2',
	'skillDisabled2',
	'skill3',
	'skillActive3',
	'skillCooldown3',
	'skillDisabled3',
	'vultrMaintenance',
	'hscore',
	'scoreL',
	'money',
	'sightRadius',
	'sightRadiusAfterBlind',
	'x',
	'y',
	'hp',
	'stamina',
	'blood',
	'reinc',
	'closeTime',
	'oxy',

	'numPlayers',
	'armageddonStartTime',
	'armageddonStarted',
	'armageddonPlace',
	'closedSectors',
	'closingSectorX',
	'closingSectorY',
	'closingSectorDirection',
	'closingSectorStartTime',
	'closingSectorEndTime',

	'publicChestX',
	'publicChestY',
	'publicChestOpened',

	'spearsBought',
	'spearType',

	'userRemove',
	'userAdd',

	'templeChestOpened',

	'whirlpoolX',
	'whirlpoolY',
	'whirlpoolSpawnTime',
	'whirlpoolEndTime',
	'sirenX',
	'sirenY',
	'sirenSpawnTime',
	'sirenEndTime',
	'ouroboros1X',
	'ouroboros1Y',
	'ouroboros2X',
	'ouroboros2Y',
	'animateOuroborus',

	'cpsPlace',
	'opponentCurrentLife',
	'opponentHpPercent',
	'numZombies',
	'timeResult',

	'playerMode',
	'dojoId',
]);
export const md = propToDic(myDataProps);

const playerDataProps: Array<keyof PartialPlayerData> = ([
	'name',
	'tid',
	'decoSet',
	'blink',
	'id',
	'stababId',
	'maxHp',
	'hp',
	'x', // x10
	'y', // x10
	'stun',
	'bodyRadius',
	'spearWidth',
	'spearHeight',
	'deg', // x10
	'spearType',
	'fishType',
	'playerType',
	'mobLvl',
	'noobLvl',
	'isAdmin',
	'isModerator',
	'isDraco',
	'skill1',
	'skill2',
	'skill3',
	'spearLvl',
	'hasSpear',
	'frozen',
	'burn',
	'paralyzed',
	'spd', // x10
	'sbs',
	'spearTemparature',
	'isHealing',
	'blind',
	'skinGroup',
	'spearElectrified',
	'isFishBolt',
	'nmsStage',
	'zompkin',
	'jobuff',
	'isWrapped',
	'assassinLvl',
	'daredevilLvl',
]);
export const pd = propToDic(playerDataProps);

const stababDataProps: Array<keyof StababData> = (['id', 'ft', 'pid', 'd', 'dist', 'scale', 'state', 'zompkin', 't']);
export const sd = propToDic(stababDataProps);

const foodDataProps: Array<keyof FoodData> = (['id', 'x', 'y', 'tx', 'ty', 'eatenTime', 'deathTime', 'ft']);
export const fd = propToDic(foodDataProps);

function propToDic<T>(s: Array<keyof T>): { [k in keyof T]: number } {
	return s.reduce((d: any, v: keyof T, i: number) => {
		d[v] = i;
		return d;
	}, {});
}

export class MyDataView extends DataView {
	public getBigUint(offset: number) {
		const a = this.getUint32(offset);
		const b = this.getUint32(offset + 4);
		return a * 4294967295 + b + a;
	}
	public setBigUint(offset: number, int: number) {
		const a = Math.floor(int / 4294967295);
		const b = int - a - a * 4294967295;
		this.setUint32(offset, a);
		this.setUint32(offset + 4, b);
	}
}

export function decodeUpdateState(buff: ArrayBuffer): UpdateState {

	const data = new MyDataView(buff);
	const res: UpdateState = { t: Number(data.getBigUint(1)), rt: Number(data.getBigUint(9)), my: {} };
	const len = buff.byteLength;
	let offset = 0;
	res.fds = [];
	res.ps = [];
	res.ts = [];
	res.ors = [];
	res.bottles = [];
	res.hzds = [];
	res.state = data.getUint8(0);
	res.t = Number(data.getBigUint(1));
	res.rt = Number(data.getBigUint(9));
	offset += 17;
	while (offset < len) {
		const index = data.getUint8(offset);
		offset += 1;
		const prop = updateProps[index];
		if (prop === 'my') {
			const dlen = data.getUint16(offset);
			offset += 2;
			const value = buff.slice(offset, offset + dlen);
			res[prop] = decodeMyData(value);
			offset += dlen;
		} else if (prop === 'ranks' || prop === 'evts' || prop === 'mis') {
			const dlen = data.getUint16(offset);
			offset += 2;
			const value = buff.slice(offset, offset + dlen);
			res[prop] = JSON.parse(decodeString(value));
			offset += dlen;
		} else if (prop === 'fds') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res.fds.push(decodeFoodData(value));
			offset += dlen;
		} else if (prop === 'ps') {
			const dlen = data.getUint16(offset);
			offset += 2;
			const value = buff.slice(offset, offset + dlen);
			res.ps.push(decodePlayerData(value));
			offset += dlen;
		} else if (prop === 'ts') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res.ts.push(decodeTracker(value));
			offset += dlen;
		} else if (prop === 'ors') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res.ors.push(decodeOre(value));
			offset += dlen;
		} else if (prop === 'hzds') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res.hzds.push(decodeHazard(value));
			offset += dlen;
		} else if (prop === 'bottles') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res.bottles.push(decodeBottle(value));
			offset += dlen;
		}
	}

	return res;
}

export function decodeIdList(buffer: ArrayBuffer): number[] {
	const list: number[] = [];
	const data = new MyDataView(buffer);
	const len = buffer.byteLength / 4;
	for (let i = 0; i < len; i++) {
		list.push(data.getUint32(i * 4));
	}
	return list;
}
export function decodeString(buffer: ArrayBuffer): string {
	const sliced: number[] = [];
	const strLen = buffer.byteLength / 2;
	let offset = 0;
	const data = new MyDataView(buffer);
	for (let i = 0; i < strLen; i++) {
		sliced.push(data.getUint16(offset));
		offset += 2;
	}
	return String.fromCharCode.apply(null, sliced);
}
export function decodeStringList(buff: ArrayBuffer): string[] {
	const list: string[] = [];
	const data = new MyDataView(buff);
	const len = buff.byteLength;
	let offset = 0;
	while (offset < len) {
		const dlen = data.getUint8(offset);
		offset += 1;
		const value = buff.slice(offset, offset + dlen);
		list.push(decodeString(value));
		offset += dlen;
	}
	return list;
}
export function decodeUserList(buff: ArrayBuffer): UserData[] {
	const list: UserData[] = [];
	const data = new MyDataView(buff);
	const len = buff.byteLength;
	let offset = 0;
	while (offset < len) {
		const dlen = data.getUint8(offset);
		offset += 1;
		const value = buff.slice(offset, offset + dlen);
		list.push(decodeUser(value));
		offset += dlen;
	}
	return list;
}

export function decodeUser(buff: ArrayBuffer): UserData {
	const data = new MyDataView(buff);
	let offset = 0;
	const uidLen = data.getUint8(offset);
	offset += 1;
	const uidValue = buff.slice(offset, offset + uidLen);
	const uid = decodeString(uidValue);
	offset += uidLen;
	const nameLen = data.getUint8(offset);
	offset += 1;
	const nameValue = buff.slice(offset, offset + nameLen);
	const name = decodeString(nameValue);
	offset += nameLen;
	const muted = data.getUint8(offset++) ? true : false;
	const mutedYou = data.getUint8(offset++) ? true : false;
	const mutedAll = data.getUint8(offset++) ? true : false;
	const dojoId = data.getUint8(offset++);
	return { uid, name, muted, mutedYou, mutedAll, dojoId };
}
export function decodeMyData(buff: ArrayBuffer): UpdateMyData {
	const res: UpdateMyData = {};

	const data = new MyDataView(buff);
	const len = buff.byteLength;
	let offset = 0;
	while (offset < len) {
		const index = data.getUint8(offset);
		offset += 1;
		const prop = myDataProps[index];
		if (prop === 'sightRadius' || prop === 'hp' || prop === 'sightRadiusAfterBlind' || prop === 'spearsBought') {
			const value = data.getUint16(offset);
			res[prop] = value;
			offset += 2;
		} else if (prop === 'sid' || prop === 'tid' || prop === 'buffs') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res[prop] = decodeString(value);
			offset += dlen;
		} else if (prop === 'team' || prop === 'playerRemove' || prop === 'foodRemove' || prop === 'miscRemove') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res[prop] = decodeIdList(value);
			offset += dlen;
		} else if (prop === 'x' || prop === 'y' || prop === 'hscore' || prop === 'scoreL' || prop === 'money' || prop === 'stabs' || prop === 'kills' || prop === 'streak' || prop === 'kStreak' || prop === 'pid' || prop === 'closedSectors' || prop === 'publicChestX' || prop === 'publicChestY') {

			let value;
			if (index === md.x || index === md.y) {
				value = data.getInt32(offset) / 10;
			} else {
				value = data.getUint32(offset);
			}
			res[prop] = value;
			offset += 4;
		} else if (prop === 'userRemove') {
			const dlen = data.getUint16(offset);
			offset += 2;
			const value = buff.slice(offset, offset + dlen);
			res[prop] = decodeStringList(value);
			offset += dlen;
		} else if (prop === 'userAdd') {
			const dlen = data.getUint16(offset);
			offset += 2;
			const value = buff.slice(offset, offset + dlen);
			res[prop] = decodeUserList(value);
			offset += dlen;
		} else if (prop === 'reinc' || prop === 'closeTime' || prop === 'closingSectorStartTime' || prop === 'closingSectorEndTime' || prop === 'armageddonStartTime' || prop === 'timeResult') {
			const value = data.getBigUint(offset);
			res[prop] = Number(value);
			offset += 8;
		} else {
			let value;
			if (index === md.skill2 || index === md.skill3 || index === md.cpsPlace) {
				value = data.getInt8(offset);
			} else {
				value = data.getUint8(offset);
			}
			if (index === md.stamina || index === md.skillCooldown2 || index === md.blood || index === md.oxy || index === md.skillCooldown3) { value /= 100; }

			res[prop] = value as never;
			offset += 1;
		}
	}
	return res;
}
export function decodePlayerData(buff: ArrayBuffer) {
	const res: PartialPlayerData = { id: 0, sbs: [] };
	const data = new MyDataView(buff);
	const len = buff.byteLength;
	let offset = 0;
	while (offset < len) {
		const index = data.getUint8(offset);
		offset += 1;
		const prop = playerDataProps[index];
		if (prop === 'name' || prop === 'tid' || prop === 'decoSet') {
			const strLen = data.getUint8(offset++) / 2;
			const sliced: number[] = [];
			for (let i = 0; i < strLen; i++) {
				sliced.push(data.getUint16(offset));
				offset += 2;
			}
			const str = String.fromCharCode.apply(null, sliced);
			res[prop] = str;
		} else if (prop === 'blink') {
			const value = data.getBigUint(offset);
			res[prop] = Number(value);
			offset += 8;
		} else if (prop === 'id' || prop === 'x' || prop === 'y' || prop === 'hp' || prop === 'stababId' || prop === 'maxHp' || prop === 'jobuff') {

			let value;
			if (index === pd.x || index === pd.y) {
				value = data.getInt32(offset) / 10;
			} else {
				value = data.getUint32(offset);
			}
			res[prop] = value;
			offset += 4;
		} else if (prop === 'deg' || prop === 'stun' || prop === 'bodyRadius' || prop === 'spearWidth' || prop === 'spearHeight' || prop === 'spearTemparature' || prop === 'nmsStage') {

			let value;
			if (index === pd.spearTemparature) {
				value = data.getInt16(offset);
			} else if (index === pd.deg) {
				value = data.getInt16(offset) / 10;
			} else {
				value = data.getUint16(offset);
			}
			res[prop] = value;
			offset += 2;
		} else if (prop === 'hasSpear' || prop === 'skill1' || prop === 'skill2' || prop === 'skill3' || prop === 'frozen' || prop === 'paralyzed' || prop === 'burn' || prop === 'spearElectrified' || prop === 'isHealing' || prop === 'blind' || prop === 'isFishBolt' || prop === 'zompkin') {

			const value = data.getUint8(offset);
			res[prop] = value ? true : false;
			offset += 1;
		} else if (prop === 'sbs') {
			const dlen = data.getUint8(offset);
			offset += 1;
			const value = buff.slice(offset, offset + dlen);
			res.sbs!.push(decodeStababData(value));
			offset += dlen;
		} else if (prop === 'stababs') {
		} else {

			let value;
			if (index === pd.spd || index === pd.spearLvl) {
				value = data.getInt8(offset) / 10;
			} else {
				value = data.getUint8(offset);
			}
			res[prop] = value as never;
			offset += 1;
		}

	}
	return res;
}

export function decodeStababData(buff: ArrayBuffer): PartialStababData {
	const res: PartialStababData = { id: 0 };

	const data = new MyDataView(buff);
	const len = buff.byteLength;
	let offset = 0;
	while (offset < len) {
		const index = data.getUint8(offset);
		offset += 1;
		const prop = stababDataProps[index];
		if (prop === 'id' || prop === 'pid') {
			const value = data.getUint32(offset);
			res[prop] = Number(value);
			offset += 4;
		} else if (prop === 'dist') {
			const value = data.getUint16(offset);
			res[prop] = Number(value) / 10;
			offset += 2;
		} else {
			let value = data.getUint8(offset);
			if (index === sd.scale) {
				value /= 10;
			}
			res[prop] = value as any;
			offset += 1;
		}
	}
	return res;
}
export function decodeFoodData(buff: ArrayBuffer): PartialFoodData {
	const res: PartialFoodData = { id: 0 };

	const data = new MyDataView(buff);
	const len = buff.byteLength;
	let offset = 0;
	while (offset < len) {
		const index = data.getUint8(offset);
		offset += 1;
		const prop = foodDataProps[index];
		if (prop === 'ft') {
			const value = data.getUint8(offset);
			res[prop] = value;
			offset += 1;
		} else if (prop === 'eatenTime' || prop === 'deathTime') {
			const value = data.getBigUint(offset);
			res[prop] = Number(value);
			offset += 8;
		} else {
			let value = data.getInt32(offset);
			if (index !== fd.id) {
				value /= 10;
			}
			res[prop] = value;
			offset += 4;
		}
	}
	return res;
}

export function decodeTracker(buff: ArrayBuffer): TrackerData {
	const data = new MyDataView(buff);
	return {
		id: data.getUint32(0),
		type: data.getUint8(4),
		x: data.getInt32(5) / 10,
		y: data.getInt32(9) / 10,
	};
}
export function decodeHazard(hazard: ArrayBuffer): HazardData {
	const data = new MyDataView(hazard);
	return {
		id: data.getUint32(0),
		type: data.getUint8(4),
		x: data.getInt32(5) / 10,
		y: data.getInt32(9) / 10,
		deg: data.getInt16(13) / 10,
		active: data.getInt8(15) ? true : false,
	};
}
export function decodeOre(buff: ArrayBuffer): OreData {
	const data = new MyDataView(buff);
	return {
		id: data.getUint32(0),
		color: data.getUint8(4),
		x: data.getInt32(5) / 10,
		y: data.getInt32(9) / 10,
	};
}
export function decodeBottle(buff: ArrayBuffer): BottleData {
	const data = new MyDataView(buff);
	return {
		id: data.getUint32(0),
		x: data.getInt32(4) / 10,
		y: data.getInt32(8) / 10,
	};
}
