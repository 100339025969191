import { SpecialSkill } from './skills';

export enum SpearType {
	Tusk,
	Trident,
	Chillblade,
	Chainsaw,
	Pickaxe,
	HolySword,
	MercyStaff,
	Crossbow,
	HolyTusk,
	HammerToy,
	Firesaber,
	LightningRod,
}

export const spearLvlRequirements: number[][] = [];

spearLvlRequirements[SpearType.Tusk] = [0, 500, 2000, 5000];
spearLvlRequirements[SpearType.Pickaxe] = [0, 500, 2000, 5000];
spearLvlRequirements[SpearType.MercyStaff] = [0, 500, 2000, 5000];
spearLvlRequirements[SpearType.HolySword] = [0, 500, 2000, 5000];
spearLvlRequirements[SpearType.Crossbow] = [0, 1500, 3000, 6000];
spearLvlRequirements[SpearType.Trident] = [0, 2000, 5000, 10000];
spearLvlRequirements[SpearType.Chainsaw] = [0, 2000, 5000, 10000];
spearLvlRequirements[SpearType.Chillblade] = [0, 5000, 10000, 20000];
spearLvlRequirements[SpearType.Firesaber] = [0, 7500, 15000, 30000];
spearLvlRequirements[SpearType.LightningRod] = [0, 12500, 25000, 50000];
spearLvlRequirements[SpearType.HolyTusk] = [0, 0, 0, 0];
spearLvlRequirements[SpearType.HammerToy] = [0, 500, 2000, 5000];

export const spearCost: number[] = [];

spearCost[SpearType.Tusk] = 0;
spearCost[SpearType.MercyStaff] = 100;
spearCost[SpearType.Pickaxe] = 1000;
spearCost[SpearType.Crossbow] = 6000;
spearCost[SpearType.Trident] = 10000;
spearCost[SpearType.Chainsaw] = 10000;
spearCost[SpearType.Chillblade] = 20000;
spearCost[SpearType.Firesaber] = 30000;
spearCost[SpearType.LightningRod] = 50000;
spearCost[SpearType.HammerToy] = 4141;


export const spearSkills: SpecialSkill[] = [];
spearSkills[SpearType.MercyStaff] = SpecialSkill.MercyRelease;
spearSkills[SpearType.Crossbow] = SpecialSkill.FishBolt;
spearSkills[SpearType.Trident] = SpecialSkill.TridentWave;




export const weaponNames: string[] = [];
weaponNames[SpearType.Tusk] = 'Tusk';
weaponNames[SpearType.Trident] = 'Trident';
weaponNames[SpearType.Chainsaw] = 'Chainsaw';
weaponNames[SpearType.Chillblade] = 'Chillblade';
weaponNames[SpearType.Pickaxe] = 'Pickaxe';
weaponNames[SpearType.MercyStaff] = 'MercyStaff';
weaponNames[SpearType.Crossbow] = 'Crossbow';
weaponNames[SpearType.HammerToy] = 'HammerToy';
weaponNames[SpearType.Firesaber] = 'Firesaber';
weaponNames[SpearType.LightningRod] = 'LightningRod';

export const weaponLongNames: string[] = [];
weaponLongNames[SpearType.Tusk] = 'The Ancient Tusk';
weaponLongNames[SpearType.Trident] = 'The Water Trident';
weaponLongNames[SpearType.Chainsaw] = 'The Brutal Chainsaw';
weaponLongNames[SpearType.Chillblade] = 'The Freezing Chillblade';
weaponLongNames[SpearType.Pickaxe] = 'The Mining Pickaxe';
weaponLongNames[SpearType.Crossbow] = 'The Hunting Crossbow';
weaponLongNames[SpearType.MercyStaff] = 'The Mercy Staff';
weaponLongNames[SpearType.HammerToy] = 'The Fool\'s Toy';
weaponLongNames[SpearType.Firesaber] = 'The Burning Firesaber';
weaponLongNames[SpearType.LightningRod] = 'The Electric Lightning Rod';

export const weaponDecs: string[] = [];
weaponDecs[SpearType.Tusk] =
  'The default weapon of all stabfish. Strong and reliable.';
weaponDecs[SpearType.Pickaxe] =
  'Tool for mining ores with ease. Weapon damage decreases by 10%.';
weaponDecs[SpearType.Chillblade] = 'Freeze your oppenent upon contact.';
weaponDecs[SpearType.Firesaber] = 'Burn your oppenent upon contact.';
weaponDecs[SpearType.LightningRod] = 'Electrify your impaled victims whenever you stab an enemy or use electric-type skills.';
weaponDecs[SpearType.Chainsaw] =
  'Every cut into your opponent grinds away their flesh! More food on the table!';
weaponDecs[SpearType.HammerToy] =
  'Bonk your enemy far far away! Only available on April Fools\' Day!';
