















import { ItemCode, ItemInfo, itemInfos } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { blueprintInfos } from '@/game/infos/blueprintInfos';
import BlueprintInventory from './BlueprintInventory.vue';
import {
  hasQuestTimeStarted,
  TimeLimitedQuestCode,
} from '@/game/infos/questTimeLimited';

@Component({
  components: {
    BlueprintInventory,
  },
})
export default class CollectiblesTab extends Vue {
  public get items(): ItemCodeAmountPair[] {
    const inventory = userx.inventory.availables;
    const pairs: ItemCodeAmountPair[] = [];
    for (const itemCode in blueprintInfos) {
      if (Object.prototype.hasOwnProperty.call(blueprintInfos, itemCode)) {
        const bpInfo = blueprintInfos[itemCode];
        if (!bpInfo.isReward) {
          // if (
          //   Number(itemCode) === ItemCode.BpSantaBeard &&
          //   !hasQuestTimeStarted(TimeLimitedQuestCode.Xmas2022)
          // ) {
          //   continue;
          // }
          if (
            Number(itemCode) === ItemCode.BpDragonHorns
          ) {
            continue;
          }
          pairs.push({
            itemCode: bpInfo.code,
            amount: inventory[bpInfo.code],
          });
        }
      }
    }
    return pairs;
  }

  public get uncraftedList() {
    const targets = this.items.map((vp) => blueprintInfos[vp.itemCode].result);
    return targets.map((code) => userx.inventory.crafted.indexOf(code) === -1);
  }
  public onClick(itemInfo: ItemInfo | undefined, elementId: string) {
    if (itemInfo) {
      Global.craftModal.show(itemInfo.code);
    }
  }
}
