import { FishType } from '@/game/infos/fishInfos';
import { HazardType } from '@/game/infos/hazardInfos';
import { arrayRemoveOne } from '@/util/array';
import { Body } from 'matter-js';
import { SpearType } from '@/game/infos/spearInfos';
import { GameRoom } from '../rooms/room';

export class MiscContainer {
	public items: MiscItem[] = [];
	public bodies: Body[] = [];

	public room: GameRoom;

	constructor(room: GameRoom) {
		this.room = room;
	}
	public addItem(item: MiscItem) {
		this.items.push(item);
	}
	public removeItem(item: MiscItem) {
		arrayRemoveOne(this.items, item);

		for (const session of this.room.observers) {
			session.state.removeMisc(item.data.id);
		}
	}
	public addBody(body: Body) {
		this.bodies.push(body);
	}
	public removeBody(body: Body) {
		arrayRemoveOne(this.bodies, body);
	}
	public next(delta: number, time: number) {
		for (const item of this.items) {
			item.next(delta, time);
		}
	}

	public getAllData() {
		return this.items.map((i) => JSON.parse(JSON.stringify(i.data)));
	}
}

export interface Interpolatable {
	x: number,
	y: number,
	sc?: number,
	d?: number,
	cs?: Interpolatable[], // child MiscData, must be consistent
}
export interface MiscData extends Interpolatable {
	type: MiscType,
	id: number,
}

export interface MiscItem {
	meOnly?: string,
	data: MiscData,
	next: (delta: number, time: number) => void;
}

export enum MiscType {
	SsMegaBite,
	SsSwimmingFang,
	SsWhammyWave,
	SsRation,
	SsBandage,
	SsSpinDodge,
	SsSpikeArmor,
	SsHolySword,
	SsDiamondHarden,
	SsInkSac,
	SsBodySlam,
	SsSacrifice,
	SsTempSafeZone,
	SsForceField,
	SsElectricField,
	SsDart,
	SsBatDart,
	SsElectricDart,
	SsFishBolt,
	McHazardCloud,
	SsTridentWave,
	McIronmonite,
	McIronWave,
	McZellyfish,
	SsFireBullet,
	SsSunPunch,
	SsDecoy,
	SsTentaBola,
	SsDisguise,

	BmSuperPearl,

	BiMechguin,
	BiMechguinMissile,
	BiMechguinBullet,
	BiAtomBomb,
	BiSnowBall,
	BiLaserBeam,
	BiPenguinCrew,

	SsFakeSword,
	SsSwordDance,
	SsStrongArms,
	SsMosaBite,
	SsJomamaShield,

	// SsTentaWrapped,
}

export interface MiscDataSsMegaBite extends MiscData {
	type: MiscType.SsMegaBite,
	id: number,
	x: number,
	y: number,
	sc: number,
	d: number,
	l: number,
	st: number,
	ct: number,
}
export interface MiscDataSsMosaBite extends MiscData {
	type: MiscType.SsMosaBite,
	id: number,
	x: number,
	y: number,
	sc: number,
	d: number,
	l: number,
	st: number,
	ct: number,
}
export interface MiscDataSsSwimmingFang extends MiscData {
	type: MiscType.SsSwimmingFang,
	id: number,
	x: number,
	y: number,
	d: number,
	ac: boolean,
	tid: string,
}
export interface MiscDataSsFishBolt extends MiscData {
	type: MiscType.SsFishBolt,
	id: number,
	x: number,
	y: number,
	d: number,
	ac: boolean, // not aiming
	dd: boolean, // dead
	pr: boolean, // paralized
	ff: number, // frozen or burning
	tid: string,
	s?: {
		d: number, // -1 is isZompkin = true, 0 is alive, 1 is dead
		ft: number, // positive is fishType, negative is stababType
		pid: number,
	};
}
export interface MiscDataMcHazardCloud extends MiscData {
	type: MiscType.McHazardCloud,
	id: number,
	x: number,
	y: number,
	pr: boolean,
	ff: number,
	tid: string,
}
export interface MiscDataSsDart extends MiscData {
	type: MiscType.SsDart,
	id: number,
	x: number,
	y: number,
	d: number,
	ac: boolean, // not aiming
	dd: boolean, // dead
	pr: boolean, // paralized
	ff: number, // frozen or burning
	tid: string,
}
export interface MiscDataSsBatDart extends MiscData {
	type: MiscType.SsBatDart,
	id: number,
	x: number,
	y: number,
	d: number,
	ac: boolean, // not aiming
	dd: boolean, // dead
	pr: boolean, // paralyzed
	ff: number, // frozen or burning
	tid: string,
}
export interface MiscDataSsElectricDart extends MiscData {
	type: MiscType.SsElectricDart,
	id: number,
	x: number,
	y: number,
	d: number,
	ac: boolean, // not aiming
	dd: boolean, // dead
	ff: number, // frozen or burning
	pr: number, // lightning rod
	tid: string,
}
export interface MiscDataSsFireBullet extends MiscData {
	type: MiscType.SsFireBullet,
	id: number,
	x: number,
	y: number,
	d: number,
	ac: boolean, // not aiming
	dd: boolean, // dead
	ff: number, // frozen or burning
	tid: string,
}
export interface MiscDataSsSunPunch extends MiscData {
	type: MiscType.SsSunPunch,
	id: number,
	x: number,
	y: number,
	st: number,
	ct: number,
	tid: string,
}
export interface MiscDataSsInkSac extends MiscData {
	type: MiscType.SsInkSac,
	id: number,
	x: number,
	y: number,
	sc: number,
	p: number,
	tid: string,
}
export interface MiscDataSsWhammyWave extends MiscData {
	type: MiscType.SsWhammyWave,
	id: number,
	x: number,
	y: number,
	st: number,
	ct: number,
}
export interface MiscDataSsSpikeArmor extends MiscData {
	type: MiscType.SsSpikeArmor,
	id: number,
	x: number,
	y: number,
	st: number,
	ct: number,
}
export interface MiscDataSsBodySlam extends MiscData {
	type: MiscType.SsBodySlam,
	id: number,
	x: number,
	y: number,
	d: number,
	st: number,
	ct: number,
	bs: number,
}
export interface MiscDataSsSacrifice extends MiscData {
	type: MiscType.SsSacrifice,
	pid: number,
	id: number,
	x: number,
	y: number,
	st: number,
	ct: number,
}
export interface MiscDataSsHolySword extends MiscData {
	type: MiscType.SsHolySword,
	id: number,
	x: number,
	y: number,
}
export interface MiscDataSsDiamondHarden extends MiscData {
	type: MiscType.SsDiamondHarden,
	id: number,
	x: number,
	y: number,
	d: number,
}
export interface MiscDataSsBandage extends MiscData {
	type: MiscType.SsBandage,
	id: number,
	x: number,
	y: number,
	st: number,
	bst: number, // skillTime buff
	pid: number,
}
export interface MiscDataSsDisguise extends MiscData {
	type: MiscType.SsDisguise,
	id: number,
	x: number,
	y: number,
	st: number,
	bst: number, // skillTime buff
	pid: number,
}
export interface MiscDataSsRation extends MiscData {
	type: MiscType.SsRation,
	id: number,
	x: number,
	y: number,
	st: number,
	lvl: number,
	ct: number,
	pid: number,
}
export interface MiscDataSsSpinDodge extends MiscData {
	type: MiscType.SsSpinDodge,
	id: number,
	x: number,
	y: number,
	p: number,
	bs: number, // bodyScale
}
export interface MiscDataSsDecoy extends MiscData {
	type: MiscType.SsDecoy,
	id: number,
	x: number,
	y: number,
	cd: number,
	pid: number,
	vid?: number,
	sid?: number,
}
export interface MiscDataSsTempSafeZone extends MiscData {
	type: MiscType.SsTempSafeZone,
	id: number,
	x: number,
	y: number,
	da: number,
}
export interface MiscDataSsForceField extends MiscData {
	type: MiscType.SsForceField,
	id: number,
	x: number,
	y: number,
	ts: number,
	sc: number,
}
export interface MiscDataSsElectricField extends MiscData {
	type: MiscType.SsElectricField,
	id: number,
	x: number,
	y: number,
	ac: number,
	sc: number,
}
export interface MiscDataSsTentaslinger extends MiscData {
	type: MiscType.SsTentaBola,
	id: number,
	x: number,
	y: number,
	px: number,
	py: number,
	br: number,
	pid: number,
	s: number, // skinType
}

export interface MiscDataSsTridentWave extends MiscData {
	type: MiscType.SsTridentWave,
	id: number,
	x: number,
	y: number,
	d: number,
}
export interface MiscDataMcIronmonite extends MiscData {
	type: MiscType.McIronmonite,
	id: number,
	x: number,
	y: number,
	d: number,
	w: number,
}
export interface MiscDataMcIronWave extends MiscData {
	type: MiscType.McIronWave,
	id: number,
	x: number,
	y: number,
	r: number,
	a: number,
	l: number,
	t: SpearType,
}
export interface MiscDataMcZellyfish extends MiscData {
	type: MiscType.McZellyfish,
	id: number,
	x: number,
	y: number,
	p: number,
	h: HazardType,
}

export enum SuperPearlType {
	Ressurection,
	Stamina,
}
export interface MiscDataBmSuperPearl extends MiscData {
	type: MiscType.BmSuperPearl,
	id: number,
	x: number,
	y: number,
	hp: number,
	t: SuperPearlType,
}
export interface MiscDataBody extends Interpolatable {
	d: number,
}
export interface MiscDataBiMechguin extends MiscData {
	type: MiscType.BiMechguin,
	id: number,
	x: number,
	y: number,
	cs: Interpolatable[],
	stt: number,
	ff: number,
	sk: number,
	rp: number,
	stun: number,
	hp: number,
	mhps: number[],
	stg: number,
}
export interface MiscDataBiMechguinWing extends MiscDataBody {
	ms: number,
	stt: number,
}
export interface MiscDataBiMechguinTurret extends MiscDataBody {
	stt: number,
}
export interface MiscDataBiMechguinLaserGun extends MiscDataBody {
	stt: number,
}
export interface MiscDataBiMechguinMissile extends MiscData {
	bid: number,
	d: number,
	stt: number,
	tx: number,
}
export interface MiscDataBiMechguinBullet extends MiscData {
	type: MiscType.BiMechguinBullet,
	id: number,
	x: number,
	y: number,
}
export interface MiscDataBiAtomBomb extends MiscData {
	type: MiscType.BiAtomBomb,
	id: number,
	x: number,
	y: number,
	sc: number,
}
export interface MiscDataBiSnowBall extends MiscData {
	type: MiscType.BiSnowBall,
	id: number,
	x: number,
	y: number,
	sc: number,
	stt: number,
}
export interface MiscDataBiLaserBeam extends MiscData {
	type: MiscType.BiLaserBeam,
	id: number,
	x: number,
	y: number,
	d: number,
	sc: number,
	l: number,
}
export interface MiscDataBiPenguinCrew extends MiscData {
	type: MiscType.BiPenguinCrew,
	x: number,
	y: number,
	id: number,
	d: number,
	stt: number,
	hp: number,
	ff: number,
	pr: number,
	bl: number,
	stn: number,
	w: number,
}

export interface MiscDataSsFakeSword extends MiscData {
	type: MiscType.SsFakeSword,
	id: number,
	x: number,
	y: number,
	d: number,
	st: number,
	lvl: number,
}
export interface MiscDataSsSwordDance extends MiscData {
	type: MiscType.SsSwordDance,
	id: number,
	x: number,
	y: number,
}
export interface MiscDataSsStrongArms extends MiscData {
	type: MiscType.SsStrongArms,
	id: number,
	x: number,
	y: number,
	pid: number,
	vid: number,
	p: number, // skill progress
}
export interface MiscDataSsTentaBola extends MiscData {
	type: MiscType.SsTentaBola;
	id: number,
	x: number,
	y: number,
	d: number,
	sc: number,
	ac: number,
}
// export interface MiscDataSsTentaWrapped extends MiscData {
// 	type: MiscType.SsTentaWrapped;
// 	id: number,
// 	x: number,
// 	y: number,
// 	d: number,
// 	tid: number,
// 	sc: number,
// }
