

































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DecoContestVotableEntry } from '@/game/infos/dataServerPackets';
import { Queuer } from '@/util/queuer';
import { generateLargeFishImage } from '../mainMenu/tools/largeFishImageGenerator';
import { decodeDecoSet } from '@/game/infos/decorativeInfos';
import fb from '@/store/sf-firestore';

@Component({
  components: {},
})
export default class DecoEntryForVote extends Vue {
  @Prop({ required: true }) public entry!: DecoContestVotableEntry;

  public imgSrc = '';
  public isLoading = true;

  @Watch('entry', { immediate: true })
  public async sumcheckChange(newVal: DecoContestVotableEntry) {
    this.imgSrc = '';
    if (!newVal || !newVal.fishType) {
      return;
    }
    this.isLoading = true;
    const { fishType, decoSet } = newVal;
    const skinGroup = newVal.skin || 0;
    await Queuer.queue('fishImage', async () => {
      this.imgSrc = await generateLargeFishImage(
        fishType,
        skinGroup,
        decodeDecoSet(decoSet || ''),
      );
      this.isLoading = false;
    });
  }

  public async onLike() {
    if (!this.entry.uid) {
      return;
    }
    try {
      if (!this.entry.liked) {
        this.entry.liked = true;
        this.entry.numLikes++;
        await fb.actionOnDecoContest(1, this.entry.uid, 'l');
      } else {
        this.entry.liked = false;
        this.entry.numLikes--;
        await fb.actionOnDecoContest(1, this.entry.uid, 'rl');
      }
    } catch (error) {
      this.showError(error);
    }
  }
  public async onDislike() {
    if (!this.entry.uid) {
      return;
    }
    try {
      if (!this.entry.disliked) {
        this.entry.disliked = true;
        this.entry.numDislikes++;
        await fb.actionOnDecoContest(1, this.entry.uid, 'd');
      } else {
        this.entry.disliked = false;
        this.entry.numDislikes--;
        await fb.actionOnDecoContest(1, this.entry.uid, 'rd');
      }
    } catch (error) {
      this.showError(error);
    }
  }
  public async onReport() {
    if (!this.entry.uid) {
      return;
    }
    try {
      if (!this.entry.reported) {
        this.entry.reported = true;
        await fb.actionOnDecoContest(1, this.entry.uid, 'r');
      } else {
        this.entry.reported = false;
        await fb.actionOnDecoContest(1, this.entry.uid, 'rr');
      }
    } catch (error) {
      this.showError(error);
    }
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
