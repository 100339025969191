import { Dictionary } from '@/util/dictionary';
import { ItemCode, itemInfos } from './itemInfos';

export interface BlueprintInfo {
	code: ItemCode,
	result: ItemCode,
	recipe: Array<[number, number]>,
	bpUsed: number,
	timeLimited?: boolean,
	isReward?: boolean,
}

export const blueprintInfos: Dictionary<BlueprintInfo> = {};


let code: ItemCode;
let result: ItemCode;





result = ItemCode.JackOLanternJaw;
code = ItemCode.BpJackOLanternJaw;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Potato, 1],
		[ItemCode.Apple, 1],
		[ItemCode.GulperEelStone, 100],
		[ItemCode.GulperEelGem, 1],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.JackOLantern;
code = ItemCode.BpJackOLantern;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.GhostPepperGreen, 1],
		[ItemCode.GhostPepperRed, 1],
		[ItemCode.Kill4Rock, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.DragonHorns;
code = ItemCode.BpDragonHorns;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.RankBMedal;
code = ItemCode.BpRankBMedal;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankBRock, 10],
		[ItemCode.LapisLazuliOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.RankAMedal;
code = ItemCode.BpRankAMedal;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankARock, 10],
		[ItemCode.RankBRock, 30],
		[ItemCode.SpinelOre, 10],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.RankSMedal;
code = ItemCode.BpRankSMedal;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankSRock, 10],
		[ItemCode.RankBRock, 30],
		[ItemCode.RankARock, 10],
		[ItemCode.Money, 30000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.RankSSMedal;
code = ItemCode.BpRankSSMedal;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankSSRock, 3],
		[ItemCode.RankSRock, 30],
		[ItemCode.RankARock, 30],
		[ItemCode.Money, 50000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.RankSSSMedal;
code = ItemCode.BpRankSSSMedal;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankSSSRock, 1],
		[ItemCode.RankSSRock, 10],
		[ItemCode.RankSRock, 30],
		[ItemCode.Money, 100000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Score1Ring;
code = ItemCode.BpScore1Ring;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Score1Rock, 10],
		[ItemCode.IronOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Score2Pendant;
code = ItemCode.BpScore2Pendant;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Score2Rock, 10],
		[ItemCode.Score1Rock, 30],
		[ItemCode.PeridotOre, 10],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Score3Belt;
code = ItemCode.BpScore3Belt;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Score3Rock, 10],
		[ItemCode.Score1Rock, 30],
		[ItemCode.Score2Rock, 10],
		[ItemCode.Money, 30000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Score4Collar;
code = ItemCode.BpScore4Collar;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Score4Rock, 3],
		[ItemCode.Score3Rock, 30],
		[ItemCode.Score2Rock, 30],
		[ItemCode.Money, 50000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Score5Crown;
code = ItemCode.BpScore5Crown;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Score5Rock, 1],
		[ItemCode.Score4Rock, 10],
		[ItemCode.Score3Rock, 30],
		[ItemCode.Money, 100000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Kill1Ring;
code = ItemCode.BpKill1Ring;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Kill1Rock, 10],
		[ItemCode.CopperOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Kill2Pendant;
code = ItemCode.BpKill2Pendant;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Kill2Rock, 10],
		[ItemCode.Kill1Rock, 30],
		[ItemCode.QuartzOre, 10],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Kill3Belt;
code = ItemCode.BpKill3Belt;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Kill3Rock, 10],
		[ItemCode.Kill1Rock, 30],
		[ItemCode.Kill2Rock, 10],
		[ItemCode.Money, 30000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Kill4Collar;
code = ItemCode.BpKill4Collar;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Kill4Rock, 3],
		[ItemCode.Kill3Rock, 30],
		[ItemCode.Kill2Rock, 30],
		[ItemCode.Money, 50000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.Kill5Crown;
code = ItemCode.BpKill5Crown;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Kill5Rock, 1],
		[ItemCode.Kill4Rock, 10],
		[ItemCode.Kill3Rock, 30],
		[ItemCode.Money, 100000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: true,
};

result = ItemCode.SantaHat;
code = ItemCode.BpSantaHat;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SpinelOre, 50],
		[ItemCode.IronOre, 50],
		[ItemCode.Money, 1000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.CandyStick;
code = ItemCode.BpCandyStick;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.QuartzOre, 50],
		[ItemCode.IronOre, 50],
		[ItemCode.AmethystOre, 10],
		[ItemCode.Money, 2000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SantaBeard;
code = ItemCode.BpSantaBeard;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.GentlemanMustache, 2],
		[ItemCode.WhiteWig, 5],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.ReindeerHeadband;
code = ItemCode.BpReindeerHeadband;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.CopperOre, 100],
		[ItemCode.PeridotOre, 100],
		[ItemCode.EmeraldOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.StarLantern;
code = ItemCode.BpStarLantern;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SilverOre, 5],
		[ItemCode.GoldOre, 5],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.RedPacket;
code = ItemCode.BpRedPacket;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PaperPlane, 1],
		[ItemCode.SpinelOre, 8],
		[ItemCode.GoldOre, 1],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.PirateBandana;
code = ItemCode.BpPirateBandana;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PenguinStone, 50],
		[ItemCode.BirdOrb, 5],
		[ItemCode.Hachimaki, 1],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.PirateCaptainBicorne;
code = ItemCode.BpPirateCaptainBicorne;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PenguinGem, 1],
		[ItemCode.PenguinStone, 100],
		[ItemCode.BirdOrb, 10],
		[ItemCode.BlueCap, 2],
		[ItemCode.GoldOre, 1],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: true,
	isReward: false,
};

result = ItemCode.Shield;
code = ItemCode.BpShield;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankAMedal, 1],
		[ItemCode.TurquoiseOre, 25],
		[ItemCode.GarnetOre, 25],
		[ItemCode.CoconutTree, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Sword;
code = ItemCode.BpSword;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankSRock, 1],
		[ItemCode.IronOre, 100],
		[ItemCode.SilverOre, 30],
		[ItemCode.DiamondOre, 1],
		[ItemCode.UnicornHorn, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Saber;
code = ItemCode.BpSaber;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankSRock, 1],
		[ItemCode.IronOre, 100],
		[ItemCode.CitrineOre, 30],
		[ItemCode.GoldOre, 1],
		[ItemCode.KnifefishOrb, 20],
		[ItemCode.Money, 5000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SharkSaberYellow;
code = ItemCode.BpSharkSaberYellow;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Saber, 1],
		[ItemCode.SharkOrb, 50],
		[ItemCode.HammeredHeadStone, 100],
		[ItemCode.HammeredHeadGem, 5],
		[ItemCode.CitrineOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SharkSaberRed;
code = ItemCode.BpSharkSaberRed;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Saber, 1],
		[ItemCode.SharkOrb, 50],
		[ItemCode.MegalodonStone, 100],
		[ItemCode.MegalodonGem, 5],
		[ItemCode.GarnetOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SharkSaberPurple;
code = ItemCode.BpSharkSaberPurple;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Saber, 1],
		[ItemCode.SharkOrb, 50],
		[ItemCode.WhammyHeadStone, 100],
		[ItemCode.WhammyHeadGem, 5],
		[ItemCode.AmethystOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SharkSaberBlue;
code = ItemCode.BpSharkSaberBlue;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Saber, 1],
		[ItemCode.SharkOrb, 50],
		[ItemCode.GreatWhiteSharkStone, 100],
		[ItemCode.GreatWhiteSharkGem, 5],
		[ItemCode.AquamarineOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DolphinSwordPurple;
code = ItemCode.BpDolphinSwordPurple;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Sword, 1],
		[ItemCode.DolphinOrb, 50],
		[ItemCode.PsychoKillerWhaleStone, 100],
		[ItemCode.PsychoKillerWhaleGem, 5],
		[ItemCode.AmethystOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DolphinSwordGreen;
code = ItemCode.BpDolphinSwordGreen;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Sword, 1],
		[ItemCode.DolphinOrb, 50],
		[ItemCode.SpinnerDolphinStone, 100],
		[ItemCode.SpinnerDolphinGem, 5],
		[ItemCode.TurquoiseOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DolphinSwordTeal;
code = ItemCode.BpDolphinSwordTeal;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Sword, 1],
		[ItemCode.DolphinOrb, 50],
		[ItemCode.CommonDolphinStone, 100],
		[ItemCode.CommonDolphinGem, 5],
		[ItemCode.AquamarineOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DolphinSwordBlack;
code = ItemCode.BpDolphinSwordBlack;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Sword, 1],
		[ItemCode.DolphinOrb, 50],
		[ItemCode.SerialKillerWhaleStone, 100],
		[ItemCode.SerialKillerWhaleGem, 5],
		[ItemCode.GarnetOre, 20],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Quill;
code = ItemCode.BpQuill;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankARock, 5],
		[ItemCode.BelugaStone, 10],
		[ItemCode.NarwhalOrb, 10],
		[ItemCode.QuartzOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 6,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.UnicornHorn;
code = ItemCode.BpUnicornHorn;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.NarwhalStone, 20],
		[ItemCode.NarwhalOrb, 10],
		[ItemCode.RankSRock, 5],
		[ItemCode.SpinelOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 24,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SeaUrchin;
code = ItemCode.BpSeaUrchin;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PufferfishStone, 20],
		[ItemCode.PufferfishOrb, 10],
		[ItemCode.Kill2Rock, 10],
		[ItemCode.SpinelOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 15,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Moss;
code = ItemCode.BpMoss;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.GreenSeaTurtleStone, 20],
		[ItemCode.TurtleOrb, 10],
		[ItemCode.Score1Rock, 10],
		[ItemCode.PeridotOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 63,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.WhiteWig;
code = ItemCode.BpWhiteWig;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.EelOrb, 10],
		[ItemCode.IronOre, 10],
		[ItemCode.MorayEelStone, 20],
		[ItemCode.Kill1Rock, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 15,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.PaperPlane;
code = ItemCode.BpPaperPlane;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.StingrayStone, 10],
		[ItemCode.Kill1Rock, 3],
		[ItemCode.RayOrb, 10],
		[ItemCode.IronOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 50,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Blush;
code = ItemCode.BpBlush;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SakuraShrimpStone, 20],
		[ItemCode.Kill2Rock, 10],
		[ItemCode.CrustaceanOrb, 10],
		[ItemCode.SpinelOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SkewerStick;
code = ItemCode.BpSkewerStick;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SwordfishStone, 20],
		[ItemCode.SwordfishOrb, 10],
		[ItemCode.Kill2Rock, 10],
		[ItemCode.CopperOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 3,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.KindergartenHat;
code = ItemCode.BpKindergartenHat;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueCrabStone, 20],
		[ItemCode.DumboOctopusStone, 20],
		[ItemCode.CrustaceanOrb, 10],
		[ItemCode.SpinelOre, 5],
		[ItemCode.PeridotOre, 5],
		[ItemCode.Money, 1000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.PlasticBag;
code = ItemCode.BpPlasticBag;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.CrocodileStone, 20],
		[ItemCode.CuttlefishStone, 20],
		[ItemCode.LeatherbackStone, 20],
		[ItemCode.Kill1Rock, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 9,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BlueCap;
code = ItemCode.BpBlueCap;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.KillerWhaleStone, 40],
		[ItemCode.DolphinOrb, 10],
		[ItemCode.BlueSharkStone, 20],
		[ItemCode.KillerWhaleGem, 1],
		[ItemCode.RankBRock, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 7,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Fountain;
code = ItemCode.BpFountain;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueWhaleStone, 20],
		[ItemCode.WhaleOrb, 10],
		[ItemCode.LapisLazuliOre, 10],
		[ItemCode.HumpbackWhaleStone, 5],
		[ItemCode.Money, 1000],
	],
	bpUsed: 60,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Hachimaki;
code = ItemCode.BpHachimaki;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MantaRayStone, 20],
		[ItemCode.PeridotOre, 10],
		[ItemCode.RayOrb, 10],
		[ItemCode.Score3Rock, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 13,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.MiniBubble;
code = ItemCode.BpMiniBubble;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.GoldfishStone, 10],
		[ItemCode.RankBRock, 10],
		[ItemCode.CarpOrb, 10],
		[ItemCode.LapisLazuliOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 37,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BeachBall;
code = ItemCode.BpBeachBall;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BottlenoseDolphinStone, 30],
		[ItemCode.PufferfishOrb, 10],
		[ItemCode.BottlenoseDolphinGem, 1],
		[ItemCode.RankBRock, 10],
		[ItemCode.RankARock, 5],
		[ItemCode.Kill1Rock, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 8,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Bandaid;
code = ItemCode.BpBandaid;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.HammeredHeadStone, 20],
		[ItemCode.Kill2Rock, 10],
		[ItemCode.RayOrb, 10],
		[ItemCode.SpinelOre, 10],
		[ItemCode.Money, 1000],
	],
	bpUsed: 11,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BumpOnHead;
code = ItemCode.BpBumpOnHead;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.HammerheadStone, 20],
		[ItemCode.Kill1Rock, 10],
		[ItemCode.SharkOrb, 10],
		[ItemCode.HammerheadGem, 1],
		[ItemCode.Money, 1000],
	],
	bpUsed: 15,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Hoop;
code = ItemCode.BpHoop;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SpinnerDolphinStone, 30],
		[ItemCode.DolphinOrb, 10],
		[ItemCode.CommonDolphinStone, 30],
		[ItemCode.SpinelOre, 50],
		[ItemCode.Money, 2000],
	],
	bpUsed: 6,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BandaidX;
code = ItemCode.BpBandaidX;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Bandaid, 2],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.CoconutTree;
code = ItemCode.BpCoconutTree;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Moss, 3],
		[ItemCode.Fountain, 3],
		[ItemCode.UnicornHorn, 1],
		[ItemCode.HumpbackWhaleStone, 10],
		[ItemCode.HumpbackWhaleGem, 1],
		[ItemCode.Score2Rock, 10],
		[ItemCode.Money, 2000],
	],
	bpUsed: 13,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SurgicalMask;
code = ItemCode.BpSurgicalMask;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Hachimaki, 1],
		[ItemCode.HammeredHeadGem, 1],
		[ItemCode.HammeredHeadStone, 100],
		[ItemCode.HammerheadGem, 5],
		[ItemCode.Score2Rock, 20],
		[ItemCode.PeridotOre, 50],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Battery;
code = ItemCode.BpBattery;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.ElectricRayStone, 5],
		[ItemCode.ElectricEelStone, 20],
		[ItemCode.CopperOre, 25],
		[ItemCode.CephalopodOrb, 10],
		[ItemCode.Money, 2000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Card;
code = ItemCode.BpCard;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PaperPlane, 1],
		[ItemCode.CrustaceanOrb, 10],
		[ItemCode.PsychoKillerWhaleStone, 10],
		[ItemCode.SpinelOre, 10],
		[ItemCode.Money, 2000],
	],
	bpUsed: 33,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.NeckRuffle;
code = ItemCode.BpNeckRuffle;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Hachimaki, 1],
		[ItemCode.DumboOctopusGem, 5],
		[ItemCode.DumboOctopusStone, 100],
		[ItemCode.VampireSquidGem, 5],
		[ItemCode.VampireSquidStone, 100],
		[ItemCode.Money, 2000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DoodlesShark;
code = ItemCode.BpDoodlesShark;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.DoodlesFish, 1],
		[ItemCode.SharkOrb, 100],
		[ItemCode.GreatWhiteSharkGem, 5],
		[ItemCode.GreatWhiteSharkStone, 100],
		[ItemCode.MegalodonStone, 100],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DoodlesOctopus;
code = ItemCode.BpDoodlesOctopus;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.DoodlesFish, 1],
		[ItemCode.CephalopodOrb, 10],
		[ItemCode.TakoStone, 100],
		[ItemCode.TakoGem, 5],
		[ItemCode.ColossalSquidStone, 30],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.String;
code = ItemCode.BpString;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SpiderCrabStone, 50],
		[ItemCode.CrustaceanOrb, 10],
		[ItemCode.EelOrb, 20],
		[ItemCode.CopperOre, 5],
		[ItemCode.Money, 2000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BaseballGlove;
code = ItemCode.BpBaseballGlove;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Baseball, 1],
		[ItemCode.PlasticBag, 1],
		[ItemCode.NarwhalGem, 5],
		[ItemCode.CitrineOre, 1],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Bungalow;
code = ItemCode.BpBungalow;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BumpOnHead, 3],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.MusicNote;
code = ItemCode.BpMusicNote;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MantraRayStone, 20],
		[ItemCode.WhaleOrb, 10],
		[ItemCode.HumpbackWhaleStone, 30],
		[ItemCode.PeridotOre, 50],
		[ItemCode.Money, 2000],
	],
	bpUsed: 7,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Wristband;
code = ItemCode.BpWristband;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.ColossalSquidStone, 30],
		[ItemCode.KillerWhaleStone, 50],
		[ItemCode.LeatherbackStone, 30],
		[ItemCode.Money, 2000],
	],
	bpUsed: 5,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DoodlesFish;
code = ItemCode.BpDoodlesFish;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Crayon, 1],
		[ItemCode.BlueSharkGem, 5],
		[ItemCode.BlueSharkStone, 50],
		[ItemCode.KillerWhaleGem, 5],
		[ItemCode.KillerWhaleStone, 50],
		[ItemCode.HammeredHeadGem, 5],
		[ItemCode.HammerheadStone, 50],
		[ItemCode.Money, 2000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Pencil;
code = ItemCode.BpPencil;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Crayon, 1],
		[ItemCode.SwordfishGem, 5],
		[ItemCode.CoconutTree, 1],
		[ItemCode.PlasticBag, 1],
		[ItemCode.Money, 2000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Baseball;
code = ItemCode.BpBaseball;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BeachBall, 1],
		[ItemCode.NarwhalOrb, 20],
		[ItemCode.SpinelOre, 50],
		[ItemCode.Money, 2000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Glasses;
code = ItemCode.BpGlasses;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueSharkStone, 50],
		[ItemCode.QuartzOre, 30],
		[ItemCode.SharkOrb, 10],
		[ItemCode.HammerheadStone, 30],
		[ItemCode.BlueSharkGem, 1],
		[ItemCode.Score2Rock, 10],
		[ItemCode.Money, 2000],
	],
	bpUsed: 10,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Crayon;
code = ItemCode.BpCrayon;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MarlinStone, 20],
		[ItemCode.JomamaStone, 20],
		[ItemCode.DumboOctopusGem, 1],
		[ItemCode.DumboOctopusStone, 20],
		[ItemCode.Money, 2000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Egg;
code = ItemCode.BpEgg;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MiniBubble, 1],
		[ItemCode.LeatherbackStone, 50],
		[ItemCode.CrocodileStone, 50],
		[ItemCode.GoldfishGem, 1],
		[ItemCode.GoldenfishGem, 1],
		[ItemCode.CrocodileGem, 1],
		[ItemCode.ReptileOrb, 50],
		[ItemCode.Money, 2000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.WhiteFlower;
code = ItemCode.BpWhiteFlower;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Moss, 1],
		[ItemCode.Fountain, 1],
		[ItemCode.SeaUrchin, 1],
		[ItemCode.DiamondfishStone, 30],
		[ItemCode.CarpOrb, 10],
		[ItemCode.Money, 2000],
	],
	bpUsed: 14,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Ink;
code = ItemCode.BpInk;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.TakoStone, 20],
		[ItemCode.CephalopodOrb, 10],
		[ItemCode.Score2Rock, 10],
		[ItemCode.LapisLazuliOre, 25],
		[ItemCode.Money, 2000],
	],
	bpUsed: 15,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Scar1;
code = ItemCode.BpScar1;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.GreatWhiteSharkStone, 100],
		[ItemCode.SharkOrb, 10],
		[ItemCode.GreatWhiteSharkGem, 5],
		[ItemCode.SilverOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.GentlemanMustache;
code = ItemCode.BpGentlemanMustache;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SerialKillerWhaleStone, 50],
		[ItemCode.SerialKillerWhaleGem, 1],
		[ItemCode.CephalopodOrb, 10],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.JugglingClub;
code = ItemCode.BpJugglingClub;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PsychoKillerWhaleStone, 20],
		[ItemCode.SpinnerDolphinStone, 20],
		[ItemCode.DolphinOrb, 10],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.LargeBubble;
code = ItemCode.BpLargeBubble;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MiniBubble, 5],
		[ItemCode.DiamondfishStone, 30],
		[ItemCode.DiamondfishGem, 1],
		[ItemCode.AquamarineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 6,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Bling;
code = ItemCode.BpBling;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.RankSRock, 5],
		[ItemCode.GoldenfishStone, 20],
		[ItemCode.SilverOre, 5],
		[ItemCode.MythicalOrb, 5],
		[ItemCode.Money, 3000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.PropellerHat;
code = ItemCode.BpPropellerHat;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueCap, 3],
		[ItemCode.BeachBall, 3],
		[ItemCode.HammerheadGem, 5],
		[ItemCode.GarnetOre, 3],
		[ItemCode.AquamarineOre, 3],
		[ItemCode.CitrineOre, 3],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Apple;
code = ItemCode.BpApple;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WhiteFlower, 1],
		[ItemCode.RedNose, 1],
		[ItemCode.TakoGem, 1],
		[ItemCode.Score2Rock, 10],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 5,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Ghost;
code = ItemCode.BpGhost;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.GulperEelStone, 20],
		[ItemCode.MythrilOre, 1],
		[ItemCode.MythicalOrb, 10],
		[ItemCode.Kill3Rock, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 11,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.JokerCard;
code = ItemCode.BpJokerCard;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Card, 1],
		[ItemCode.JugglingClub, 1],
		[ItemCode.PsychoKillerWhaleGem, 5],
		[ItemCode.AquamarineOre, 5],
		[ItemCode.GarnetOre, 5],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Maraca;
code = ItemCode.BpMaraca;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.JugglingClub, 1],
		[ItemCode.MusicNote, 1],
		[ItemCode.AmphibianOrb, 10],
		[ItemCode.AxolotlGem, 1],
		[ItemCode.AxolotlStone, 30],
		[ItemCode.MorayEelGem, 2],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.UmbrellaRed;
code = ItemCode.BpUmbrellaRed;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.NeckRuffle, 1],
		[ItemCode.CandyStick, 1],
		[ItemCode.AmphibianOrb, 30],
		[ItemCode.GoldenfishStone, 100],
		[ItemCode.GoldfishGem, 5],
		[ItemCode.SakuraShrimpStone, 100],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BaseballBat;
code = ItemCode.BpBaseballBat;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Baseball, 1],
		[ItemCode.SkewerStick, 1],
		[ItemCode.NarwhalGem, 5],
		[ItemCode.CitrineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.CaptainCap;
code = ItemCode.BpCaptainCap;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueCap, 1],
		[ItemCode.MarlinGem, 5],
		[ItemCode.MarlinStone, 100],
		[ItemCode.SailfishStone, 50],
		[ItemCode.MantaRayGem, 1],
		[ItemCode.AquamarineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.TopHat;
code = ItemCode.BpTopHat;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.KindergartenHat, 1],
		[ItemCode.SerialKillerWhaleStone, 100],
		[ItemCode.SerialKillerWhaleGem, 5],
		[ItemCode.AquamarineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BrodieHelm;
code = ItemCode.BpBrodieHelm;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.KindergartenHat, 1],
		[ItemCode.KingCrabStone, 50],
		[ItemCode.KingCrabGem, 1],
		[ItemCode.GreenSeaTurtleGem, 10],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Handcuffs;
code = ItemCode.BpHandcuffs;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Wristband, 2],
		[ItemCode.SpiderCrabStone, 100],
		[ItemCode.BatRayStone, 50],
		[ItemCode.BatRayGem, 1],
		[ItemCode.SpiderCrabGem, 5],
		[ItemCode.SilverOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Toast;
code = ItemCode.BpToast;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Egg, 1],
		[ItemCode.Apple, 1],
		[ItemCode.Ink, 1],
		[ItemCode.WheatStraw, 1],
		[ItemCode.BottlenoseDolphinStone, 100],
		[ItemCode.CitrineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Clock;
code = ItemCode.BpClock;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.KronosaurusStone, 100],
		[ItemCode.QuartzOre, 50],
		[ItemCode.ReptileOrb, 50],
		[ItemCode.KronosaurusGem, 2],
		[ItemCode.Money, 3000],
	],
	bpUsed: 5,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.PotatoChips;
code = ItemCode.BpPotatoChips;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Potato, 1],
		[ItemCode.PlasticBag, 1],
		[ItemCode.MosasaurusGem, 5],
		[ItemCode.MosasaurusStone, 100],
		[ItemCode.CitrineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.TrapperHat;
code = ItemCode.BpTrapperHat;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueCap, 1],
		[ItemCode.GreatWhiteSharkGem, 5],
		[ItemCode.GreatWhiteSharkStone, 100],
		[ItemCode.SharkOrb, 10],
		[ItemCode.AquamarineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.DizzyHalo;
code = ItemCode.BpDizzyHalo;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Bling, 1],
		[ItemCode.WhammyHeadGem, 2],
		[ItemCode.WhammyHeadStone, 60],
		[ItemCode.AmethystOre, 1],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.RedNose;
code = ItemCode.BpRedNose;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BumpOnHead, 1],
		[ItemCode.PsychoKillerWhaleGem, 1],
		[ItemCode.PsychoKillerWhaleStone, 50],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 6,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Eyepatch;
code = ItemCode.BpEyepatch;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Bandaid, 1],
		[ItemCode.Ink, 1],
		[ItemCode.AmethystOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 8,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.GhostPepperRed;
code = ItemCode.BpGhostPepperRed;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Apple, 1],
		[ItemCode.Ghost, 1],
		[ItemCode.GulperEelGem, 1],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.FootballHelmet;
code = ItemCode.BpFootballHelmet;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BrodieHelm, 1],
		[ItemCode.AmericanFootball, 1],
		[ItemCode.CommonDolphinGem, 5],
		[ItemCode.CommonDolphinStone, 50],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Schoolbag;
code = ItemCode.BpSchoolbag;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.PlasticBag, 1],
		[ItemCode.Book, 1],
		[ItemCode.MantaRayGem, 5],
		[ItemCode.AmethystOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.HydroTank;
code = ItemCode.BpHydroTank;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Fountain, 1],
		[ItemCode.Clock, 1],
		[ItemCode.SailfishGem, 3],
		[ItemCode.KronosaurusStone, 100],
		[ItemCode.LeatherbackGem, 5],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.GreenSeaTurtleGem, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Bone;
code = ItemCode.BpBone;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MosasaurusStone, 50],
		[ItemCode.UnicornHorn, 1],
		[ItemCode.MosasaurusGem, 1],
		[ItemCode.SilverOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 3,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.LongGlasses;
code = ItemCode.BpLongGlasses;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Glasses, 1],
		[ItemCode.Card, 1],
		[ItemCode.BlueRayGem, 5],
		[ItemCode.AmethystOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.AmericanFootball;
code = ItemCode.BpAmericanFootball;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BeachBall, 1],
		[ItemCode.CommonDolphinStone, 100],
		[ItemCode.CommonDolphinGem, 5],
		[ItemCode.LeatherbackStone, 50],
		[ItemCode.LeatherbackGem, 1],
		[ItemCode.CitrineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Potato;
code = ItemCode.BpPotato;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BumpOnHead, 1],
		[ItemCode.WhiteFlower, 1],
		[ItemCode.PorcupinefishStone, 10],
		[ItemCode.GoldenfishGem, 5],
		[ItemCode.Score2Rock, 10],
		[ItemCode.CitrineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.GhostPepperGreen;
code = ItemCode.BpGhostPepperGreen;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Potato, 1],
		[ItemCode.Ghost, 1],
		[ItemCode.GulperEelGem, 1],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Book;
code = ItemCode.BpBook;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Card, 2],
		[ItemCode.PaperPlane, 5],
		[ItemCode.RankARock, 1],
		[ItemCode.RankBRock, 1],
		[ItemCode.RankSRock, 1],
		[ItemCode.BlueRayGem, 5],
		[ItemCode.Money, 3000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Box;
code = ItemCode.BpBox;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Card, 6],
		[ItemCode.KingCrabStone, 30],
		[ItemCode.KingCrabGem, 1],
		[ItemCode.BlueCrabGem, 2],
		[ItemCode.BlueCrabStone, 50],
		[ItemCode.AquamarineOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 3,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Sombrero;
code = ItemCode.BpSombrero;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WheatStraw, 1],
		[ItemCode.KindergartenHat, 1],
		[ItemCode.AxolotlGem, 5],
		[ItemCode.AxolotlStone, 100],
		[ItemCode.MorayEelGem, 5],
		[ItemCode.MorayEelStone, 100],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Wheel;
code = ItemCode.BpWheel;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Hoop, 1],
		[ItemCode.CoconutTree, 1],
		[ItemCode.AlligatorSnappingTurtleGem, 2],
		[ItemCode.AlligatorSnappingTurtleStone, 100],
		[ItemCode.WhaleOrb, 10],
		[ItemCode.TurtleOrb, 10],
		[ItemCode.SpinnerDolphinStone, 30],
		[ItemCode.Money, 3000],
	],
	bpUsed: 5,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BlackAfro;
code = ItemCode.BpBlackAfro;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WhiteWig, 1],
		[ItemCode.Ink, 1],
		[ItemCode.AquamarineOre, 5],
		[ItemCode.AmethystOre, 5],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.ClownfishDoll;
code = ItemCode.BpClownfishDoll;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Hachimaki, 1],
		[ItemCode.WheatStraw, 1],
		[ItemCode.WhiteWig, 1],
		[ItemCode.GoldenfishGem, 1],
		[ItemCode.GoldenfishStone, 30],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SpiralGlasses;
code = ItemCode.BpSpiralGlasses;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WhammyHeadStone, 30],
		[ItemCode.WhammyHeadGem, 1],
		[ItemCode.Glasses, 1],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.RabbitEarsHeadband;
code = ItemCode.BpRabbitEarsHeadband;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WhiteWig, 2],
		[ItemCode.GarnetOre, 2],
		[ItemCode.Money, 3000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.WheatStraw;
code = ItemCode.BpWheatStraw;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Moss, 1],
		[ItemCode.Quill, 1],
		[ItemCode.CrocodileStone, 100],
		[ItemCode.AxolotlStone, 50],
		[ItemCode.Score3Rock, 10],
		[ItemCode.Money, 3000],
	],
	bpUsed: 5,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.ScarX;
code = ItemCode.BpScarX;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Scar1, 2],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Warpaint1;
code = ItemCode.BpWarpaint1;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MegalodonStone, 100],
		[ItemCode.MegalodonGem, 5],
		[ItemCode.SharkOrb, 20],
		[ItemCode.GarnetOre, 10],
		[ItemCode.Money, 5000],
	],
	bpUsed: 7,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Tuna;
code = ItemCode.BpTuna;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.HumpbackWhaleStone, 100],
		[ItemCode.HumpbackWhaleGem, 5],
		[ItemCode.AquamarineOre, 25],
		[ItemCode.IronOre, 100],
		[ItemCode.SapphireOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BikerSunglasses;
code = ItemCode.BpBikerSunglasses;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Glasses, 2],
		[ItemCode.Ink, 1],
		[ItemCode.MegalodonStone, 50],
		[ItemCode.MegalodonGem, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BalloonRed;
code = ItemCode.BpBalloonRed;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.LargeBubble, 1],
		[ItemCode.Ghost, 1],
		[ItemCode.PufferfishOrb, 10],
		[ItemCode.OnifuguStone, 50],
		[ItemCode.OnifuguGem, 5],
		[ItemCode.TakoGem, 5],
		[ItemCode.RubyOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.ChefKnife;
code = ItemCode.BpChefKnife;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.KnifefishOrb, 50],
		[ItemCode.ElectricEelStone, 20],
		[ItemCode.ElectricEelGem, 1],
		[ItemCode.BlueWhaleGem, 5],
		[ItemCode.Money, 5000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.StageLight;
code = ItemCode.BpStageLight;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Bling, 1],
		[ItemCode.UnicornHorn, 1],
		[ItemCode.ElectricRayStone, 100],
		[ItemCode.ElectricRayGem, 5],
		[ItemCode.DiamondOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.RainbowAfro;
code = ItemCode.BpRainbowAfro;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WhiteWig, 2],
		[ItemCode.MantisShrimpStone, 100],
		[ItemCode.MantisShrimpGem, 5],
		[ItemCode.RubyOre, 2],
		[ItemCode.SapphireOre, 2],
		[ItemCode.EmeraldOre, 2],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SquirtingBlood;
code = ItemCode.BpSquirtingBlood;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Fountain, 1],
		[ItemCode.Warpaint1, 1],
		[ItemCode.VampireSquidStone, 50],
		[ItemCode.VampireSquidGem, 1],
		[ItemCode.RubyOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.IncognitoMask;
code = ItemCode.BpIncognitoMask;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Eyepatch, 2],
		[ItemCode.BatRayStone, 100],
		[ItemCode.BatRayGem, 5],
		[ItemCode.CuttlefishStone, 100],
		[ItemCode.CuttlefishGem, 5],
		[ItemCode.StingrayGem, 3],
		[ItemCode.Money, 5000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Staff;
code = ItemCode.BpStaff;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.SkewerStick, 1],
		[ItemCode.DiamondOre, 1],
		[ItemCode.SerialKillerWhaleGem, 5],
		[ItemCode.SerialKillerWhaleStone, 100],
		[ItemCode.DolphinOrb, 50],
		[ItemCode.TurquoiseOre, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SailingShip;
code = ItemCode.BpSailingShip;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Hachimaki, 6],
		[ItemCode.CoconutTree, 3],
		[ItemCode.SailfishStone, 100],
		[ItemCode.SailfishGem, 5],
		[ItemCode.KrakenGem, 5],
		[ItemCode.KrakenStone, 100],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Scissors;
code = ItemCode.BpScissors;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BlueCrabStone, 100],
		[ItemCode.BlueCrabGem, 5],
		[ItemCode.CrustaceanOrb, 20],
		[ItemCode.EmeraldOre, 1],
		[ItemCode.IronOre, 100],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Speaker;
code = ItemCode.BpSpeaker;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MusicNote, 2],
		[ItemCode.Box, 1],
		[ItemCode.ElectricEelStone, 30],
		[ItemCode.ElectricRayStone, 30],
		[ItemCode.ElectricEelGem, 2],
		[ItemCode.AmethystOre, 30],
		[ItemCode.AquamarineOre, 30],
		[ItemCode.Money, 5000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Ukulele;
code = ItemCode.BpUkulele;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.CoconutTree, 1],
		[ItemCode.MusicNote, 1],
		[ItemCode.Box, 1],
		[ItemCode.String, 4],
		[ItemCode.MantraRayGem, 5],
		[ItemCode.MantraRayStone, 100],
		[ItemCode.CitrineOre, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.TeddyBear;
code = ItemCode.BpTeddyBear;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.ClownfishDoll, 1],
		[ItemCode.AmphibianOrb, 20],
		[ItemCode.SakuraShrimpGem, 10],
		[ItemCode.AxolotlStone, 100],
		[ItemCode.AxolotlGem, 5],
		[ItemCode.GarnetOre, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.SpearGun;
code = ItemCode.BpSpearGun;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.HydroTank, 1],
		[ItemCode.ChefKnife, 1],
		[ItemCode.StingrayGem, 5],
		[ItemCode.LapisLazuliOre, 100],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Tattoo;
code = ItemCode.BpTattoo;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Ink, 1],
		[ItemCode.FishSkeleton, 1],
		[ItemCode.MosasaurusStone, 100],
		[ItemCode.SharkOrb, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Warpaint2;
code = ItemCode.BpWarpaint2;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Warpaint1, 2],
		[ItemCode.Money, 5000],
	],
	bpUsed: 2,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Monocle;
code = ItemCode.BpMonocle;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Eyepatch, 1],
		[ItemCode.Glasses, 1],
		[ItemCode.SerialKillerWhaleGem, 5],
		[ItemCode.SerialKillerWhaleStone, 100],
		[ItemCode.Money, 5000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BalloonYellow;
code = ItemCode.BpBalloonYellow;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.LargeBubble, 1],
		[ItemCode.Ghost, 1],
		[ItemCode.PufferfishOrb, 10],
		[ItemCode.PorcupinefishGem, 5],
		[ItemCode.PorcupinefishStone, 50],
		[ItemCode.DumboOctopusGem, 5],
		[ItemCode.GoldOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.OperaGlasses;
code = ItemCode.BpOperaGlasses;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BikerSunglasses, 1],
		[ItemCode.SkewerStick, 1],
		[ItemCode.JomamaGem, 5],
		[ItemCode.JomamaStone, 100],
		[ItemCode.GoldOre, 1],
		[ItemCode.CitrineOre, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Katana;
code = ItemCode.BpKatana;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.ChefKnife, 1],
		[ItemCode.OnifuguGem, 1],
		[ItemCode.OnifuguStone, 50],
		[ItemCode.SailfishGem, 1],
		[ItemCode.SailfishStone, 50],
		[ItemCode.IronOre, 100],
		[ItemCode.CitrineOre, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Headphone;
code = ItemCode.BpHeadphone;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Speaker, 1],
		[ItemCode.Handcuffs, 1],
		[ItemCode.BlueSharkGem, 10],
		[ItemCode.BlueSharkStone, 100],
		[ItemCode.SapphireOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BunnyToy;
code = ItemCode.BpBunnyToy;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.ClownfishDoll, 1],
		[ItemCode.ColossalSquidGem, 5],
		[ItemCode.ColossalSquidStone, 100],
		[ItemCode.GulperEelGem, 10],
		[ItemCode.AmethystOre, 50],
		[ItemCode.RabbitEarsHeadband, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Smartphone;
code = ItemCode.BpSmartphone;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Battery, 1],
		[ItemCode.Clock, 1],
		[ItemCode.Book, 1],
		[ItemCode.ElectricRayGem, 10],
		[ItemCode.BlueRayStone, 100],
		[ItemCode.Apple, 1],
		[ItemCode.TurquoiseOre, 50],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.MenacingEffect;
code = ItemCode.BpMenacingEffect;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.MusicNote, 1],
		[ItemCode.KrakenGem, 1],
		[ItemCode.KrakenStone, 100],
		[ItemCode.MegalodonStone, 100],
		[ItemCode.MosasaurusStone, 100],
		[ItemCode.MosasaurusGem, 1],
		[ItemCode.MegalodonGem, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Warpaint4;
code = ItemCode.BpWarpaint4;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Warpaint2, 2],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.FlowerBouquet;
code = ItemCode.BpFlowerBouquet;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.WhiteFlower, 3],
		[ItemCode.DiamondfishGem, 3],
		[ItemCode.RubyOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.BalloonGreen;
code = ItemCode.BpBalloonGreen;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.LargeBubble, 1],
		[ItemCode.Ghost, 1],
		[ItemCode.PufferfishOrb, 10],
		[ItemCode.PufferfishStone, 50],
		[ItemCode.PufferfishGem, 5],
		[ItemCode.KrakenGem, 5],
		[ItemCode.EmeraldOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.CarWheel;
code = ItemCode.BpCarWheel;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Wheel, 1],
		[ItemCode.PlasticBag, 1],
		[ItemCode.SpinnerDolphinGem, 2],
		[ItemCode.TurquoiseOre, 10],
		[ItemCode.Money, 5000],
	],
	bpUsed: 4,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.PocketWatch;
code = ItemCode.BpPocketWatch;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Clock, 1],
		[ItemCode.Monocle, 1],
		[ItemCode.SapphireOre, 1],
		[ItemCode.AmphibianOrb, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.FishSkeleton;
code = ItemCode.BpFishSkeleton;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Tuna, 1],
		[ItemCode.Bone, 1],
		[ItemCode.SwordfishGem, 5],
		[ItemCode.Money, 5000],
	],
	bpUsed: 1,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.AviatorSunglasses;
code = ItemCode.BpAviatorSunglasses;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BikerSunglasses, 1],
		[ItemCode.BlueRayGem, 1],
		[ItemCode.BlueRayStone, 100],
		[ItemCode.MantaRayGem, 1],
		[ItemCode.MantaRayStone, 30],
		[ItemCode.SapphireOre, 1],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Watch;
code = ItemCode.BpWatch;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Clock, 1],
		[ItemCode.Wristband, 1],
		[ItemCode.LeatherbackStone, 30],
		[ItemCode.EmeraldOre, 1],
		[ItemCode.AquamarineOre, 20],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Skull;
code = ItemCode.BpSkull;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Bone, 1],
		[ItemCode.Ghost, 1],
		[ItemCode.VampireSquidGem, 5],
		[ItemCode.VampireSquidStone, 100],
		[ItemCode.Money, 5000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Balloons;
code = ItemCode.BpBalloons;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.BalloonGreen, 1],
		[ItemCode.BalloonYellow, 1],
		[ItemCode.BalloonRed, 1],
		[ItemCode.MantisShrimpStone, 100],
		[ItemCode.MantisShrimpGem, 5],
		[ItemCode.DolphinOrb, 50],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.MasqueradeMask;
code = ItemCode.BpMasqueradeMask;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.IncognitoMask, 2],
		[ItemCode.SpinnerDolphinGem, 10],
		[ItemCode.SpinnerDolphinStone, 200],
		[ItemCode.MarlinGem, 10],
		[ItemCode.MarlinStone, 200],
		[ItemCode.SapphireOre, 5],
		[ItemCode.DiamondOre, 3],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};

result = ItemCode.Skateboard;
code = ItemCode.BpSkateboard;
blueprintInfos[code] = {
	code,
	result,
	recipe: [
		[ItemCode.Card, 1],
		[ItemCode.CarWheel, 4],
		[ItemCode.CoconutTree, 1],
		[ItemCode.KillerWhaleGem, 10],
		[ItemCode.KillerWhaleStone, 100],
		[ItemCode.SapphireOre, 5],
		[ItemCode.Money, 10000],
	],
	bpUsed: 0,
	timeLimited: false,
	isReward: false,
};
