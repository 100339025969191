







































import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { levelRequirements, maxExp } from '@/game/infos/levelInfos';
import { addComma } from '@/util/number';
import globalx from '@/store/modules/globalx';
import { ItemCode } from '@/game/infos/itemInfos';
import BlackPearlField from '@/components/gacha/poq/BlackPearlField.vue';

@Component({
  components: {
    BlackPearlField,
  },
})
export default class UserBar extends Vue {
  public baseUrl = process.env.BASE_URL;
  public get loginStatus() {
    return userx.loginStatus;
  }
  public get userName() {
    const loginStatus = userx.loginStatus;
    const userDocsReady = globalx.userDocsReady;

    return userDocsReady && loginStatus === 'logged in'
      ? userx.userDoc.name
      : loginStatus === 'logged in'
      ? 'Loading'
      : 'Login';
  }
  public get money() {
    return addComma(userx.inventory.money);
  }
  public get gem() {
    return addComma(userx.inventory.availables[ItemCode.Gem]);
  }
  public get lvl() {
    return userx.userDoc.lvl;
  }

  public get maxLvl() {
    return this.lvl === levelRequirements.length + 1;
  }

  public expRequirement: number = 0;
  public exp: number = 0;

  public get barStyle() {
    let expLeft = userx.userDoc.exp;
    if (expLeft === maxExp) {
      return { width: '100%' };
    }
    let expReq = levelRequirements[0];
    for (const req of levelRequirements) {
      if (expLeft < req) {
        expReq = req;
        break;
      }
      expLeft -= req;
    }
    const progress = Math.max((expLeft / expReq) * 100, 2);
    this.expRequirement = expReq;
    this.exp = expLeft;
    return { width: `${progress}%` };
  }
}
