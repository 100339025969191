








































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import userx from '@/store/modules/userx';
import fb from '@/store/sf-firestore';
import { EditState } from '@/store/models.def';
import StateButton from '@/components/inputs/StateButton.vue';
import Radix36Input from '@/components/inputs/Radix36Input.vue';

@Component({
  components: {
    StateButton,
  },
})
export default class MustLoginModal extends Vue {
  @Prop({ default: false }) public value!: boolean;
  public guestLoginState: EditState = 'active';

  public get guestLoginLabel() {
    const labels = {
      active: 'Continue As Guest',
      waiting: 'Continue As Guest',
      sending: 'Logging in...',
      done: 'Logged in',
    };
    return labels[this.guestLoginState];
  }

  public get titleHtml() {
    return `🦄🎊 Welcome 🎉🦈`;
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }

  public async onGuestLogin() {
    this.guestLoginState = 'waiting';
    const value = await this.$bvModal.msgBoxConfirm(
      'Your data can be permanent lost. You can bind your account with email or social media account in the user panel later.',
      {
        title: `Continue As Guest`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'secondary',
        cancelVariant: 'link btn-link-warning',
        modalClass: 'funny-modal',
        okTitle: 'I understand',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      },
    );
    if (value) {
      try {
        this.guestLoginState = 'sending';
        await userx.signInAnonymously();
        this.guestLoginState = 'done';
      } catch (error) {
        this.showError(error);
      }
    } else {
      this.guestLoginState = 'active';
    }
  }
}
