




















import { ServerData } from '@/game/infos/serverInfos';
import { ServerList } from '@/store/api/serverList';
import { ServerTime } from '@/store/api/serverTime';
import { Global } from '@/store/globalz';
import globalx from '@/store/modules/globalx';
import { Dictionary } from '@/util/dictionary';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';


@Component({
	components: {},
})
export default class ServerDropDown extends Vue {

	public get myServer() {
		return globalx.selectedServer || globalx.recommendedServer;
	}
	public get myServerName() {
		if (!this.myServer) {
			return 'Region';
		}
		const split = this.myServer.name.split('|');
		return 'Region: ' + split[1];
	}

	public get fastestContinent() {
		const fastestContinent: string[] = [];
		for (const server of globalx.fastestServers) {
			if (
				(server.armageddonStartTime &&
					this.time > server.armageddonStartTime) ||
				(server.closeTime && this.time > server.closeTime - 60000)
			) {
				continue;
			}
			const continent = server.name.split('|')[0];
			if (fastestContinent.indexOf(continent) === -1) {
				fastestContinent.push(continent);
				this.servers[continent] = [];
			}
			this.servers[continent].push(server);
		}
		for (const continent in this.servers) {
			if (Object.prototype.hasOwnProperty.call(this.servers, continent)) {
				const servers = this.servers[continent];
				servers.sort((a, b) => {
					if (!a.ping || a.ping === -1) {
						return 1;
					}
					if (!b.ping || b.ping === -1) {
						return -1;
					}
					return a.ping - b.ping;
				});
			}
		}
		return fastestContinent;
	}
	public time = Date.now();
	public interval: any;
	public lastRefreshTime = 0;

	public servers: Dictionary<ServerData[]> = {};

	public mounted() {
		this.onRefresh();

		this.interval = setInterval(() => {
			this.time = ServerTime.time;
		}, 1000);
	}
	public beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	public async onRefresh() {
		const ct = Date.now();
		if (globalx.previousPing >= ct - 3000 || ct < this.lastRefreshTime) {
			return;
		}
		this.lastRefreshTime = ct + 10000;
		try {
			await ServerList.sync();
			await ServerList.pingAll();

		} catch (error) {
			this.showError(error);
		}
	}
	public showError(error: any) {
		this.$root.$emit('error', error);
	}
	public get items() {
		return globalx.serverList;
	}


	public onClick(data: ServerData) {
		if (!data.isOffline) {
			globalx.updateSelectedServer(data);
		}
	}
}
