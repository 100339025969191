import { Dictionary } from '@/util/dictionary';
import { sound, Sound } from '@pixi/sound';

const sfxIds = [
	'lose',
	'win',
	'win2',
	'achievement',
	'unlock',
	'unlockStar',
	'gachaAnimation',
	'gachaReveal',
	'wsBadgeBreak',

	'mining',
	'spearHitSpear',
	'spearParried',
	'boostBubbles',
	'damage',
	'damageSelf',
	'stab',
	'corpseToFood',
	'legendaryLoot',
	'swimmingFang',
	'swimmingFangBoom',
	'megaBite',
	'whammyWave',
	'bandage',
	'stun',
	'freeze',
	'burn',
	'bleedNormal',
	'tick',
	'thunder',
	'scoreReveal',
	'rankReveal',
	'splashOut',
	'splashIn',
	'openChest',
	'changeWeapon',
	'buyChaching',
	'polar1',
	'polar2',
	'polar3',
	'polar4',
	'breakBottle',
	'whoosh',
	'teammate',
	'spikeGrow',
	'holySword',
	'diamondHarden',
	'diamondShieldHit',
	'bodySlam',
	'sacrifice1',
	'sacrifice2',
	'electricHazard',
	'electricShock',
	'electricCharge',
	'electricBolt',
	'forceField',
	'tempSafeZone',
	'tips',
	'evolveEffect',
	'divineBlessing',
	'chatFailed',
	'bonk',
	'announcement',
	'bell',
	'siren',
	'wave',
	'dart',
	'dartMulti',
	'crossbow',
	'zellyExplode',
	'poof',
	'fireBullet',
	'sunPunch',
	'decoy',
	'decoyReady',

	'zombieCrunch',

	'mechguinGlassbreak',
	'mechguinExplode',
	'mechguinMetal1',
	'mechguinMetal2',
	'mechguinMetal3',
	'mechguinMissileLoad',
	'mechguinMissileLaunch',
	'mechguinMissileSeparate',
	'mechguinMissilePush',
	'pearlHit',
	'laserBeam',

	'weaponBreak',

] as const;
export type SfxId = typeof sfxIds[number];


export const sfxUrls: { [T in SfxId]: string } = {
	lose: 'audio/bgm/Armageddon_Lose V2_128Kmp3.mp3',
	win: 'audio/bgm/Armageddon_Win_128Kmp3.mp3',
	win2: 'audio/bgm/Stabfish2 Arena Win Music_0915.mp3',
	achievement: 'audio/bgm/Stabfish_Unlock Achievement Sound V3_128K mp3.mp3',
	unlock: 'audio/bgm/NEW_Unlock Music V4_128K mp3.mp3',
	unlockStar: 'audio/sfx/Mining GET.mp3',
	gachaAnimation: 'audio/bgm/Stabfish_Gacha Animation_Audio3_128Kmp3.mp3',
	gachaReveal: 'audio/sfx/Stabfish_Gacha Reveal Sound Efx3_128Kmp3.mp3',
	wsBadgeBreak: 'audio/sfx/WsBadgeBreak.mp3',

	bleedNormal: 'audio/sfx/Loosing Blood - Heart Beat.mp3',

	tick: 'audio/sfx/Tick.mp3',
	spearHitSpear: 'audio/sfx/Fish Sword Hit 1.mp3',
	spearParried: 'audio/sfx/SpearParry.mp3',
	mining: 'audio/sfx/Mining HIT.mp3',
	boostBubbles: 'audio/sfx/Turbo Swim Bubble 1 Fixed.mp3',
	stab: 'audio/sfx/Stab In 3.mp3',
	damage: 'audio/sfx/Fish Body Hit 1.mp3',
	damageSelf: 'audio/sfx/Fish Body Hit 3.mp3',
	corpseToFood: 'audio/sfx/Crush into Salmon 2.mp3',
	swimmingFang: 'audio/sfx/Walking Teeth 3.mp3',
	swimmingFangBoom: 'audio/sfx/Walking Teeth Boom Fixed.mp3',
	megaBite: 'audio/sfx/Croc Bite New.mp3',
	whammyWave: 'audio/sfx/NEW_Wave n Dizzy - Wave Hum Style.mp3',
	bandage: 'audio/sfx/Bandage 2.mp3',
	thunder: 'audio/sfx/Thunder.mp3',
	legendaryLoot: 'audio/sfx/Legendary Item 2.mp3',
	breakBottle: 'audio/sfx/Bottle Break.mp3',
	weaponBreak: 'audio/sfx/WeaponBreak.mp3',
	stun: 'audio/sfx/Wave n Dizzy - Dizzy.mp3',
	freeze: 'audio/sfx/Freeze 2 without Chime.mp3',
	burn: 'audio/sfx/Fire 3.mp3',

	scoreReveal: 'audio/sfx/Score Reveal 2.mp3',
	rankReveal: 'audio/sfx/Rank Reveal.mp3',
	splashOut: 'audio/sfx/Swim In Water Surface 1.mp3',
	splashIn: 'audio/sfx/Swim In Water Surface 2.mp3',
	openChest: 'audio/sfx/Chest Open 2.mp3',
	changeWeapon: 'audio/sfx/Change Weapon.mp3',
	buyChaching: 'audio/sfx/Buying Cha-Ching.mp3',
	polar1: 'audio/sfx/Bear Hmm 1.mp3',
	polar2: 'audio/sfx/Bear Hmm 2.mp3',
	polar3: 'audio/sfx/Bear Hmm 3.mp3',
	polar4: 'audio/sfx/Bear Hmm 4.mp3',
	whoosh: 'audio/sfx/Spin Dodge Moving 2.mp3',
	teammate: 'audio/sfx/Mining GET.mp3',

	spikeGrow: 'audio/sfx/Spike Grow SEfx 2.mp3',
	holySword: 'audio/sfx/Holy Sword SEfx.mp3',
	diamondHarden: 'audio/sfx/DiamondFish DiamondShield_SEfx 1.mp3',
	diamondShieldHit: 'audio/sfx/DiamondShieldHit.mp3',
	bodySlam: 'audio/sfx/Jomama Body Slam_SEfx 1.mp3',
	sacrifice2: 'audio/sfx/Kraken Sacrifice_Sacrifice SEfx 3.mp3',
	sacrifice1: 'audio/sfx/Kraken Sacrifice_PowerUp SEfx 2.mp3',
	electricHazard: 'audio/sfx/ElectricHazard.mp3',
	electricShock: 'audio/sfx/ElectricShock.mp3',
	electricCharge: 'audio/sfx/ElectricCharge.mp3',
	electricBolt: 'audio/sfx/ElectricBolt.mp3',
	forceField: 'audio/sfx/Blue Ray SEfx 3.mp3',
	tempSafeZone: 'audio/sfx/Manta Ray SEfx 3.mp3',

	tips: 'audio/sfx/Tips.mp3',
	evolveEffect: 'audio/sfx/Spear Grow UpLvl 1.mp3',
	divineBlessing: 'audio/sfx/DivineBlessing.mp3',
	chatFailed: 'audio/sfx/Fish Sword Hit 6.mp3',
	bonk: 'audio/sfx/Bonk.mp3',
	announcement: 'audio/sfx/Announcement Efx 1.mp3',
	bell: 'audio/sfx/Bell2.mp3',
	siren: 'audio/sfx/Siren Singing Efx 1 Low_Fixed 3.mp3',

	wave: 'audio/sfx/Wave Efx Slow 3.mp3',
	dart: 'audio/sfx/dartSingle.mp3',
	dartMulti: 'audio/sfx/dartMulti.mp3',
	crossbow: 'audio/sfx/crossbow.mp3',
	zellyExplode: 'audio/sfx/zellyfish.mp3',
	poof: 'audio/sfx/Poof Sound 2.mp3',
	fireBullet: 'audio/sfx/Pistol Shrimp-Fire Bullet Underwater_SEfx 2.mp3',
	sunPunch: 'audio/sfx/Mantis Shrimp-Sun Punch_SEfx 4.mp3',
	decoy: 'audio/sfx/Naruto Boom SEfx 4.mp3',
	decoyReady: 'audio/sfx/Naruto Boom SEfx 5.mp3',
	zombieCrunch: 'audio/sfx/ZombieCrunch.mp3',

	mechguinExplode: 'audio/sfx/MechguinExplode.mp3',
	mechguinGlassbreak: 'audio/sfx/MechguinGlassBreak.mp3',
	mechguinMetal1: 'audio/sfx/MechguinMetal1.mp3',
	mechguinMetal2: 'audio/sfx/MechguinMetal2.mp3',
	mechguinMetal3: 'audio/sfx/MechguinMetal4.mp3',
	pearlHit: 'audio/sfx/One Coin Earning 1.mp3',
	laserBeam: 'audio/sfx/Laser Beam_SEfx.mp3',
	mechguinMissileLoad: 'audio/sfx/Missile SEfx_Part 1.mp3',
	mechguinMissileLaunch: 'audio/sfx/Missile SEfx_Part 2.mp3',
	mechguinMissileSeparate: 'audio/sfx/Missile SEfx_Part 3.mp3',
	mechguinMissilePush: 'audio/sfx/Missile SEfx_Part 4.mp3',
};

const bgmIds = [
	'bgmMainMenu',
	'bgmGame',
	'armageddon',
	'arena',
] as const;
export type BgmId = typeof bgmIds[number];


export const bgmUrls: { [T in BgmId]: string } = {
	bgmMainMenu: 'audio/bgm/Stabfish_Menu_Let_s Get Into Water_128K mp3.mp3',
	bgmGame: 'audio/bgm/NEW_Stabfish_On The Run_V2_128K mp3.mp3',
	armageddon: 'audio/bgm/Stabfish_Map Armageddon_Stay Until The End_128K mp3.mp3',
	arena: 'audio/bgm/Stabfish2 Arena BGM_On Time_V7.1_Loops_128K MP3.mp3',
};

export const sfxs: { [T in SfxId]: Sound } = {} as any;
for (const sfxId in sfxUrls) {
	if (Object.prototype.hasOwnProperty.call(sfxUrls, sfxId)) {
		const url = sfxUrls[sfxId];
		sfxs[sfxId] = sound.add(sfxId, {
			url,
			preload: true,
		});
	}
}
