import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { LocalData } from '@/util/localData';
import { isTouchDevice } from '@/util/isTouchDevice';
// import { Ticker } from '@/util/tweents';
import { isWebGLSupported } from '@pixi/utils';
import { Ticker } from 'pixi.js';
import { Tween } from '@/util/tweents';
import globalx from './globalx';
import { Global } from '../globalz';
import { setDepthFilter } from '@/client/sound/BgmManager';

export interface SettingsData {
	control: 'mouse' | 'touch',
	dpi: number,
	antialias: number,
	frameRate: number,
	detailsLevel: number, // 0-10
	canvasSize: number,
	leftHanded: boolean,
	cameraShakeOff: boolean,

	color1: number,
	color2: number,
	color3: number,
	color4: number,

	whiteOre: number,
	yellowOre: number,
	greenOre: number,
	blueOre: number,
	purpleOre: number,
	redOre: number,

	armageddonFilter: boolean,
	hideEnemyDecos: boolean,
	hideTeamDecos: boolean,
	hideMyDecos: boolean,
	hideEnemySkin: boolean,
	hideTeamSkin: boolean,
	hideMySkin: boolean,
	hideParticles: boolean,
	hideDmgNums: boolean,
	hideShadows: boolean,

	skill1: ActionKey,
	skill2: ActionKey,
	skill3: ActionKey,
	wpnL: ActionKey,
	wpnR: ActionKey,

	news: string,
	newSkin: string,
	readBoss1Shop: boolean,
	readBoss1Dif2: boolean,
	readBoss1Dif3: boolean,

	bgm: number,
	sfx: number,
	sfxMuted: boolean,
	bgmMuted: boolean,
	depthFilter: boolean,

	capStreak: boolean,
}
// LocalData.delete('stabfishLocalSettings');
// const defaultTeamColors = [0x39E700, 0xFF0033, 0xFFCC00, 0x6633CC];
export const actionKeys = [
	'Left-Click', 'Right-Click', 'Space', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'a', 's', 'd',
] as const;
export type ActionKey = typeof actionKeys[number];

@Module({
	namespaced: true,
	name: 'settingx',
	store,
	dynamic: true,
})
class GobiSettingsModule extends VuexModule {

	public get keys() {
		const toCode = (k: ActionKey) => k === 'Space' ? k : 'Key' + k.toUpperCase();
		return {
			skill1: toCode(settingx.now.skill1),
			skill2: toCode(settingx.now.skill2),
			skill3: toCode(settingx.now.skill3),
			wpnL: toCode(settingx.now.wpnL),
			wpnR: toCode(settingx.now.wpnR),
		};
	}
	public get now(): {
		control: 'mouse' | 'touch',
		dpi: number,
		antialias: number,
		frameRate: number,
		detailsLevel: number, // 0-10
		canvasSize: number,
		leftHanded: boolean,
		cameraShakeOff: boolean,

		color1: number,
		color2: number,
		color3: number,
		color4: number,

		whiteOre: number,
		yellowOre: number,
		greenOre: number,
		blueOre: number,
		purpleOre: number,
		redOre: number,

		armageddonFilter: boolean,
		hideEnemyDecos: boolean,
		hideTeamDecos: boolean,
		hideMyDecos: boolean,
		hideEnemySkin: boolean,
		hideTeamSkin: boolean,
		hideMySkin: boolean,
		hideParticles: boolean,
		hideDmgNums: boolean,
		hideShadows: boolean,

		skill1: ActionKey,
		skill2: ActionKey,
		skill3: ActionKey,
		wpnL: ActionKey,
		wpnR: ActionKey,

		news: string,
		newSkin: string,
		readBoss1Shop: boolean,
		readBoss1Dif2: boolean,
		readBoss1Dif3: boolean,

		bgm: number,
		sfx: number,
		sfxMuted: boolean,
		bgmMuted: boolean,
		depthFilter: boolean,
		capStreak: boolean,
	} {
		if (!this._local.color1 === undefined) { this._local.color1 = this._default.local.color1; }
		if (!this._local.color2 === undefined) { this._local.color2 = this._default.local.color2; }
		if (!this._local.color3 === undefined) { this._local.color3 = this._default.local.color3; }
		if (!this._local.color4 === undefined) { this._local.color4 = this._default.local.color4; }
		if (!this._local.skill1) { this._local.skill1 = 'Left-Click'; }
		if (!this._local.skill2) { this._local.skill2 = 'Right-Click'; }
		if (!this._local.skill3) { this._local.skill3 = 'Space'; }
		if (!this._local.news) { this._local.news = '0'; }
		if (!this._local.newSkin) { this._local.newSkin = '0'; }
		if (!this._local.whiteOre === undefined) { this._local.whiteOre = 0xffffff; }
		if (!this._local.yellowOre === undefined) { this._local.yellowOre = 0xffffff; }
		if (!this._local.greenOre === undefined) { this._local.greenOre = 0xffffff; }
		if (!this._local.blueOre === undefined) { this._local.blueOre = 0xffffff; }
		if (!this._local.purpleOre === undefined) { this._local.purpleOre = 0xffffff; }
		if (!this._local.redOre === undefined) { this._local.redOre = 0xffffff; }
		if (!this._local.bgm === undefined) { this._local.bgm = .2; }
		if (!this._local.sfx === undefined) { this._local.sfx = 1; }
		if (!this._local.armageddonFilter === undefined) { this._local.armageddonFilter = false; }
		if (!this._local.depthFilter === undefined) { this._local.depthFilter = !isTouchDevice; }
		if (!this._local.wpnL) { this._local.wpnL = 'a'; }
		if (!this._local.wpnR) { this._local.wpnR = 's'; }

		return { ...this._local, ...this._server };
	}

	public isWebGLSupported = isWebGLSupported();
	public sidebarShown = false;
	private readonly _default = {
		local: {
			control: (isTouchDevice ? 'touch' : 'mouse') as 'touch' | 'mouse',
			// dpi: Math.min(screen.width, screen.height) >= 768 ? 1 : 1 + (window.devicePixelRatio - 1) * 0.5,
			dpi: Math.min(window.devicePixelRatio, 2),
			antialias: 1,
			frameRate: 60,
			detailsLevel: 10, // 0-10
			canvasSize: 1,
			leftHanded: false,
			cameraShakeOff: false,

			color1: 0x39E700,
			color2: 0xFF0033,
			color3: 0xFFCC00,
			color4: 0x6633CC,

			whiteOre: 0xffffff,
			yellowOre: 0xffffff,
			greenOre: 0xffffff,
			blueOre: 0xffffff,
			purpleOre: 0xffffff,
			redOre: 0xffffff,

			armageddonFilter: false,
			hideEnemyDecos: false,
			hideTeamDecos: false,
			hideMyDecos: false,
			hideEnemySkin: false,
			hideTeamSkin: false,
			hideMySkin: false,
			hideParticles: false,
			hideDmgNums: false,
			hideShadows: false,

			skill1: 'Left-Click' as ActionKey,
			skill2: 'Right-Click' as ActionKey,
			skill3: 'Space' as ActionKey,
			wpnL: 'a' as ActionKey,
			wpnR: 's' as ActionKey,

			news: '',
			newSkin: '',
			readBoss1Shop: false,
			readBoss1Dif2: false,
			readBoss1Dif3: false,

			sfx: 1,
			bgm: .2,
			sfxMuted: false,
			bgmMuted: false,
			depthFilter: !isTouchDevice,
			capStreak: false,
		},
		server: {
		},
	};

	private _local = { ...this._default.local, ...LocalData.load('stabfishLocalSettings') } || { ...this._default.local };
	private _server = { ...this._default.server, ...LocalData.load('stabfishServerSettings') } || { ...this._default.server };

	@Action
	public updateSettings(settings: Partial<SettingsData>) {
		this.m_updateSettings(settings);
	}
	@Action
	public backToDefault(target: 'local' | 'server') {
		switch (target) {
			case 'local':
				this._default.local.news = this.now.news;
				this._default.local.newSkin = this.now.newSkin;
				this.updateSettings(this._default.local);
				break;
			case 'server':
				this.updateSettings(this._default.server);
				break;
		}
	}
	@Action
	public defaultColor() {
		this.updateSettings({
			whiteOre: 0xffffff,
			yellowOre: 0xffffff,
			greenOre: 0xffffff,
			redOre: 0xffffff,
			purpleOre: 0xffffff,
			blueOre: 0xffffff,
			color1: this._default.local.color1,
			color2: this._default.local.color2,
			color3: this._default.local.color3,
			color4: this._default.local.color4,
		});
	}
	@Action
	public read(id: string) {
		const s: any = {};
		s[id] = true;
		this.m_updateSettings(s);
	}
	@Action
	public readNews() {
		this.m_updateSettings({ news: globalx.latestNews });
	}
	@Action
	public readNewSkin() {
		this.m_updateSettings({ newSkin: globalx.latestNewSkin });
	}
	@Action
	public show() {
		this.m_updateSidebarShown(true);
	}
	@Action
	public hide() {
		this.m_updateSidebarShown(false);
	}

	@Mutation
	private m_updateSettings(settings: Partial<SettingsData>) {
		const localSettings = Object.keys(this._default.local);
		const serverSettings = Object.keys(this._default.server);

		let localDirty = false;
		let serverDirty = false;

		for (const key in settings) {
			if (settings.hasOwnProperty(key)) {
				if (serverSettings.includes(key)) {
					this._server[key] = settings[key];
					serverDirty = true;
					// TODO: save to server
				} else if (localSettings.includes(key)) {
					this._local[key] = settings[key];
					localDirty = true;
				}
			}
		}
		if (localDirty) {
			LocalData.save('stabfishLocalSettings', this._local);

			Global.bgmSoundGroup.volume = this._local.bgm;
			Global.bgmSoundGroup.muted = this._local.bgmMuted;
			Global.sfxSoundGroup.volume = this._local.sfx;
			Global.sfxSoundGroup.muted = this._local.sfxMuted;
			setDepthFilter(this._local.depthFilter);
		}
		if (serverDirty) {
			// TODO: save to server
		}

		Ticker.shared.maxFPS = this._local.frameRate;
	}
	@Mutation
	private m_updateSidebarShown(visible: boolean) {
		this.sidebarShown = visible;
	}

}
const settingx = getModule(GobiSettingsModule);
export default settingx;

// Ticker.framerate = 60;
let lastTouch = 0;
document.body.addEventListener('touchstart', () => {
	const ct = Date.now();
	lastTouch = ct;
	if (settingx.now.control !== 'touch') {
		settingx.updateSettings({ control: 'touch' });
	}
});
document.body.addEventListener('mousemove', () => {
	const ct = Date.now();
	if (settingx.now.control !== 'mouse' && ct - lastTouch > 500) {
		settingx.updateSettings({ control: 'mouse' });
	}
});
Ticker.shared.add((tickerDeltaTime: number) => {
	// console.log(tickerDeltaTime / PIXI.settings.TARGET_FPMS);
	Tween.tick(Ticker.shared.elapsedMS, false);
});
