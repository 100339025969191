import { EventType } from '@/game/infos/eventType';
import { FishType } from '@/game/infos/fishInfos';
import { HazardType } from '@/game/infos/hazardInfos';
import { ItemCode } from '@/game/infos/itemInfos';
import { OreColor } from '@/game/infos/oreInfo';
import { Preset } from '@/game/infos/preset';
import { NormalSkill, PassiveSkill, SpecialSkill } from '@/game/infos/skills';
import { TipId } from '@/game/infos/tipInfos';
import { NodeData, PartialMapData, SecretRoomData } from '@/game/multithread/roomMap';
import { BroadcastBonkEvent, BroadcastBuffEvent, BroadcastCorpseToFoodEvent, BroadcastDamageEvent, BroadcastEmojiEvent, BroadcastJobuffEvent, BroadcastLootEvent, BroadcastPublicChestEvent, BroadcastSpearHitBottleEvent, BroadcastSpearHitOreEvent, BroadcastSpearHitSpearEvent, BroadcastStabEvent, BroadcastSwordBreakEvent, BroadcastTailEvent, DamageTargetType, DamageType, PlayerGameState } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import store from '@/store';
import { Global } from '@/store/globalz';
import settingx, { SettingsData } from '@/store/modules/settingx';
import tipx from '@/store/modules/tipx';
import Collision from '@/util/collide';
import { Dictionary } from '@/util/dictionary';
import { getBoolean } from '@/util/number';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { BLEND_MODES, Container, filters, Graphics, ParticleContainer, Sprite, Texture, TilingSprite, Rectangle } from 'pixi.js';
import Factory from '../factory';
import { ClickFinger, PublicChest } from '../factory/assets/publiChestMixin';
import { SafeZoneSymbol } from '../factory/assets/safeZoneMixin';
import { SecretTemple } from '../factory/assets/secretTemple';
import { getMiscSymbol, MiscSymbol } from '../factory/assets/skills/miscSymbol';
import { disposeWallSprite, getWallSprite, MapAsset, WallSprite } from '../factory/mapAssets';
import { disposeParticle, getParticle, Particle } from '../factory/particles';
import { BgmManager } from '../sound/BgmManager';
import { SoundEfx } from '../sound/SoundEfx';
import { SfxId } from '../sound/soundInfos';
import { BottleControl } from './bottleControl';
import { CameraControl } from './cameraControl';
import { CutEffectControl } from './effects/cutEfxControl';
import { DamageNumberControl } from './effects/damageNumberControl';
import { LootEffectControl } from './effects/lootEfxControl';
import { MiningSparkleEffectControl } from './effects/miningSparkleEfxControl';
import { SpearCollisionEffectControl } from './effects/spearCollisionEfxControl';
import { SplashEffectControl } from './effects/splashEfxControl';
import { EmojiBubbleContainer } from './emojiBubbleControl';
import { FoodControl } from './foodControl';
import { HazardControl } from './hazardControl';
import { OreControl } from './oreControl';
import { PlayerControl } from './playerControl';
import { OuroborosSymbol, SirenSymbol, WhirlpoolSymbol } from '../factory/assets/npcAssets';
import { LongSoundEfx } from '../sound/LongSoundEfx';
import { Iceberg } from '../factory/assets/boss/xmasPenguin/xmasPenguinAssets';
import { StolenNumberControl } from './effects/stolenNumberControl';
import { EmojiType } from '@/game/infos/emojiInfos';
import { DojoSymbol } from '../factory/assets/dojoSymbol';
import { LootParticleEffectControl } from './effects/lootParticleEfxControl';
import { skinGroups } from '../factory/assets/skinAssets';
import { wait } from '@/util/wait';
import gamex from '@/store/modules/gamex';

const poisonTexture = 'images/armageddonPoison.png';

function createPoisonTile(x: number, y: number, w: number, h: number) {
	const tile = new TilingSprite(Texture.from(poisonTexture), w, h);
	tile.x = x;
	tile.y = y;
	return tile;
}

function getTint(oreColor: OreColor) {
	return oreColor === OreColor.White ? settingx.now.whiteOre
		: oreColor === OreColor.Yellow ? settingx.now.yellowOre
			: oreColor === OreColor.Green ? settingx.now.greenOre
				: oreColor === OreColor.Blue ? settingx.now.blueOre
					: oreColor === OreColor.Purple ? settingx.now.purpleOre
						: settingx.now.redOre;
}
export class RoomControl {


	public symbol: Container = new Container();
	public emojiContainer = new Container();
	public playerTopContainer = new Container();
	public playerBottomContainer = new Container();
	public wallBottomContainer = new Container();
	public playersContainer = new Container();
	public playerLabelsContainer = new Container();
	public bossContainer = new Container();
	public shadowsContainer = new ParticleContainer(200, { vertices: true, position: true, rotation: false, tint: false });
	public foodsContainer = new ParticleContainer(10000, { vertices: false, position: true, rotation: false, tint: false });
	public effectsContainer = new ParticleContainer(100, { vertices: true, position: true, rotation: true, tint: false });
	public oresContainer = new ParticleContainer(1000, { vertices: false, position: true, rotation: false, tint: false });
	public wallsContainer = new ParticleContainer(10000, { vertices: false, position: false, rotation: false, tint: false });
	public backParticlesContainer = new ParticleContainer(200, { vertices: false, position: false, rotation: false, tint: true });
	public snowParticlesContainer = new ParticleContainer(200, { vertices: false, position: true, rotation: true, tint: true });
	public armageddonLayer = new Graphics();
	public closedContainer = new Container();
	public npcContainer = new Container();
	public blindScreen = Global.blindScreen;
	public blocks = new Graphics();
	public debug = new Graphics();

	public safeZoneSymbol = Factory.get(SafeZoneSymbol);
	public secretTemple = new SecretTemple();
	public dojo = new DojoSymbol();


	public state: ViewState | null = null;

	public allPlayers: Dictionary<PlayerControl> = {};
	public visiblePlayers: Dictionary<PlayerControl> = {};

	public visibleMiscs: Dictionary<MiscSymbol> = {};
	public allMiscs: Dictionary<MiscSymbol> = {};

	public visibleFoods: Dictionary<FoodControl> = {};
	public visibleOres: Dictionary<OreControl> = {};
	public visibleBottles: Dictionary<BottleControl> = {};

	public visibleHazards: Dictionary<HazardControl> = {};
	public allHazards: Dictionary<HazardControl> = {};

	public emojiBubbles: Dictionary<EmojiBubbleContainer> = {};

	public cameraControl: { swing: (a: number, d: number, l?: boolean) => void; };

	public secretRoomData: SecretRoomData | null = null;
	public isInSecretRoom = false;
	public strongArmingVid = 0;

	protected skyBg: TilingSprite;
	protected closingPoison: TilingSprite;
	protected secretTempleCover = new Graphics();
	protected bg: Sprite;
	protected width = 0;
	protected height = 0;

	protected mapData!: PartialMapData;
	protected closedSectors = 0;
	protected fullClosedSectors = 0;

	protected graphicSettings = { ...settingx.now };

	protected boostBubbleFlag = true;

	protected publicChest = Factory.get(PublicChest);
	protected publicChestPointer = Factory.get(ClickFinger);

	protected armageddonTween = Tween.get(this.armageddonLayer, { paused: true, loop: -1 })
		.to({ alpha: 0 }, 0)
		.to({ alpha: 1 }, 1000)
		.to({ alpha: 0 }, 1000);

	protected safeZoneX = 0;
	protected whirlpool = Factory.get(WhirlpoolSymbol).children[0] as Sprite;
	protected ouroboros1 = Factory.get(OuroborosSymbol);
	protected ouroboros2 = Factory.get(OuroborosSymbol);
	protected siren = Factory.get(SirenSymbol);
	protected sirenSong = new LongSoundEfx('siren');
	protected showingOuroboros = false;
	protected pvpEmojiCount = 6;
	protected toPvpEmoji = false;
	protected lastInPvp = false;

	constructor(cameraControl: { swing: (a: number, d: number, l?: boolean) => void; }) {
		this.cameraControl = cameraControl;
		const canvas = document.createElement('canvas');
		canvas.width = 1000;
		canvas.height = 1000;
		const ctx = canvas.getContext('2d')!;
		const gradient = ctx.createLinearGradient(0, 0, 0, 1000);
		gradient.addColorStop(0, '#60dbfd');
		// gradient.addColorStop(0.248, '#263E74');
		// gradient.addColorStop(0.253, '#22336a');
		gradient.addColorStop(0.248, '#22336a');
		gradient.addColorStop(0.253, '#213167');
		gradient.addColorStop(1, '#011032');
		ctx.fillStyle = gradient;
		ctx.fillRect(0, 0, 1000, 1000);
		this.bg = new Sprite(Texture.from(canvas));
		this.skyBg = new TilingSprite(Texture.from('images/skybg.jpg?v=2'), 2047 * 6, 1022);
		this.closingPoison = createPoisonTile(0, 0, 256, 256);
		this.closedContainer.filters = !settingx.now.armageddonFilter ? [] : [new filters.BlurFilter()];
		this.wallsContainer.filterArea = new Rectangle(0, 0, 1000, 1000);
		this.whirlpool.anchor.set(.5, .5);
		this.whirlpool.scale.set(2.5);
		// Preset.renderer = this.debug;

		this.symbol.addChild(
			this.bg,
			this.shadowsContainer,
			this.skyBg,
			this.dojo,
			this.npcContainer,
			this.snowParticlesContainer,
			this.backParticlesContainer,
			this.wallBottomContainer,
			this.blocks,
			this.wallsContainer,
			this.foodsContainer,
			this.playerBottomContainer,
			this.playersContainer,
			this.oresContainer,
			this.bossContainer,
			this.playerTopContainer,
			this.effectsContainer,
			this.closedContainer,
			this.playerLabelsContainer,
			this.emojiContainer,
			this.debug,
			this.armageddonLayer,
			this.blindScreen,
		);
		this.skyBg.y = -1022;
		this.dojo.y = Preset.DOJO_Y;
		this.dojo.visible = false;

		this.armageddonLayer.blendMode = !settingx.now.armageddonFilter ? BLEND_MODES.NORMAL : BLEND_MODES.ADD;

		const sectorSize = 2047;
		this.secretTempleCover.beginFill(0);
		this.secretTempleCover.drawRect(-sectorSize / 2, -sectorSize / 2, sectorSize, sectorSize);


		store.watch((states, getters) => getters['settingx/now'],
			(newValue: SettingsData, oldValue: SettingsData) => {
				if (this.graphicSettings.hideMyDecos !== newValue.hideMyDecos) {
					if (this.state) {
						const player = this.allPlayers[this.state.pid];
						if (player) {
							if (newValue.hideMyDecos) {
								player.hideDecos();
							} else {
								player.showDecos();
							}
						}
					}
				}
				if (this.graphicSettings.hideEnemyDecos !== newValue.hideEnemyDecos) {
					for (const pid in this.allPlayers) {
						if (Object.prototype.hasOwnProperty.call(this.allPlayers, pid)) {
							const player = this.allPlayers[pid];
							if (player.alliance === 'enemy') {
								if (newValue.hideEnemyDecos) {
									player.hideDecos();
								} else {
									player.showDecos();
								}
							}
						}
					}
				}
				if (this.graphicSettings.hideTeamDecos !== newValue.hideTeamDecos) {
					if (this.state) {
						for (const pid of this.state.team) {
							const player = this.allPlayers[pid];
							if (player && player.alliance !== 'me') {
								if (newValue.hideMyDecos) {
									player.hideDecos();
								} else {
									player.showDecos();
								}
							}
						}
					}

				}
				if (this.graphicSettings.hideMySkin !== newValue.hideMySkin) {
					if (this.state) {
						const player = this.allPlayers[this.state.pid];
						if (player.data) {
							player.toShowDecos = !newValue.hideMySkin;
							player.symbol.setFishType(player.data.fishType, player.toShowDecos ? player.data.skinGroup : 0);
						}
					}
				}
				if (this.graphicSettings.hideEnemySkin !== newValue.hideEnemySkin) {
					for (const pid in this.allPlayers) {
						if (Object.prototype.hasOwnProperty.call(this.allPlayers, pid)) {
							const player = this.allPlayers[pid];
							if (player.alliance === 'enemy') {
								if (player.data) {
									player.toShowDecos = !newValue.hideEnemySkin;
									player.symbol.setFishType(player.data.fishType, player.toShowDecos ? player.data.skinGroup : 0);
								}
							}
						}
					}
				}
				if (this.graphicSettings.hideTeamSkin !== newValue.hideTeamSkin) {
					if (this.state) {
						for (const pid of this.state.team) {
							const player = this.allPlayers[pid];
							if (player && player.alliance !== 'me') {
								if (player.data) {
									player.toShowDecos = !newValue.hideTeamSkin;
									player.symbol.setFishType(player.data.fishType, player.toShowDecos ? player.data.skinGroup : 0);
								}
							}
						}
					}
				}
				if (this.graphicSettings.hideShadows !== newValue.hideShadows) {
					if (newValue.hideShadows) {
						this.shadowsContainer.removeChildren();
					} else {
						for (const pid in this.visiblePlayers) {
							if (Object.prototype.hasOwnProperty.call(this.visiblePlayers, pid)) {
								const player = this.visiblePlayers[pid];
								this.shadowsContainer.addChild(player.shadow);
							}
						}
					}
				}
				if (this.graphicSettings.armageddonFilter !== newValue.armageddonFilter) {
					this.closedContainer.filters = [];
					if (newValue.armageddonFilter) {
						this.closedContainer.filters.push(new filters.BlurFilter());
					}
					this.armageddonLayer.blendMode = !settingx.now.armageddonFilter ? BLEND_MODES.NORMAL : BLEND_MODES.ADD;
				}
				if (this.graphicSettings.whiteOre !== newValue.whiteOre) {
					this.updateOreColor(OreColor.White, newValue.whiteOre);
				}
				if (this.graphicSettings.yellowOre !== newValue.yellowOre) {
					this.updateOreColor(OreColor.Yellow, newValue.yellowOre);
				}
				if (this.graphicSettings.greenOre !== newValue.greenOre) {
					this.updateOreColor(OreColor.Green, newValue.greenOre);
				}
				if (this.graphicSettings.redOre !== newValue.redOre) {
					this.updateOreColor(OreColor.Red, newValue.redOre);
				}
				if (this.graphicSettings.purpleOre !== newValue.purpleOre) {
					this.updateOreColor(OreColor.Purple, newValue.purpleOre);
				}
				if (this.graphicSettings.blueOre !== newValue.blueOre) {
					this.updateOreColor(OreColor.Blue, newValue.blueOre);
				}

				this.graphicSettings = { ...newValue };
			});
	}

	public buildBossMap() {
		this.purge();
		// build map

		const w = 1.25;
		const h = 0.5;


		this.closedSectors = this.fullClosedSectors = 0;

		this.wallBottomContainer.removeChild(this.publicChest);
		this.playerLabelsContainer.removeChild(this.publicChestPointer);
		const sectorSize = 2047;
		const thickness = sectorSize;

		const placeIceberg = (x: number, y: number) => {
			const ice = Factory.get(Iceberg);
			this.wallBottomContainer.addChild(ice);
			ice.x = x;
			ice.y = y;
		};
		const floor = h * sectorSize;
		placeIceberg(900, 0);
		placeIceberg(1450, 0);
		placeIceberg(1750, 0);
		placeIceberg(2250, 0);
		placeIceberg(1100, floor);
		placeIceberg(1500, floor);
		placeIceberg(2080, floor);
		placeIceberg(2400, floor);

		this.bg.width = w * sectorSize;
		this.bg.height = 4 * sectorSize;

		this.blocks.lineStyle(0);
		this.blocks.beginFill(0);

		this.blocks.drawRect(-thickness, -h * sectorSize, thickness, h * sectorSize * 2);
		this.blocks.drawRect(-thickness, h * sectorSize, w * sectorSize + thickness * 2, thickness);
		this.blocks.drawRect(w * sectorSize, -h * sectorSize, thickness, h * sectorSize * 2);

		const bg = new Sprite(Texture.from('images/boss/penguinbossbg.png'));
		bg.anchor.set(0, 1);
		bg.scale.set(.5);
		bg.x = 0;
		bg.y = h * sectorSize;
		this.wallBottomContainer.addChild(bg);
	}
	public build(mapData: PartialMapData) {
		this.purge();
		// build map

		this.closedSectors = this.fullClosedSectors = 0;

		this.wallBottomContainer.removeChild(this.publicChest);
		this.playerLabelsContainer.removeChild(this.publicChestPointer);

		const { sectors, w, h, safe } = mapData;
		this.mapData = mapData;

		this.fullClosedSectors = 0;
		for (let i = 0; i < w * h; i++) {
			this.fullClosedSectors |= (1 << i);
		}

		const blockSize = 341;
		const sectorSize = 2047;
		const emptySectorSize = 1620;
		const emptyMainNodeSize = 922;
		const emptySideNodeSize = 796;
		const thickness = sectorSize;


		this.bg.width = w * sectorSize;
		this.bg.height = h * sectorSize;

		if (safe) {
			this.toPvpEmoji = this.pvpEmojiCount > 0;
			this.blocks.lineStyle(3, 0x00ff00, 0.2);
			this.blocks.beginFill(0x00ff00, 0.1);
			this.blocks.arc(safe.x * sectorSize + sectorSize / 2, safe.y * sectorSize, sectorSize / 1.85, 0, Math.PI);


			this.blocks.lineStyle(10, 0xffffff, 0.2);
			this.blocks.moveTo(0, sectorSize);
			this.blocks.lineTo(sectorSize * w, sectorSize);
			// this.safeZoneSymbol.polarBubble.visible = false;
			this.safeZoneSymbol.x = safe.x * sectorSize + sectorSize / 2;
			this.safeZoneSymbol.y = 0;
			this.safeZoneX = this.safeZoneSymbol.x;
			this.npcContainer.addChild(this.safeZoneSymbol);
		}
		this.blocks.lineStyle(0);
		this.blocks.beginFill(0);

		this.blocks.drawRect(-thickness, -h * sectorSize, thickness, h * sectorSize * 2);
		this.blocks.drawRect(-thickness, h * sectorSize, w * sectorSize + thickness * 2, thickness);
		this.blocks.drawRect(w * sectorSize, -h * sectorSize, thickness, h * sectorSize * 2);


		const addMainNode = (x: number, y: number, node: NodeData) => {
			if (node.t) {
				this.wallsContainer.addChild(getWallSprite(MapAsset.MainNodeT, x, y - blockSize));
			}
			if (node.b) {
				this.wallsContainer.addChild(getWallSprite(MapAsset.MainNodeB, x, y + blockSize));
			}
			if (node.l) {
				this.wallsContainer.addChild(getWallSprite(y === 0 ? MapAsset.MainNodeLSurface : MapAsset.MainNodeL, x - blockSize, y));
			}
			if (node.r) {
				this.wallsContainer.addChild(getWallSprite(y === 0 ? MapAsset.MainNodeRSurface : MapAsset.MainNodeR, x + blockSize, y));
			}
			this.wallsContainer.addChild(getWallSprite(y === 0 ? MapAsset.MainNodeSurface : MapAsset.MainNode, x, y));
		};
		const addTopNode = (x: number, y: number, node: NodeData) => {
			if (node.l) {
				this.wallsContainer.addChild(getWallSprite(y === 0 ? MapAsset.TopNodeLSurface : MapAsset.TopNodeL, x - blockSize, y));
			}
			if (node.r) {
				this.wallsContainer.addChild(getWallSprite(y === 0 ? MapAsset.TopNodeRSurface : MapAsset.TopNodeR, x + blockSize, y));
			}
			this.wallsContainer.addChild(getWallSprite(y === 0 ? MapAsset.TopNodeSurface : MapAsset.TopNode, x, y));
		};
		const addLeftNode = (x: number, y: number, node: NodeData) => {
			if (node.t) {
				this.wallsContainer.addChild(getWallSprite(MapAsset.LeftNodeT, x, y - blockSize));
			}
			if (node.b) {
				this.wallsContainer.addChild(getWallSprite(MapAsset.LeftNodeB, x, y + blockSize));
			}
			this.wallsContainer.addChild(getWallSprite(MapAsset.LeftNode, x, y));
		};

		for (let x = 0; x < w + 1; x++) {
			for (let y = 0; y < h + 1; y++) {
				const sector = sectors[x][y];
				if (sector.blocked) {
					this.blocks.drawRect(x * sectorSize, y * sectorSize, sectorSize, sectorSize);
				}
				if (sector.nodes.tl) {
					addMainNode(x * sectorSize, y * sectorSize, sector.nodes.tl);
				}
				if (sector.nodes.tc) {
					addTopNode(x * sectorSize + sectorSize / 2, y * sectorSize, sector.nodes.tc);
				}
				if (sector.nodes.cl) {
					addLeftNode(x * sectorSize, y * sectorSize + sectorSize / 2, sector.nodes.cl);
				}
			}
		}

		// const drawEntrance = (x: number, y: number, direction: number) => {
		// 	if (direction === 0) {
		// 		x += sectorSize / 2;
		// 	} else if (direction === 1) {
		// 		x -= sectorSize / 2;
		// 	} else if (direction === 0.5) {
		// 		y += sectorSize / 2;
		// 	} else if (direction === -0.5) {
		// 		y -= sectorSize / 2;
		// 	}
		// 	this.blocks.drawRect(x - blockSize / 2, y - blockSize / 2, blockSize, blockSize);

		// };


		// this.blocks.beginFill(0xff0000);
		// this.blocks.drawRect(abyss.x * sectorSize + sectorSize / 4, abyss.y * sectorSize + sectorSize / 4, sectorSize / 2, sectorSize / 2);
		// drawEntrance(abyss.x * sectorSize + sectorSize / 2, abyss.y * sectorSize + sectorSize / 2, -0.5);
		// this.blocks.beginFill(0x00aaff);
		// this.blocks.drawRect(scr.x * sectorSize + sectorSize / 4, scr.y * sectorSize + sectorSize / 4, sectorSize / 2, sectorSize / 2);
		// drawEntrance(scr.x * sectorSize + sectorSize / 2, scr.y * sectorSize + sectorSize / 2, scr.d);


		// this.blocks.beginFill(0xff0000, 0);
		// this.blocks.lineStyle(5, 0x00ff00);
		// for (const emptySpace of emp) {
		// 	let x = emptySpace.x * sectorSize;
		// 	let y = emptySpace.y * sectorSize;
		// 	const size = emptySpace.t === EmptySpaceType.Sector ? emptySectorSize
		// 		: emptySpace.t === EmptySpaceType.MainNode ? emptyMainNodeSize
		// 			: emptySideNodeSize;
		// 	x -= size / 2;
		// 	y -= size / 2;
		// 	let sh = size;
		// 	if (y < 0) {
		// 		sh += y;
		// 		y = 0;
		// 	}
		// 	this.blocks.drawRect(x, y, size, sh);
		// }

		// this.symbol.scale.set(0.05);
		// this.symbol.x = 100;
		// this.symbol.y = 100;
	}

	public updateArmageddon(state: ViewState) {

		const { sectors, w, h, abyss, scr, safe } = this.mapData;
		const len = w * h;
		const blockSize = 341;
		const sectorSize = 2047;

		if (state.closedSectors !== this.closedSectors) {
			if (this.closedSectors === 0) {
				this.blocks.clear();
				this.blocks.lineStyle(0);
				this.blocks.beginFill(0);
				for (let x = 0; x < w + 1; x++) {
					for (let y = 0; y < h + 1; y++) {
						const sector = sectors[x][y];
						if (sector.blocked) {
							this.blocks.drawRect(x * sectorSize, y * sectorSize, sectorSize, sectorSize);
						}
					}
				}
				this.armageddonTween.gotoAndPlay(0);
				this.armageddonLayer.visible = true;
				this.closedContainer.visible = true;

				this.closedContainer.addChild(
					this.closingPoison,
					createPoisonTile(0, -5 * sectorSize, w * sectorSize, 5 * sectorSize),
					createPoisonTile(0, h * sectorSize, w * sectorSize, 5 * sectorSize),
					createPoisonTile(-5 * sectorSize, -5 * sectorSize, 5 * sectorSize, (h + 10) * sectorSize),
					createPoisonTile(w * sectorSize, -5 * sectorSize, 5 * sectorSize, (h + 10) * sectorSize),
				);

				BgmManager.instance.play('armageddon');
				BgmManager.currentBgm.volume = 1;
				new SoundEfx('thunder').play();
			}
			const toAdd = state.closedSectors ^ this.closedSectors;
			this.closedSectors = state.closedSectors;

			for (let i = 0; i < len; i++) {
				if (getBoolean(toAdd, i)) {
					const y = Math.floor(i / w);
					const x = i % w;
					this.closedContainer.addChild(
						createPoisonTile(x * sectorSize, y * sectorSize, sectorSize, sectorSize),
					);
				}
			}
			if (state.closedSectors === this.fullClosedSectors) {
				this.closedContainer.removeChild(this.closingPoison);
			}
		}
		if (state.closedSectors !== this.fullClosedSectors) {
			let ox = state.closingSectorX;
			let oy = state.closingSectorY;
			let ow = 1;
			let oh = 1;
			const direction = state.closingSectorDirection;
			const progress = (state.rt - state.closingSectorStartTime) / (state.closingSectorEndTime - state.closingSectorStartTime);
			if (direction === 3) {
				oy += 1 - progress;
				oh = progress;
			} else if (direction === 1) {
				oh = progress;
			} else if (direction === 2) {
				ox += 1 - progress;
				ow = progress;
			} else {
				ow = progress;
			}
			this.closingPoison.x = ox * sectorSize;
			this.closingPoison.y = oy * sectorSize;
			this.closingPoison.width = ow * sectorSize;
			this.closingPoison.height = oh * sectorSize;
		}
		for (const child of this.closedContainer.children) {
			const tile = child as TilingSprite;
			tile.tilePosition.y = -tile.y - (Date.now() % 2560) / 10;
			tile.tilePosition.x = -tile.x;
		}
	}

	public buildSecretTemple(scr: SecretRoomData) {

		this.secretRoomData = scr;
		const sectorSize = 2047;
		this.symbol.addChildAt(this.secretTemple, this.symbol.getChildIndex(this.npcContainer) + 1);

		this.secretTemple.x = (scr.x + .5) * sectorSize;
		this.secretTemple.y = (scr.y + .5) * sectorSize;
		this.secretTempleCover.x = (scr.x + .5) * sectorSize;
		this.secretTempleCover.y = (scr.y + .5) * sectorSize;
		this.secretTemple.entrance.angle = scr.d * 180;
	}

	public update(state: ViewState) {
		const distToSafeZone = Rotate.dist(state.x, state.y, this.safeZoneX, 0);
		const safeZoneArea = Preset.SECTOR_SIZE / 1.85;
		const isInPolarZone = distToSafeZone < safeZoneArea;
		// this.safeZoneSymbol.polarBubble.visible = isInPolarZone;
		let isInSecretRoom = false;

		if (this.secretRoomData) {
			const scr = this.secretRoomData;
			if (state.x > scr.x * Preset.SECTOR_SIZE && state.x < (scr.x + 1) * Preset.SECTOR_SIZE
				&& state.y > scr.y * Preset.SECTOR_SIZE && state.y < (scr.y + 1) * Preset.SECTOR_SIZE
			) {
				isInSecretRoom = true;

				if (!this.isInSecretRoom) {
					const wallIndex = this.symbol.getChildIndex(this.playerLabelsContainer) + 1;
					this.symbol.addChildAt(this.wallsContainer, wallIndex);
					this.symbol.addChildAt(this.secretTempleCover, wallIndex);
					this.symbol.addChildAt(this.blocks, this.symbol.getChildIndex(this.npcContainer));
					this.secretTemple.templeChest.gotoAndStop(state.templeChestOpened ? this.secretTemple.templeChest.totalFrames - 1 : 0);
				}

				const d = this.secretRoomData.d === 0 ? state.x - this.secretTemple.x :
					this.secretRoomData.d === 1 ? this.secretTemple.x - state.x :
						this.secretRoomData.d === 0.5 ? state.y - this.secretTemple.y :
							this.secretTemple.y - state.y;

				if (d < 512) {
					this.secretTempleCover.alpha = 0;
				} else {
					this.secretTempleCover.alpha = (d - 512) / 512;
				}
				if (this.secretTemple.idol.visible) {
					this.secretTemple.ray.angle += 1;
				}
			} else {

				if (this.isInSecretRoom) {
					const wallIndex = this.symbol.getChildIndex(this.foodsContainer);
					this.symbol.addChildAt(this.wallsContainer, wallIndex);
					this.symbol.addChildAt(this.blocks, wallIndex - 1);
					// this.symbol.addChildAt(this.secretTempleCover, wallIndex);
					this.symbol.removeChild(this.secretTempleCover);
				}
			}
			this.isInSecretRoom = isInSecretRoom;
			this.secretTemple.visible = isInSecretRoom;
		}

		this.blindScreen.visible = state.sightRadius > state.sightRadiusAfterBlind;
		if (this.blindScreen.visible) {
			this.blindScreen.x = state.x;
			this.blindScreen.y = state.y;
			this.blindScreen.scale.set(state.sightRadiusAfterBlind / 500);
		}

		if (state.publicChestX) {
			if (!this.publicChest.parent) {
				this.wallBottomContainer.addChild(this.publicChest);
				this.publicChest.x = state.publicChestX;
				this.publicChest.y = state.publicChestY;
				this.publicChestPointer.x = state.publicChestX - 11;
				this.publicChestPointer.y = state.publicChestY - 98;
				this.publicChest.gotoAndStop(state.publicChestOpened ? this.publicChest.totalFrames - 1 : 0);
			}
			if (!state.publicChestOpened && Rotate.dist(state.x, state.y, state.publicChestX, state.publicChestY) < 700) {
				if (!this.publicChestPointer.parent) {
					this.playerLabelsContainer.addChild(this.publicChestPointer);
				}
				if (!tipx.tipsRead[TipId.Chest] && !tipx.tipsToShow[TipId.Chest]) {
					tipx.showTip(TipId.Chest);
				}
			} else {
				if (this.publicChestPointer.parent) {
					this.publicChestPointer.parent.removeChild(this.publicChestPointer);
				}
			}
		}
		const team = state.team;
		let me: PlayerControl | null = null;

		const allPlayers: Dictionary<PlayerControl> = {};
		const visiblePlayers: Dictionary<PlayerControl> = {};
		for (const pid of Object.keys(state.players)) {
			allPlayers[pid] = this.allPlayers[pid];
			delete this.allPlayers[pid];
		}
		for (const pp of state.ps) {
			const playerData = state.players[pp.id];
			if (!allPlayers[playerData.id]) {

				let playerAlliance: 'me' | 'team' | 'enemy' = 'enemy';
				if (playerData.id === state.pid) {
					playerAlliance = 'me';
				} else if (state.team.includes(playerData.id)) {
					playerAlliance = 'team';
				}
				allPlayers[playerData.id] = PlayerControl.get(
					playerData,
					playerData.decoSet,
					playerAlliance,
				);
				if (playerData.id === state.pid) {
					playerAlliance = 'me';
					this.lastInPvp = playerData.y > Preset.SECTOR_SIZE;
					const searchString = `PB-${PassiveSkill.NewbieDefBonus}-0|PB-${PassiveSkill.RookieDefBonus}-0`;
					const matched = state.buffs.match(searchString);
					if (matched) {
						const event: BroadcastBuffEvent = {
							t: state.rt + 500,
							type: EventType.Buff,
							bid: matched[0],
							pid: playerData.id,
							pos: playerData,
							tid: playerData.tid,
						};
						setTimeout(() => { this.buffEvent(event); }, 1000);
					} else if (!state.armageddonStarted) {
						const event: BroadcastEmojiEvent = {
							t: state.rt + 500,
							type: EventType.Buff,
							eid: this.lastInPvp ? EmojiType.Pvp : EmojiType.Peace,
							pid: playerData.id,
							pos: playerData,
							tid: playerData.tid,
						};
						setTimeout(() => { this.emojiEvent(event); }, 1000);
					}
				}
			}
			const player = allPlayers[playerData.id];
			if (player.alliance === 'me') {
				me = player;

				if (!tipx.tipsRead[TipId.LeftClick] && !tipx.tipsToShow[TipId.LeftClick] && state.skill1 === NormalSkill.Boost) {
					tipx.showTip(TipId.LeftClick);
				}
				if (tipx.tipsRead[TipId.LeftClick] && !tipx.tipsRead[TipId.RightClick] && -state.skill2 === SpecialSkill.Ration && state.stamina < 0.75 && !tipx.tipsToShow[TipId.RightClick]) {
					tipx.showTip(TipId.RightClick);
				}
				if (!tipx.tipsRead[TipId.Hp] && !tipx.tipsToShow[TipId.Hp]) {
					tipx.showTip(TipId.Hp);
				}
				if (!tipx.tipsRead[TipId.SafeZone]) {

					if (!isInPolarZone) {
						if (distToSafeZone < safeZoneArea + 500 && !tipx.tipsToShow[TipId.SafeZone]) {
							tipx.showTip(TipId.SafeZone);
						}
					} else if (tipx.currentShowingTipId === TipId.SafeZone && !tipx.tipsRead[TipId.SafeZone]) {
						tipx.achieveTip(TipId.SafeZone);
					}
				}
				if (!tipx.tipsRead[TipId.Armory] && !tipx.tipsToShow[TipId.Armory]) {
					if (distToSafeZone < safeZoneArea - 500) {
						tipx.showTip(TipId.Armory);
					}
				}
				if (state.spearsBought > 1) {
					if (settingx.now.control === 'touch') {
						if (!tipx.tipsRead[TipId.ChangeWeaponTouch] && !tipx.tipsToShow[TipId.ChangeWeaponTouch]) {
							tipx.showTip(TipId.ChangeWeaponTouch);
						}
					} else {
						if (!tipx.tipsRead[TipId.ChangeWeapon] && !tipx.tipsToShow[TipId.ChangeWeapon]) {
							tipx.showTip(TipId.ChangeWeapon);
						}
					}
				}
				if (!state.armageddonStarted && this.toPvpEmoji) {
					const currentInPvp = playerData.y > Preset.SECTOR_SIZE;
					if (currentInPvp !== this.lastInPvp && state.state === PlayerGameState.Active) {
						this.pvpEmojiCount--;
						this.lastInPvp = currentInPvp;
						const event: BroadcastEmojiEvent = {
							t: state.rt + 500,
							type: EventType.Buff,
							eid: this.lastInPvp ? EmojiType.Pvp : EmojiType.Peace,
							pid: playerData.id,
							pos: playerData,
							tid: playerData.tid,
						};
						this.emojiEvent(event);
					}
				}

				if (playerData.nmsStage > gamex.nmsStageRecord) {
					gamex.updateNmsStageRecord(playerData.nmsStage);
				}
			}
			player.update(playerData, allPlayers, state.rt, state.x, state.y, this);
			player.label.update(playerData, state.ranks, state.armageddonStarted);
			allPlayers[playerData.id] = player;

			if (!playerData.stababId && !playerData.isFishBolt) {
				visiblePlayers[playerData.id] = player;
				if (!this.visiblePlayers[playerData.id]) {
					this.playersContainer.addChild(player.symbol);
					this.playerLabelsContainer.addChild(player.label);
					if (!this.graphicSettings.hideShadows) {
						this.shadowsContainer.addChild(player.shadow);
					}
				} else {
					delete this.visiblePlayers[playerData.id];
				}
				if (!settingx.now.hideParticles || this.boostBubbleFlag) {
					const makeBubble = playerData.fishType === FishType.AlligatorSnappingTurtle ? playerData.skill2 : playerData.skill1 || (playerData.fishType === FishType.Jomama && playerData.skill2);
					if (makeBubble && playerData.y > 0) {
						const particle = getParticle(Particle.MiniBubble);
						const p = Collision.move(Math.random() * Math.PI * 2, Math.random() * playerData.bodyRadius);
						particle.x = playerData.x + p.x;
						particle.y = playerData.y + p.y;
						if (particle.y < 8) { particle.y = 8; }
						this.backParticlesContainer.addChild(particle);
						particle.alpha = 1;
						Tween.get(particle).wait(500).to({ alpha: 0 }, 800).call(
							() => {
								this.backParticlesContainer.removeChild(particle);
								particle.x = 0; particle.y = 0;
								particle.alpha = 1;
								disposeParticle(Particle.MiniBubble, particle);
							});
					}
				}
			}
		}
		for (const player of Object.values(this.visiblePlayers)) {
			player.hide();
			this.playersContainer.removeChild(player.symbol);
			this.playerLabelsContainer.removeChild(player.label);
			if (!this.graphicSettings.hideShadows) {
				this.shadowsContainer.removeChild(player.shadow);
			}
		}
		for (const pid in this.allPlayers) {
			if (Object.prototype.hasOwnProperty.call(this.allPlayers, pid)) {
				const player = this.allPlayers[pid];
				if (player) {
					player.dispose();
				}
			}
		}
		this.visiblePlayers = visiblePlayers;
		this.allPlayers = allPlayers;
		this.boostBubbleFlag = !this.boostBubbleFlag;



		const visibleFoods: Dictionary<FoodControl> = {};
		for (const ff of state.fds) {
			const foodData = state.foods[ff.id];
			const food = this.visibleFoods[foodData.id] || FoodControl.get(
				foodData.id,
				foodData.ft,
			);
			food.update(foodData, state.rt);
			visibleFoods[foodData.id] = food;
			if (!this.visibleFoods[foodData.id]) {
				this.foodsContainer.addChild(food.symbol);
			}
			delete this.visibleFoods[foodData.id];
		}

		for (const food of Object.values(this.visibleFoods)) {
			this.foodsContainer.removeChild(food.symbol);
			food.dispose();
		}
		this.visibleFoods = visibleFoods;

		const visibleHazards: Dictionary<HazardControl> = {};
		for (const hid in state.hazards) {
			if (Object.prototype.hasOwnProperty.call(state.hazards, hid)) {
				const hazardData = state.hazards[hid];
				if (!this.allHazards[hid]) {
					this.allHazards[hid] = HazardControl.get(hazardData);
					this.wallBottomContainer.addChild(this.allHazards[hid].symbol);
				} else {
					const distance = Rotate.dist(state.x, state.y, hazardData.x, hazardData.y);
					const visible = distance < state.sightRadius;
					if (visible) {
						if (!this.visibleHazards[hid]) {
							this.wallBottomContainer.addChild(this.allHazards[hid].symbol);
							this.visibleHazards[hid] = this.allHazards[hid];
						}
						if (hazardData.type === HazardType.Electric) {
							this.allHazards[hid].seen(hazardData, distance);
						} else if (hazardData.type === HazardType.Fire) {
							if (me) {
								if (tipx.tipsRead[TipId.Crush] && !tipx.tipsRead[TipId.Hazard] && Object.values(me.stababs).length > 0 && !tipx.tipsToShow[TipId.Hazard]) {
									tipx.showTip(TipId.Hazard);
								}
							}
						}
						visibleHazards[hid] = this.visibleHazards[hid];
					} else {
						if (this.visibleHazards[hid]) {
							this.wallBottomContainer.removeChild(this.allHazards[hid].symbol);
							this.allHazards[hid].unseen();
						}
					}
				}
			}
		}
		this.visibleHazards = visibleHazards;


		const visibleOres: Dictionary<OreControl> = {};
		for (const oreData of state.ors) {
			const ore = this.visibleOres[oreData.id] || OreControl.get(oreData);
			visibleOres[oreData.id] = ore;
			if (!this.visibleOres[oreData.id]) {
				this.oresContainer.addChild(ore.symbol);
				ore.symbol.tint = getTint(oreData.color);
			}
			delete this.visibleOres[oreData.id];
		}

		for (const ore of Object.values(this.visibleOres)) {
			this.oresContainer.removeChild(ore.symbol);
			ore.dispose();
		}
		this.visibleOres = visibleOres;

		const visibleBottles: Dictionary<BottleControl> = {};
		for (const bottleData of state.bottles) {
			const bottle = this.visibleBottles[bottleData.id]
				|| BottleControl.get(bottleData);
			visibleBottles[bottleData.id] = bottle;
			if (!this.visibleBottles[bottleData.id]) {
				this.effectsContainer.addChild(bottle.symbol);
			}
			delete this.visibleBottles[bottleData.id];
		}

		for (const bottle of Object.values(this.visibleBottles)) {
			this.effectsContainer.removeChild(bottle.symbol);
			bottle.dispose();
		}
		this.visibleBottles = visibleBottles;


		const allMiscs: Dictionary<MiscSymbol> = {};
		const visibleMiscs: Dictionary<MiscSymbol> = {};
		for (const mid of Object.keys(state.miscs)) {
			allMiscs[mid] = this.allMiscs[mid];
			delete this.allMiscs[mid];
		}
		for (const mid in this.allMiscs) {
			if (Object.prototype.hasOwnProperty.call(this.allMiscs, mid)) {
				const miscSymbol = this.allMiscs[mid];
				if (miscSymbol) {
					miscSymbol.dispose(this);
				}
				delete this.visibleMiscs[mid];
			}
		}
		this.allMiscs = allMiscs;
		for (const mm of state.mis) {
			const miscItem = state.miscs[mm.id];
			const miscSymbol: MiscSymbol | null = allMiscs[miscItem.id] || getMiscSymbol(miscItem.type);

			if (!miscSymbol) {
				continue;
			}
			miscSymbol.update(this, miscItem, state.rt, state);
			allMiscs[miscItem.id] = miscSymbol;
			visibleMiscs[miscItem.id] = miscSymbol;
			if (!this.visibleMiscs[miscItem.id]) {
				miscSymbol.show(this);
			} else {
				delete this.visibleMiscs[miscItem.id];
			}
		}
		for (const miscSymbol of Object.values(this.visibleMiscs)) {
			miscSymbol.hide(this);
		}
		this.visibleMiscs = visibleMiscs;


		// draw events;

		for (const event of state.evts) {
			if (event.type === EventType.Damage) {
				const e = event as BroadcastDamageEvent;
				e.dt ||= 0;
				e.to ||= 0;
				if (!e.to) {
					const vid = e.pidV!;
					if (visiblePlayers[vid]) {
						visiblePlayers[vid].lastStabTime = state.rt;
					}
				}
				if (!settingx.now.hideParticles) {
					if (e.dt <= DamageType.Spike) {
						const control = CutEffectControl.get(e);
						this.effectsContainer.addChild(control.symbol);
					}
				}
				if (e.to === DamageTargetType.ToMechguin) {
					if (!settingx.now.hideDmgNums) {
						const damageNumber = DamageNumberControl.get();
						damageNumber.set(e, state.pid);
						this.playerTopContainer.addChild(damageNumber.container);
						const crit = e.crit || 0;
						this.playSoundToDistance(crit > 0 ? 'mechguinMetal3' : crit === 0 ? 'mechguinMetal1' : 'mechguinMetal2', e.pos.x, e.pos.y, state.x, state.y, 0.4);
					}
				} else if (e.to === DamageTargetType.ToPearl) {
					if (!settingx.now.hideDmgNums) {
						const damageNumber = DamageNumberControl.get();
						damageNumber.set(e, state.pid);
						this.playerTopContainer.addChild(damageNumber.container);
						this.playSoundToDistance('pearlHit', e.pos.x, e.pos.y, state.x, state.y, 0.8, 2000);
					}
				} else {
					if (e.pidD === state.pid || e.pidV === state.pid || e.pidD === this.strongArmingVid || e.pidV === this.strongArmingVid) {
						if (!settingx.now.hideDmgNums) {
							const damageNumber = DamageNumberControl.get();
							damageNumber.set(e, state.pid);
							this.playerTopContainer.addChild(damageNumber.container);
						}
						if (e.pidV === state.pid || e.pidV === this.strongArmingVid) {
							this.playSoundToDistance('damageSelf', e.pos.x, e.pos.y, state.x, state.y, 0.4);
							this.cameraControl.swing(2, 500, true);
						} else {
							this.playSoundToDistance('damage', e.pos.x, e.pos.y, state.x, state.y, 0.2);
							// this.cameraControl.swing(.75, 500, true);
						}
					}
				}
			} else if (event.type === EventType.SpearHitSpear) {
				// const control = SpearCollisionEffectControl.get(event as BroadcastSpearHitSpearEvent);
				// this.effectsContainer.addChild(control.symbol);
				const e = event as BroadcastSpearHitSpearEvent;
				this.playSoundToDistance('spearHitSpear', e.pos.x, e.pos.y, state.x, state.y);
				// if (e.pidA === state.pid || e.pidB === state.pid) {
				// 	this.cameraControl.swing(0.5, 500, true);
				// }

			} else if (event.type === EventType.SpearParried) {
				// const control = SpearCollisionEffectControl.get(event as BroadcastSpearHitSpearEvent);
				// this.effectsContainer.addChild(control.symbol);
				const e = event as BroadcastSpearHitSpearEvent;
				this.playSoundToDistance('spearParried', e.pos.x, e.pos.y, state.x, state.y);
				const particle = getParticle(Particle.RainbowGlare);
				this.playerTopContainer.addChild(particle);
				particle.x = e.pos.x;
				particle.y = e.pos.y;
				particle.blendMode = BLEND_MODES.ADD;
				particle.scale.set(0.5);
				particle.alpha = 1;
				const parrier = this.allPlayers[e.pidB];
				if (parrier) {
					particle.angle = Rotate.degree(parrier.symbol.x, parrier.symbol.y, e.pos.x, e.pos.y) + 90;
				}
				Tween.get(particle).to({ alpha: 0 }, 800, Ease.linear);
				Tween.get(particle.scale).to({ x: 1.5, y: 1.5 }, 800, Ease.circOut).call(() => {
					this.effectsContainer.removeChild(particle);
					particle.angle = 0;
					particle.scale.set(1);
					particle.blendMode = BLEND_MODES.NORMAL;
					disposeParticle(Particle.RainbowGlare, particle);
				});
				// if (e.pidA === state.pid || e.pidB === state.pid) {
				// 	this.cameraControl.swing(0.5, 500, true);
				// }

			} else if (event.type === EventType.Bonk) {
				const e = event as BroadcastBonkEvent;
				this.playSoundToDistance('bonk', e.pos.x, e.pos.y, state.x, state.y);

				if (visiblePlayers[e.pidB]) {
					const spear = visiblePlayers[e.pidB].symbol.spearContainer;
					Tween.get(spear.scale, { override: true }).to({ x: .7, y: .8 }, 0).to({ x: 1, y: 1 }, 600, Ease.elasticOut);
				}

			} else if (event.type === EventType.Stab) {
				// const control = SpearCollisionEffectControl.get(event as BroadcastSpearHitSpearEvent);
				// this.effectsContainer.addChild(control.symbol);
				const e = event as BroadcastStabEvent;
				this.playSoundToDistance('stab', e.pos.x, e.pos.y, state.x, state.y, 0.3);
				if (e.pidS === state.pid || e.pidS === this.strongArmingVid) {
					this.cameraControl.swing(1.5, 500, true);
				} else if (e.pidV === state.pid) {
					this.cameraControl.swing(4, 500, true);
				}

			} else if (event.type === EventType.CorpseToFood) {
				// const control = SpearCollisionEffectControl.get(event as BroadcastSpearHitSpearEvent);
				// this.effectsContainer.addChild(control.symbol);
				const e = event as BroadcastCorpseToFoodEvent;
				const dist = this.playSoundToDistance('corpseToFood', e.pos.x, e.pos.y, state.x, state.y);
				if (dist < 10) {
					this.cameraControl.swing(8, 2000, true);
				}

			} else if (event.type === EventType.Emoji) {
				const e = event as BroadcastEmojiEvent;
				this.emojiEvent(e);
			} else if (event.type === EventType.Loot) {
				const e = event as BroadcastLootEvent;
				const player = this.allPlayers[e.pid];
				if (player) {
					const pos = e.pos;
					const control = LootEffectControl.get();
					control.init(pos, player.symbol, e.item, this);
					if (control.isLegendary) {
						this.playSoundToDistance('legendaryLoot', e.pos.x, e.pos.y, state.x, state.y);
					}
				}
			} else if (event.type === EventType.Tail) {
				const e = event as BroadcastTailEvent;
				const player = this.allPlayers[e.pid];
				if (player) {
					const pos = e.pos;
					const control = LootParticleEffectControl.get();
					const tail = new skinGroups['s' + e.id].Tail_Axolotl();
					tail.angle = -45;
					control.init(pos, player.symbol, tail, this, 1.5, true);
					wait(1000).then(() => {
						this.playSoundToDistance('buyChaching', e.pos.x, e.pos.y, state.x, state.y);
					});
				}
			} else if (event.type === EventType.Jobuff) {
				const e = event as BroadcastJobuffEvent;
				const player = this.allPlayers[e.pid];
				if (player) {
					for (const pos of e.ps) {
						const control = LootParticleEffectControl.get();
						const heart = getParticle(Particle.JobuffRed);
						control.init(pos, player.symbol, heart, this, 1, false, 0, 110);
					}
				}
			} else if (event.type === EventType.SpearHitBottle) {
				const e = event as BroadcastSpearHitBottleEvent;
				this.playSoundToDistance('breakBottle', e.pos.x, e.pos.y, state.x, state.y, 1);
				if (e.pid === state.pid) {
					this.cameraControl.swing(2, 500, true);
				}

			} else if (event.type === EventType.PublicChest || event.type === EventType.TempleChest) {
				const e = event as BroadcastPublicChestEvent;
				const player = this.allPlayers[e.pid];
				if (player) {
					const pos = e.pos;
					for (const item of e.loot) {
						const control = LootEffectControl.get();
						control.init(pos, player.symbol, item[0], this);
						if (control.isLegendary) {
							this.playSoundToDistance('legendaryLoot', e.pos.x, e.pos.y, state.x, state.y);
						}
					}
				}
			} else if (event.type === EventType.SpearHitOre) {
				const e = event as BroadcastSpearHitOreEvent;
				const pos = e.pos;
				if (!settingx.now.hideParticles) {
					const control = MiningSparkleEffectControl.get(pos.x, pos.y);
					this.effectsContainer.addChild(control.symbol);
				}
				const ore = visibleOres[e.oid];
				if (ore) {
					const oreData = ore.data;
					Tween.get(ore.symbol)
						.to({ x: oreData.x + Math.random() * 10 - 5, y: oreData.y + Math.random() * 10 - 5 }, 30)
						.to({ x: oreData.x, y: oreData.y }, 30);
				}
				this.playSoundToDistance('mining', e.pos.x, e.pos.y, state.x, state.y, 0.3);
				if (e.pid === state.pid) {
					this.cameraControl.swing(1, 500, true);
				}
			} else if (event.type === EventType.SpearHitShield) {
				const e = event as BroadcastSpearHitOreEvent;
				const pos = e.pos;
				if (!settingx.now.hideParticles) {
					const control = MiningSparkleEffectControl.get(pos.x, pos.y);
					this.effectsContainer.addChild(control.symbol);
				}
				this.playSoundToDistance('diamondShieldHit', e.pos.x, e.pos.y, state.x, state.y, 0.3);
			} else if (event.type === EventType.Buff) {
				const e = event as BroadcastBuffEvent;
				this.buffEvent(e);
			} else if (event.type === EventType.SwordBreak) {
				const e = event as BroadcastSwordBreakEvent;
				this.playSoundToDistance('weaponBreak', e.pos.x, e.pos.y, state.x, state.y, 0.3);
				if (e.pidV === state.pid) {
					this.cameraControl.swing(4, 500, true);
				}
				const player = this.allPlayers[e.pidV];
				if (player) {
					player.swordBreak(e.s);
				}

			}
		}
		this.dojo.visible = state.dojoId ? true : false;
		if (this.dojo.visible) {
			this.dojo.x = state.dojoId * Preset.DOJO_GAP;
		}
		if (!this.secretRoomData && state.scrX !== -100) {
			this.buildSecretTemple({
				x: state.scrX,
				y: state.scrY,
				d: state.scrD,
			});
		}
		if (this.safeZoneSymbol.goldenIdol.visible === false && state.explorerUid) {
			this.safeZoneSymbol.goldenIdol.visible = true;
			this.safeZoneSymbol.goldenIdol.angle = state.idolDirection - 90;
			this.secretTemple.idol.visible = false;
			this.secretTemple.afterContainer.visible = true;
			if (state.explorerPid) {
				this.secretTemple.afterContainer.alpha = 0;
				this.secretTemple.afterContainer.y = -20;
				Tween.get(this.secretTemple.afterContainer, { override: true }).wait(1500).to({ alpha: 1, y: 0 }, 750, Ease.circOut);
				this.safeZoneSymbol.goldenIdol.alpha = 0;
				Tween.get(this.safeZoneSymbol.goldenIdol, { override: true }).wait(1500).to({ alpha: 1 }, 750);

				if (isInSecretRoom && this.secretRoomData) {
					const player = this.allPlayers[state.explorerPid];
					if (player) {
						const pos = { x: this.secretTemple.x, y: this.secretTemple.y };
						const control = LootEffectControl.get();
						control.init(pos, player.symbol, ItemCode.GoldenIdol, this);
						this.playSoundToDistance('legendaryLoot', pos.x, pos.y, state.x, state.y);
						if (state.explorerPid === state.pid) {
							this.cameraControl.swing(2, 1000, true);
						}
					}
				}
			} else {
				this.secretTemple.afterContainer.alpha = 1;
				this.safeZoneSymbol.goldenIdol.alpha = 1;
			}
		}

		if (this.whirlpool.parent) {
			this.whirlpool.x = (state.whirlpoolX + .5) * Preset.SECTOR_SIZE;
			this.whirlpool.y = (state.whirlpoolY + .5) * Preset.SECTOR_SIZE;
			this.whirlpool.angle -= 3;
			if (state.rt < state.whirlpoolSpawnTime + 3000) {
				this.whirlpool.alpha = (state.rt - state.whirlpoolSpawnTime) / 3000;
			} else if (state.rt > state.whirlpoolEndTime) {
				this.npcContainer.removeChild(this.whirlpool);
			} else if (state.rt > state.whirlpoolEndTime - 3000) {
				this.whirlpool.alpha = (state.whirlpoolEndTime - state.rt) / 3000;
			} else {
				this.whirlpool.alpha = 1;
			}
		} else {
			if (state.rt < state.whirlpoolEndTime) {
				this.whirlpool.alpha = 0;
				this.npcContainer.addChild(this.whirlpool);
			}
		}
		if (this.siren.parent) {
			this.siren.x = (state.sirenX + .5) * Preset.SECTOR_SIZE;
			this.siren.y = (state.sirenY + .5) * Preset.SECTOR_SIZE;
			if (state.rt < state.sirenSpawnTime + 1000) {
				const progress = (state.rt - state.sirenSpawnTime) / 1000;
				this.siren.alpha = progress;
				this.siren.scale.set(Ease.circOut(progress) / 2 + .5);
			} else if (state.rt > state.sirenEndTime) {
				this.npcContainer.removeChild(this.siren);
				this.sirenSong.stop();
			} else if (state.rt > state.sirenEndTime - 1000) {
				const progress = (state.sirenEndTime - state.rt) / 1000;
				this.siren.alpha = progress;
				this.siren.scale.set(Ease.circOut(progress) / 2 + .5);
			} else {
				this.siren.scale.set(1);
				this.siren.next(state.rt);
				this.siren.alpha = 1;
			}

			this.sirenSong.volume = (1300 - Rotate.dist(this.siren.x, this.siren.y, state.x, state.y)) / 1300 * 0.5 * this.siren.alpha;
			if (this.sirenSong.volume > .5) {
				this.sirenSong.volume = .5;
			}
		} else {
			if (state.rt < state.sirenEndTime) {
				this.siren.alpha = 0;
				this.siren.scale.set(1);
				this.npcContainer.addChild(this.siren);
				this.sirenSong.start();
				this.sirenSong.volume = 0;
			}
		}
		this.ouroboros1.angle += 1;
		this.ouroboros2.angle += 1;
		if (this.showingOuroboros) {
			if (state.ouroboros1X === -1) {
				this.showingOuroboros = false;
				Tween.get(this.ouroboros1.scale, { override: true }).to({ x: .01, y: .01 }, 300, Ease.circIn);
				Tween.get(this.ouroboros2.scale, { override: true }).to({ x: .01, y: .01 }, 300, Ease.circIn).call(() => {
					this.npcContainer.removeChild(this.ouroboros1, this.ouroboros2);
				});
			} else {
				this.ouroboros1.x = state.ouroboros1X;
				this.ouroboros1.y = state.ouroboros1Y;
				this.ouroboros2.x = state.ouroboros2X;
				this.ouroboros2.y = state.ouroboros2Y;
			}
		} else {
			if (state.ouroboros1X !== -1) {
				this.npcContainer.addChild(this.ouroboros1, this.ouroboros2);
				this.showingOuroboros = true;
				if (state.animateOuroborus) {
					this.ouroboros1.scale.set(.01);
					this.ouroboros2.scale.set(.01);
					Tween.get(this.ouroboros1.scale, { override: true }).to({ x: 1, y: 1 }, 300, Ease.circOut);
					Tween.get(this.ouroboros2.scale, { override: true }).to({ x: 1, y: 1 }, 300, Ease.circOut);
				} else {
					this.ouroboros1.scale.set(1);
					this.ouroboros2.scale.set(1);
				}
			}
		}

		for (const em of this.emojiContainer.children) {
			const emoji = em as EmojiBubbleContainer;
			emoji.next(state.rt);
			const player = this.allPlayers[emoji.playerId];
			if (player) {
				const isStabab = player.data!.stababId || player.data!.isFishBolt;
				const target = player.data!;
				emoji.x = target.x + (isStabab ? 35 : 65);
				emoji.y = target.y - (isStabab ? 35 : 65);
				emoji.scale.set(1 / this.symbol.parent.scale.x * (isStabab ? 0.75 : 1));
			}
		}

		const topSide = Preset.SECTOR_SIZE;
		const bottomSide = Preset.SECTOR_SIZE * 3;
		let depth = state.y < topSide ? 0 : state.y > bottomSide ? 1 : (state.y - topSide) / (bottomSide - topSide);
		depth *= 0.5;
		if (isInSecretRoom) {
			depth = 0.6;
		}
		BgmManager.instance.setDepth(depth);

		this.state = state;
		this.updatePosition(-state.x, -state.y);

		if (state.armageddonStarted) {
			this.updateArmageddon(state);
		}
	}
	public updatePosition(x: number, y: number) {

		this.symbol.x = x;
		this.symbol.y = y;
	}

	public purge() {
		for (const emoji of this.emojiContainer.children) {
			(emoji as EmojiBubbleContainer).dispose();
		}
		for (const hid in this.allHazards) {
			if (Object.prototype.hasOwnProperty.call(this.allHazards, hid)) {
				const hazard = this.allHazards[hid];
				if (hazard.symbol.parent) {
					hazard.symbol.parent.removeChild(hazard.symbol);
				}
				hazard.dispose();
			}
		}
		this.allHazards = {};
		this.blindScreen.visible = false;
		this.symbol.removeChild(this.secretTemple);
		this.symbol.removeChild(this.secretTempleCover);
		this.secretRoomData = null;
		this.safeZoneSymbol.goldenIdol.visible = false;
		this.secretTemple.idol.visible = true;
		this.secretTemple.afterContainer.visible = false;
		this.sirenSong.stop();
		this.safeZoneSymbol.parent?.removeChild(this.safeZoneSymbol);
		this.siren.parent?.removeChild(this.siren);
		this.whirlpool.parent?.removeChild(this.whirlpool);
		this.ouroboros1.parent?.removeChild(this.ouroboros1);
		this.ouroboros2.parent?.removeChild(this.ouroboros2);
		this.showingOuroboros = false;
		this.wallBottomContainer.removeChildren();
		this.toPvpEmoji = false;

		for (const wall of this.wallsContainer.children) {
			disposeWallSprite(wall as WallSprite);
		}
		this.wallsContainer.removeChildren();
		this.armageddonLayer.clear();
		this.armageddonLayer.visible = false;
		this.closedContainer.visible = false;
		this.closedContainer.removeChildren();

		this.blocks.clear();
		this.strongArmingVid = 0;
	}

	public buffEvent(e: BroadcastBuffEvent) {
		const split = e.bid.split('-');
		if (split[0] === 'PB') {
			if (!this.emojiBubbles[e.pid]) {
				this.emojiBubbles[e.pid] = EmojiBubbleContainer.get();
				this.emojiBubbles[e.pid].playerId = e.pid;
				this.emojiBubbles[e.pid].roomControl = this;
			}
			const emoji = this.emojiBubbles[e.pid];
			emoji.showBuff(e.t, {
				type: 'buff',
				index: Number(split[1]),
				lvl: Number(split[2]),
			});
			emoji.x = e.pos.x + 35;
			emoji.y = e.pos.y - 35;
			emoji.scale.set(1 / this.symbol.parent.scale.x * 0.75);
			this.emojiContainer.addChild(emoji);
		}
	}
	public emojiEvent(e: BroadcastEmojiEvent) {
		if (!this.emojiBubbles[e.pid]) {
			this.emojiBubbles[e.pid] = EmojiBubbleContainer.get();
			this.emojiBubbles[e.pid].playerId = e.pid;
			this.emojiBubbles[e.pid].roomControl = this;
		}
		const emoji = this.emojiBubbles[e.pid];
		emoji.showEmoji(e.t, e.eid);
		emoji.x = e.pos.x + 35;
		emoji.y = e.pos.y - 35;
		emoji.scale.set(1 / this.symbol.parent.scale.x * 0.75);
		this.emojiContainer.addChild(emoji);
	}

	public openPublicChest() {
		this.publicChest.gotoAndPlay(1);
		this.cameraControl.swing(2, 1000, true);
	}
	public openTempleChest() {
		this.secretTemple.templeChest.gotoAndPlay(1);
		this.cameraControl.swing(2, 1000, true);
	}

	public playSoundToDistance(sfxId: SfxId, x: number, y: number, vx: number, vy: number, soundScale = 1, maxDist = 1300) {
		const dist = Rotate.dist(x, y, vx, vy);
		const volume = (maxDist - dist) / maxDist * soundScale;
		const sfx = new SoundEfx(sfxId);
		sfx.play({ volume });
		return dist;
	}

	public splashParticles(px: number, bodyRadius: number, speed: number) {
		if (settingx.now.hideParticles) {
			return;
		}
		this.foodsContainer.addChild(SplashEffectControl.get(px - bodyRadius / 2, px, speed).symbol);
		this.foodsContainer.addChild(SplashEffectControl.get(px + bodyRadius / 2, px, speed).symbol);
		for (let i = 0; i < 8; i++) {
			const x = Math.random() * bodyRadius - bodyRadius / 2 + px;
			const droplet = SplashEffectControl.get(x, px, speed);
			this.foodsContainer.addChild(droplet.symbol);
		}
	}

	protected updateOreColor(oreColor: OreColor, newValue: number) {

		for (const oreId in this.visibleOres) {
			if (Object.prototype.hasOwnProperty.call(this.visibleOres, oreId)) {
				const ore = this.visibleOres[oreId];
				if (ore.data.color === oreColor) {
					ore.symbol.tint = newValue;
				}
			}
		}

	}
}
