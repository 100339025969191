import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { BroadcastChatEvent, BroadcastLootEvent, BroadcastPublicChestEvent, BroadcastSecretRoomFoundEvent, BroadcastState, PlayerGameState } from '@/game/multithread/state';
import { getEmptyBroadcastState } from '@/client/io/stateManager';
import { ServerInfo, ServerInitInfo } from '@/game/infos/serverInfos';
import { PassiveSkill } from '@/game/infos/skills';
import { getDefaultViewState, UserData, ViewState } from '@/game/multithread/viewState';
import { ItemCode } from '@/game/infos/itemInfos';
import { ChatType, EventType } from '@/game/infos/eventType';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import { Global } from '../globalz';
import settingx from './settingx';
import { Dictionary } from 'vue-router/types/router';
import { User } from '../firestore';
import { GameType, JoinRoomOptions } from '@/game/infos/roomInfos';
import { CpsPlayerData } from '@/game/multithread/main/socketModels';
import { TourneyLobbyData } from '@/game/infos/roomInfosTny';
import { BossLobbyData } from '@/game/infos/roomInfosBoss';

export type GameStage = 'Loading' | 'MainMenu' | 'Connecting' | 'Joining' | 'Queue' | 'Rejected' | 'Start' | 'End' | 'Reward' | 'TourneyCreate' | 'TourneyList' | 'TourneyLobby' | 'TourneyInterRound' | 'BossList' | 'BossCreate' | 'BossLobby';
export type GameUtility = 'None' | 'Map' | 'Server' | 'Rank' | 'Tips' | 'Bag' | 'Emoji' | 'Settings';


export interface ChatMessage {
	chatType: ChatType,
	senderUid?: string,
	senderName?: string,
	msg: string,
	t: number,
	isDraco?: boolean,
	isTemp?: boolean,
}

export interface BuffData {
	type: PassiveSkill;
	lvl: number;
}

let lastBuffs = '';
let lastBuffResult: BuffData[] = [];

@Module({
	namespaced: true,
	name: 'gamex',
	store,
	dynamic: true,
})
class GlobalModule extends VuexModule {

	public get buffs() {
		// return lastBuffs;
		const ids = this.currentGameState.buffs;
		if (ids === lastBuffs) { return lastBuffResult; }
		lastBuffs = ids;
		const buffs = ids.split(',');
		lastBuffResult = [];
		for (const buff of buffs) {
			if (buff.indexOf('PB-') === 0) {
				const split = buff.split('-');
				lastBuffResult.push({
					type: Number(split[1]),
					lvl: Number(split[2]),
				});
			}
		}
		return lastBuffResult;
	}

	public get scoreLeft() {
		return Math.round(this.currentGameState.scoreL);
	}
	public get highscore() {
		return Math.round(this.currentGameState.hscore);
	}
	public get money() {
		return Math.round(this.currentGameState.money);
	}
	public get kills() {
		return this.currentGameState.kills;
	}
	public get stabs() {
		return this.currentGameState.stabs;
	}
	public get numPlayers() {
		return this.currentGameState.numPlayers;
	}
	public get belayMaintenance() {
		return this.gameStage === 'Start' || this.gameStage === 'End' || this.gameStage === 'Reward';
	}
	public gameType: GameType = GameType.None;
	public gameStage: GameStage = 'MainMenu';
	public queueNumber = 0;
	public rejectReason = '';
	public socketErrorMessage = '';

	public currentGameState: ViewState = getDefaultViewState();
	public currentUtility: GameUtility = 'None';
	public currentGameServer: ServerInitInfo | null = null;
	public playerState = PlayerGameState.None;

	public nmsStageRecord = 0;
	public killStreakRecord = 0;
	public stabStreakRecord = 0;

	public inventory: ItemCodeAmountPair[] = [];
	public chatLogs: ChatMessage[] = [];
	public users: Dictionary<UserData> = {};
	public userNameCaches: Dictionary<string> = {};
	public dojoInvites: string[] = [];
	public dojoMembers: string[] = [];
	public dojoSpectators: string[] = [];
	public toShowChat: boolean = false;
	public lastHeal = 0;
	public watchingAds = false;
	public polarModalShowing = false;
	public ivModalShowing = false;

	public explorerName = '';
	public explorerUid = '';

	public cpsPlayers: { me: CpsPlayerData, opponent: CpsPlayerData; } | null = null;
	public roomOptions: JoinRoomOptions | null = null;

	public tnyLobbyData: TourneyLobbyData | null = null;
	public bossLobbyData: BossLobbyData | null = null;

	@Action
	public async updateCurrentGameState(value: ViewState) {
		this.m_updateCurrentGameState(value);
		for (const evt of value.evts) {
			if (evt.type === EventType.Loot) {
				const e = evt as BroadcastLootEvent;
				if (e.pid === value.pid && e.item !== ItemCode.Money) {
					this.addItem({
						itemCode: e.item,
						amount: e.amount,
					});
				}
			} else if (evt.type === EventType.PublicChest || evt.type === EventType.TempleChest) {
				const e = evt as BroadcastPublicChestEvent;
				for (const item of e.loot) {

					if (e.pid === value.pid) {
						this.addItem({
							itemCode: item[0],
							amount: item[1],
						});
					}
				}
			} else if (evt.type === EventType.SecretRoomFound) {
				const e = evt as BroadcastSecretRoomFoundEvent;
				this.m_updateExplorerName(e.n);
				this.m_updateExplorerUid(e.uid);
				if (e.pid === value.pid) {
					this.addItem({
						itemCode: ItemCode.Money,
						amount: 5000,
					});
				}
			}
		}
	}
	@Action
	public async updateCurrentGameServer(value: ServerInitInfo) {
		this.m_updateCurrentGameServer(value);
	}
	@Action
	public async setGameStage(value: GameStage) {
		this.m_setGameStage(value);
		if (value === 'Connecting') {
			this.m_updateInventory([]);
			this.m_updateLastHeal(0);
			this.m_updateGameRoomOptions(null);
		}
	}
	@Action
	public async updateCurrenUtility(value: GameUtility) {
		this.m_updateCurrenUtility(value);
	}
	@Action
	public async setQueueNumber(value: number) {
		this.m_setQueueNumber(value);
	}
	@Action
	public async setRejectReason(value: string) {
		this.m_setRejectReason(value);
	}
	@Action
	public async setSocketErrorMessage(value: string) {
		this.m_setSocketErrorMessage(value);
	}
	@Action
	public async addChatMessage(evt: BroadcastChatEvent) {
		if (!evt.ct) {
			const msg: ChatMessage = {
				chatType: ChatType.Public,
				senderUid: evt.uid,
				senderName: this.users[evt.uid].name || `Stabfish_${evt.uid.substring(0, 3)}`,
				msg: evt.msg,
				t: evt.t,
			};
			if (msg.senderName === 'dragonwhites' && msg.senderUid === 'fTaakPLzz0c8WTgQd8hkPZvaYu03') {
				msg.isDraco = true;
			}
			this.chatLogs.push(msg);
		} else {
			const msg: ChatMessage = {
				chatType: evt.ct,
				senderName: evt.uid || 'System',
				msg: evt.msg,
				t: evt.t,
			};
			this.chatLogs.push(msg);
		}
		this.m_updateChatLogs(this.chatLogs);
	}
	@Action
	public async addSystemMessage(msg: ChatMessage) {
		this.chatLogs.push(msg);
		this.m_updateChatLogs(this.chatLogs);
	}
	@Action
	public async clearTemporaryMessage() {
		this.m_updateChatLogs(this.chatLogs.filter((c) => !c.isTemp));
	}
	@Action
	public async purgeChatSystemMessage(message: string) {
		this.m_updateChatLogs(this.chatLogs.filter((c) => !(c.chatType === ChatType.ChatSystem && c.msg === message)));
	}
	@Action
	public async addItem(value: ItemCodeAmountPair) {
		const item = this.inventory.find((i) => i.itemCode === value.itemCode);
		if (item) {
			item.amount += value.amount;
		} else {
			this.inventory.push(value);
		}
		this.m_updateInventory([...this.inventory]);
	}
	@Action
	public async updateToShowChat(value: boolean) {
		this.m_updateToShowChat(value);
	}
	@Action
	public async updateUsers(users: Dictionary<UserData>) {
		this.m_updateUsers(users);
	}
	@Action
	public async updateDojoInvites(invites: string[]) {
		this.m_updateDojoInvites(invites);
	}
	@Action
	public async updateDojoMembers(members: string[]) {
		this.m_updateDojoMembers(members);
	}
	@Action
	public async updateDojoSpectators(spectators: string[]) {
		this.m_updateDojoSpectators(spectators);
	}
	@Action
	public updateLastHeal(value: number) {
		this.m_updateLastHeal(value);
	}
	@Action
	public updateWatchingAds(value: boolean) {
		this.m_updateWatchingAds(value);
		Global.isVideoPlaying = value;
	}

	@Action
	public async updatePolarModalShowing(value: boolean) {
		this.m_updatePolarModalShowing(value);
	}
	@Action
	public async updateIvModalShowing(value: boolean) {
		this.m_updateIvModalShowing(value);
	}
	@Action
	public async updateGameType(value: GameType) {
		this.m_updateGameType(value);
	}
	@Action
	public async updateGameRoomOptions(options: JoinRoomOptions | null) {
		this.m_updateGameRoomOptions(options);
	}
	@Action
	public async updateChampionshipPlayers(players: { me: CpsPlayerData, opponent: CpsPlayerData; } | null) {
		this.m_updateChampionshipPlayers(players);
	}
	@Action
	public async updateTourneyLobbyData(lobbyData: TourneyLobbyData | null) {
		this.m_updateTourneyLobbyData(lobbyData);
	}

	@Action
	public async updateBossLobbyData(lobbyData: BossLobbyData | null) {
		this.m_updateBossLobbyData(lobbyData);
	}
	@Action
	public async updateNmsStageRecord(value: number) {
		this.m_updateNmsStageRecord(value);
	}
	@Mutation
	protected m_updateNmsStageRecord(value: number) {
		if (value > this.nmsStageRecord) {
			this.nmsStageRecord = value;
		}
	}
	@Mutation
	protected m_updateTourneyLobbyData(lobbyData: TourneyLobbyData | null) {
		this.tnyLobbyData = lobbyData;
	}
	@Mutation
	protected m_updateBossLobbyData(lobbyData: BossLobbyData | null) {
		this.bossLobbyData = lobbyData;
	}

	@Mutation
	protected m_updateChampionshipPlayers(players: { me: CpsPlayerData, opponent: CpsPlayerData; } | null) {
		this.cpsPlayers = players;
	}
	@Mutation
	protected m_updateGameRoomOptions(options: JoinRoomOptions | null) {
		this.roomOptions = options;
	}
	@Mutation
	protected m_updateGameType(value: GameType) {
		this.gameType = value;
	}
	@Mutation
	protected m_updatePolarModalShowing(value: boolean) {
		this.polarModalShowing = value;
	}
	@Mutation
	protected m_updateIvModalShowing(value: boolean) {
		this.ivModalShowing = value;
	}
	@Mutation
	protected m_updateWatchingAds(value: boolean) {
		this.watchingAds = value;
	}
	@Mutation
	protected m_updateToShowChat(value: boolean) {
		if (value) {
			Global.$root.$bvModal.hide('polar-modal');
			Global.$root.$bvModal.hide('iv-modal');
			Global.$root.$bvModal.hide('idol-modal');
		}
		this.toShowChat = value;
	}
	@Mutation
	protected m_updateLastHeal(value: number) {
		this.lastHeal = value;
	}
	@Mutation
	protected m_updateCurrentGameServer(value: ServerInitInfo) {
		this.currentGameServer = value;
	}
	@Mutation
	protected m_updateCurrentGameState(value: ViewState) {
		this.currentGameState = value;
		if (this.killStreakRecord < value.kStreak) { this.killStreakRecord = value.kStreak; }
		if (this.stabStreakRecord < value.streak) { this.stabStreakRecord = value.streak; }
		if (this.playerState !== value.state) { this.playerState = value.state; }
	}
	@Mutation
	protected m_setGameStage(value: GameStage) {
		this.gameStage = value;
		if (value === 'Connecting') {
			this.nmsStageRecord = 0;
			this.killStreakRecord = 0;
			this.stabStreakRecord = 0;
			this.chatLogs.length = 0;
			this.chatLogs.push({
				chatType: ChatType.System,
				senderName: 'System',
				msg: `Beware of scam, do not share your personal info.${this.gameType === GameType.Armageddon ? '\nSending message cost 20pts without affecting your highscore.' : ''}\nThis is a modless chat room, please mute toxic players as you please. ${settingx.now.control === 'mouse' && this.gameType === GameType.Armageddon ? '\nPress ` to open chat window.' : ''}`,
				t: 0,
			});
			this.users = {};
			this.explorerName = '';
			this.explorerUid = '';
		}
	}
	@Mutation
	protected m_updateExplorerName(value: string) {
		this.explorerName = value;
	}
	@Mutation
	protected m_updateExplorerUid(value: string) {
		this.explorerUid = value;
	}
	@Mutation
	protected m_updateCurrenUtility(value: GameUtility) {
		this.currentUtility = value;
	}
	@Mutation
	protected m_setQueueNumber(value: number) {
		this.queueNumber = value;
	}
	@Mutation
	protected m_setRejectReason(value: string) {
		this.rejectReason = value;
	}
	@Mutation
	protected m_setSocketErrorMessage(value: string) {
		this.socketErrorMessage = value;
	}
	@Mutation
	protected m_updateInventory(value: ItemCodeAmountPair[]) {
		this.inventory = value;
	}
	@Mutation
	protected m_updateChatLogs(chatLogs: ChatMessage[]) {
		this.chatLogs = chatLogs;
	}
	@Mutation
	protected m_updateUsers(users: Dictionary<UserData>) {
		this.users = users;
		for (const uid in users) {
			if (Object.prototype.hasOwnProperty.call(users, uid)) {
				const user = users[uid];
				this.userNameCaches[uid] = user.name;
			}
		}
	}
	@Mutation
	protected m_updateDojoInvites(invites: string[]) {
		this.dojoInvites = invites;
	}
	@Mutation
	protected m_updateDojoMembers(members: string[]) {
		this.dojoMembers = members;
	}
	@Mutation
	protected m_updateDojoSpectators(spectators: string[]) {
		this.dojoSpectators = spectators;
	}
}


export default getModule(GlobalModule);
