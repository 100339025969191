





















import StateButton from '@/components/inputs/StateButton.vue';
import { BlueprintInfo } from '@/game/infos/blueprintInfos';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CollectiblesTab from './CollectiblesTab.vue';
import RewardsTab from './RewardsTab.vue';
import { blueprintInfos } from '@/game/infos/blueprintInfos';
import { ItemCode } from '@/game/infos/itemInfos';
import {
  hasQuestTimeStarted,
  TimeLimitedQuestCode,
} from '@/game/infos/questTimeLimited';

@Component({
  components: {
    StateButton,
    CollectiblesTab,
    RewardsTab,
  },
})
export default class BpAlbumModal extends Vue {
  public sorting = false;
  public tab: 'collectibles' | 'rewards' = 'collectibles';

  public collectibles: BlueprintInfo[] = [];
  public rewards: BlueprintInfo[] = [];

  public created() {
    for (const itemCode in blueprintInfos) {
      if (Object.prototype.hasOwnProperty.call(blueprintInfos, itemCode)) {
        const bpInfo = blueprintInfos[itemCode];
        if (!bpInfo.isReward) {
          // if (
          //   Number(itemCode) === ItemCode.BpSantaBeard &&
          //   !hasQuestTimeStarted(TimeLimitedQuestCode.Xmas2022)
          // ) {
          //   continue;
          // }
          if (
            Number(itemCode) === ItemCode.BpDragonHorns
          ) {
            continue;
          }
          this.collectibles.push(bpInfo);
        } else {
          this.rewards.push(bpInfo);
        }
      }
    }
  }
  public get numRewardsCrafted() {
    let num = 0;
    for (const bpInfo of this.rewards) {
      if (userx.inventory.crafted.indexOf(bpInfo.result) !== -1) {
        num++;
      }
    }
    return num;
  }
  public get numCollectiblesCrafted() {
    let num = 0;
    for (const bpInfo of this.collectibles) {
      if (userx.inventory.crafted.indexOf(bpInfo.result) !== -1) {
        num++;
      }
    }
    return num;
  }

  public selectTab(tab: 'collectibles' | 'rewards') {
    this.tab = tab;
  }
  public get titleHtml() {
    return `Blueprint Album`;
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
  public async onSort() {
    if (this.sorting) {
      return;
    }
    this.sorting = true;
    try {
      await userx.sortBag();
    } catch (error) { }
    this.sorting = false;
  }
}
