import { RoomControl } from '@/client/controls/roomControl';
import { MiscData, MiscType } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Dictionary } from '@/util/dictionary';
import Factory from '../..';
import { SsBandageSymbol } from './ssBandageSymbol';
import { SsBatDartSymbolController } from './ssBatDartSymbol';
import { SsBodySlamSymbol, SsJomamaShieldSymbol } from './ssBodySlamSymbol';
import { SsDartSymbolController } from './ssDartSymbol';
import { SsDiamondHarden } from './SsDiamondHardenSymbol';
import { SsElectricDartSymbolController } from './ssElectricDartSymbol';
import { SsElectricFieldSymbol } from './ssElectricFieldSymbol';
import { SsFishBoltSymbolController } from './ssFishBoltSymbol';
import { SsForceFieldSymbol } from './ssForceFieldSymbol';
import { McHazardCloudSymbolController } from './mcHazardCloudSymbol';
import { SsHolySwordSymbol } from './ssHolySwordSymbol';
import { SsInkSacSymbol } from './ssInkSacSymbol';
import { SsMegaBiteSymbol } from './ssMegaBiteSymbol';
import { SsRationSymbol } from './ssRationSymbol';
import { SsSacrificeSymbol } from './ssSacrificeSymbol';
import { SsSpikeArmorSymbol } from './SsSpikeArmorSymbol';
import { SsSpinDodgeSymbol } from './ssSpinDodgeSymbol';
import { SsSwimmingFangSymbol } from './ssSwimmingFangSymbol';
import { SsTempSafeZoneSymbol } from './ssTempSafeZoneSymbol';
import { SsWhammyWaveSymbol } from './ssWhammyWaveSymbol';
import { SsTridentWaveSymbol } from './ssTridentWaveSymbol';
import { McIronWaveSymbol, McIronmoniteSymbol } from './mcIronmoniteSymbol';
import { McZellyfishSymbol } from './mcZellyfishSymbol';
import { SsFireBulletSymbolController } from './ssFireBulletSymbol';
import { SsSunPunchController } from './ssSunPunchSymbol';
import { SsDecoySymbol } from './ssDecoySymbol';
import { SsTentaBolaSymbol } from './ssTentaBolasSymbol';
import { SsDisguiseSymbol } from './ssDisguiseSymbol';
import { Mechguin, MechguinMissile } from '../boss/xmasPenguin/xmasPenguinAssets';
import { BiAtomBombController } from '../boss/xmasPenguin/biAtomBombSymbol';
import { BiAMechguinBulletController } from '../boss/xmasPenguin/biMechguinBulletSymbol';
import { BiSnowBallController } from '../boss/xmasPenguin/biSnowBallSymbol';
import { BiLaserBeamController } from '../boss/xmasPenguin/biLaserBeamSymbol';
import { BiPenguinCrewController } from '../boss/xmasPenguin/biPenguinCrewSymbol';
import { BmSuperPearlController } from '../boss/superPearlSymbol';
import { SsFakeSwordController, SsSwordDanceController } from './ssSwordDanceSymbol';
import { SsStrongArmsController } from './ssStrongArmsSymbol';
import { SsMosaBiteSymbol } from './ssMosaBiteSymbol';

export interface MiscSymbol {
	dispose: (room: RoomControl) => void;
	update: (room: RoomControl, data: MiscData, time: number, state: ViewState) => void;
	show: (room: RoomControl) => void;
	hide: (room: RoomControl) => void;
}

export const miscAssets: Dictionary<() => MiscSymbol> = {};

export function getMiscSymbol(type: MiscType) {
	if (!miscAssets[type]) {
		console.error('misc symbol not found', type);
		return null;
	}
	return miscAssets[type]();
}

miscAssets[MiscType.SsMegaBite] = () => Factory.get(SsMegaBiteSymbol);
miscAssets[MiscType.SsSwimmingFang] = () => Factory.get(SsSwimmingFangSymbol);
miscAssets[MiscType.SsWhammyWave] = () => Factory.get(SsWhammyWaveSymbol);
miscAssets[MiscType.SsInkSac] = () => Factory.get(SsInkSacSymbol);
miscAssets[MiscType.SsBodySlam] = () => Factory.get(SsBodySlamSymbol);
miscAssets[MiscType.SsJomamaShield] = () => Factory.get(SsJomamaShieldSymbol);
miscAssets[MiscType.SsBandage] = () => SsBandageSymbol.get();
miscAssets[MiscType.SsRation] = () => SsRationSymbol.get();
miscAssets[MiscType.SsSpinDodge] = () => SsSpinDodgeSymbol.get();
miscAssets[MiscType.SsSpikeArmor] = () => SsSpikeArmorSymbol.get();
miscAssets[MiscType.SsHolySword] = () => SsHolySwordSymbol.get();
miscAssets[MiscType.SsDiamondHarden] = () => SsDiamondHarden.get();
miscAssets[MiscType.SsSacrifice] = () => SsSacrificeSymbol.get();
miscAssets[MiscType.SsTempSafeZone] = () => SsTempSafeZoneSymbol.get();
miscAssets[MiscType.SsForceField] = () => Factory.get(SsForceFieldSymbol);
miscAssets[MiscType.SsElectricField] = () => Factory.get(SsElectricFieldSymbol);
miscAssets[MiscType.SsDart] = () => SsDartSymbolController.get();
miscAssets[MiscType.SsBatDart] = () => SsBatDartSymbolController.get();
miscAssets[MiscType.SsElectricDart] = () => SsElectricDartSymbolController.get();
miscAssets[MiscType.SsFishBolt] = () => SsFishBoltSymbolController.get();
miscAssets[MiscType.McHazardCloud] = () => McHazardCloudSymbolController.get();
miscAssets[MiscType.SsTridentWave] = () => Factory.get(SsTridentWaveSymbol);
miscAssets[MiscType.McIronmonite] = () => Factory.get(McIronmoniteSymbol);
miscAssets[MiscType.McIronWave] = () => Factory.get(McIronWaveSymbol);
miscAssets[MiscType.McZellyfish] = () => McZellyfishSymbol.get();
miscAssets[MiscType.SsFireBullet] = () => SsFireBulletSymbolController.get();
miscAssets[MiscType.SsSunPunch] = () => SsSunPunchController.get();
miscAssets[MiscType.SsDecoy] = () => SsDecoySymbol.get();
miscAssets[MiscType.SsTentaBola] = () => Factory.get(SsTentaBolaSymbol);
miscAssets[MiscType.SsDisguise] = () => SsDisguiseSymbol.get();
miscAssets[MiscType.BmSuperPearl] = () => BmSuperPearlController.get();
miscAssets[MiscType.BiMechguin] = () => Mechguin.get();
miscAssets[MiscType.BiMechguinMissile] = () => Factory.get(MechguinMissile);
miscAssets[MiscType.BiAtomBomb] = () => BiAtomBombController.get();
miscAssets[MiscType.BiSnowBall] = () => BiSnowBallController.get();
miscAssets[MiscType.BiMechguinBullet] = () => BiAMechguinBulletController.get();
miscAssets[MiscType.BiLaserBeam] = () => BiLaserBeamController.get();
miscAssets[MiscType.BiPenguinCrew] = () => BiPenguinCrewController.get();
miscAssets[MiscType.SsFakeSword] = () => SsFakeSwordController.get();
miscAssets[MiscType.SsSwordDance] = () => SsSwordDanceController.get();
miscAssets[MiscType.SsStrongArms] = () => SsStrongArmsController.get();
miscAssets[MiscType.SsMosaBite] = () => Factory.get(SsMosaBiteSymbol);
