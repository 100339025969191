




































































import FishCanvasComponent from '@/components/canvas/FishCanvasComponent.vue';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { BModal } from 'bootstrap-vue';
import uuid from 'uuid';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StateButton from '@/components/inputs/StateButton.vue';
import { EditState } from '@/store/models.def';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';
import { blueprintInfos } from '@/game/infos/blueprintInfos';
import { blueprintQuests } from '@/game/infos/blueprintQuestInfos';

interface Material {
  name: string;
  possessed: number;
  required: number;
  sufficient: boolean;
}

@Component({
  components: {
    FishCanvasComponent,
    StateButton,
  },
})
export default class CraftModal extends Vue {
  public craftState: EditState = 'active';
  public get craftLabel() {
    const labels = {
      active: 'Craft',
      sending: 'Crafting',
      done: 'Crafted',
    };
    return labels[this.craftState];
  }
  public get itemInfo() {
    return itemInfos[this.itemCode];
  }

  public get amount() {
    return userx.inventory.availables[this.itemCode] || 0;
  }

  public get decoCode() {
    const bpInfo = this.blueprintInfo || blueprintInfos[ItemCode.BpBumpOnHead];
    return bpInfo.result;
  }
  public get decoPossessed() {
    return userx.inventory.availables[this.decoCode];
  }
  public get decoName() {
    return itemInfos[this.decoCode].name;
  }

  public get rarityColor() {
    return Global.rarityColors[this.itemInfo.rarity];
  }
  public get rarity() {
    return Global.rarityLabels[this.itemInfo.rarity];
  }

  public get blueprintInfo() {
    return (
      (this.itemInfo.type === 'blueprint' && blueprintInfos[this.itemCode]) ||
      null
    );
  }
  public get blueprintQuest() {
    return (
      (blueprintQuests[this.itemCode] &&
        blueprintQuests[this.itemCode][0] &&
        blueprintQuests[this.itemCode][0][0]) ||
      null
    );
  }
  public get blueprintQuestProgress() {
    const progress = userx.userDoc.blueprintQuestProgress[this.itemCode] || {};
    return progress[0] || 0;
  }

  public get materialsNeeded(): Material[] {
    if (!this.blueprintInfo) {
      return [];
    }
    const availables = userx.inventory.availables;
    const money = userx.inventory.money;
    const bpInfo = this.blueprintInfo;
    return bpInfo.recipe.map((pair) => {
      const [itemCode, required] = pair;
      const name = itemInfos[itemCode].name;
      const possessed =
        itemCode === ItemCode.Money ? money : availables[itemCode];
      return { name, required, possessed, sufficient: possessed >= required };
    });
  }
  public get readyToCraft() {
    return this.amount === 0 ||
      this.materialsNeeded.find((m) => m.sufficient === false)
      ? false
      : true;
  }

  public itemCode: ItemCode = ItemCode.BpBumpOnHead;
  @Prop({ default: '' }) public container!: string;

  public canvasId = uuid.v4();

  public created() {
    Global.craftModal = this;
  }

  public show(itemCode: ItemCode) {
    this.itemCode = itemCode;
    (this.$refs.myModal as BModal).show();
    this.craftState = 'active';
  }

  public async onCraft() {
    this.craftState = 'sending';
    try {
      await userx.craft(this.itemCode);
      this.craftState = this.amount === 0 ? 'done' : 'active';
    } catch (error) {
      this.craftState = 'active';
      this.$root.$emit('error', (error as any).message);
    }
  }
}
